import React, { ReactNode } from 'react'

import { Typography } from 'components/lib'

import Style from './MetricBar.module.css'

type TMetricBarProps = {
  metricTitle: string
  dropdownComponent: ReactNode
}

export const MetricBar = ({
  metricTitle,
  dropdownComponent,
}: TMetricBarProps) => {
  return (
    <div className={Style['metric-bar']}>
      <Typography
        text={metricTitle}
        tag="h3"
        size="m"
        weight="semi-bold"
        color="gray900"
      />
      {dropdownComponent}
    </div>
  )
}
