import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Line, Button, ReactDatePicker, IconLib } from 'components/lib'

import Style from './dropdownNumbersContent.module.scss'

export const DropdownDateContent = ({
  column,
  sortTypeParam,
  sortByParam,
  minName,
  maxName,
  minParamValue,
  maxParamValue,
}) => {
  const [sort, setSort] = useState('')
  const [minValue, setMinValue] = useState()
  const [maxValue, setMaxValue] = useState()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (sortByParam === column) {
      setSort(sortTypeParam)
    }

    if (minParamValue) {
      setMinValue(minParamValue)
    }

    if (maxParamValue) {
      setMaxValue(maxParamValue)
    }
  }, [sortByParam, sortTypeParam, minParamValue, maxParamValue])

  const handleFilter = (column) => {
    if (sort) {
      searchParams.delete('sort-type')
      searchParams.append('sort-type', sort)
      searchParams.delete('sort-by')
      searchParams.append('sort-by', column)
      setSearchParams(searchParams)
    }

    if (!sort && sortByParam === column) {
      searchParams.delete('sort-by')
      searchParams.delete('sort-type')
      setSearchParams(searchParams)
    }

    if (minValue) {
      searchParams.delete(minName)
      searchParams.append(minName, minValue)
      setSearchParams(searchParams)
    } else {
      searchParams.delete(minName)
      setSearchParams(searchParams)
    }

    if (maxValue) {
      searchParams.delete(maxName)
      searchParams.append(maxName, maxValue)
      setSearchParams(searchParams)
    } else {
      searchParams.delete(maxName)
      setSearchParams(searchParams)
    }
  }

  const handleStartDate = (value) => {
    const formattedValue = value?.toISOString().substring(0, 10)
    setMinValue(formattedValue)
  }

  const handleEndDate = (value) => {
    const formattedValue = value?.toISOString().substring(0, 10)
    setMaxValue(formattedValue)
  }

  const handleSort = (type) => {
    if (type === sort) {
      setSort()
    } else {
      setSort(type)
    }
  }

  return (
    <div className={Style.contentContainer}>
      <p className={Style.dropDownContentTitle}>Sort by</p>
      <Line noMargin className="mt8 mb8" />
      <div>
        <button
          onClick={() => handleSort('asc')}
          className={
            sort === 'asc'
              ? `${Style.sortButton} ${Style.activeButton}`
              : Style.sortButton
          }
        >
          <IconLib
            pack="own"
            iconHeight="16"
            iconWidth="16"
            image={sort === 'asc' ? 'arrowUp2' : 'arrowUp'}
          />
          Value: <span className={Style.boldText}>Ascending</span>
        </button>
        <button
          onClick={() => handleSort('desc')}
          className={
            sort === 'desc'
              ? `${Style.sortButton} ${Style.activeButton}`
              : Style.sortButton
          }
        >
          <IconLib
            pack="own"
            iconHeight="16"
            iconWidth="16"
            image={sort === 'desc' ? 'arrowDown2' : 'arrowDown'}
          />
          Value: Descending
        </button>
      </div>
      <Line noMargin className="mt8 mb8" />
      <p className={`${Style.dropDownContentTitle} mb8`}>Range</p>
      <div className={Style.rangeContainer}>
        <ReactDatePicker
          key="min"
          label="Start Date"
          onChange={(value) => handleStartDate(value)}
          value={minValue}
          format="yyyy-MM-dd"
          clearIcon={<IconLib pack="own" image="exitThin" />}
        />
        <ReactDatePicker
          key="max"
          label="End Date"
          onChange={(value) => handleEndDate(value)}
          value={maxValue}
          format="yyyy-MM-dd"
          clearIcon={<IconLib pack="own" image="exitThin" />}
        />
      </div>
      <Button
        text="Apply"
        fullWidth
        small
        action={() => handleFilter(column)}
      />
    </div>
  )
}
