/***
 *
 *   CHART BUILDER
 *
 **********/

import React, { useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { logger } from 'utils/logger'
import dappSymbol from 'assets/images/dapp-symbol.svg'
import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { Typography, Loader } from 'components/lib'
import { TResult, ResultSchema } from 'types/result'
import { fetchResultData } from 'utils/fetches/resultData'
import {
  removeUselessProperties,
  getValidationErrorMessage,
} from 'utils/helpers'

import { BlockChartContext } from 'features/builders/shared/contexts/BlockChartContext'
import { Window } from 'features/builders/chartBuilder/components/Window'
import { ResultView } from 'features/builders/chartBuilder/components/ResultView'
import { TopBar } from 'features/builders/chartBuilder/components/TopBar'
import { MetricsSidebar } from 'features/builders/chartBuilder/components/MetricsSidebar'
import { SettingsChart } from 'features/builders/chartBuilder/components/SettingsChart'
import { AddSegment } from 'features/builders/shared/components/AddSegment'
import { AddSegmentModalContext } from 'features/builders/shared/contexts/AddSegmentModalContext'
import Style from './ChartBuilder.module.css'

type TSelectedUnits = {
  name: string
  icon?: string
  label: string
  value: string
}

type TSelectedSegments = {
  name: string
  icon?: string
  label: string
  value: string
}

type TSelectedB = {
  name: string
  icon: string
}

export function ChartBuilder() {
  const [result, setResult] = useState<TResult | undefined>()
  const [isLoadingResult, setIsLoadingResult] = useState<boolean | undefined>()
  const [chartType, setChartType] = useState<string>('lineChart')
  const [chartTitle, setChartTitle] = useState<string>('Default Chart Name')
  const [selectedUnit, setSelectedUnit] = useState<TSelectedUnits | undefined>()
  const [selectedSegments, setSelectedSegments] = useState<
    TSelectedSegments | undefined
  >()
  const [selectedEvents, setSelectedEvents] = useState<TSelectedB | undefined>()
  const [selectedCalls, setSelectedCalls] = useState<TSelectedB | undefined>()
  const [isScBreakdown, setIsScBreakdown] = useState<boolean>(false)
  const blockChartContext = useContext(BlockChartContext)
  const dashboardContentContext = useContext(DashboardContentContext)
  const addSegmentContext = useContext(AddSegmentModalContext)

  if (!addSegmentContext) {
    throw Error('Segment modal context has to be in provider')
  }

  if (!blockChartContext) {
    throw Error('Chart context has to be in provider')
  }

  if (!dashboardContentContext) {
    throw Error('Dashboard context has to be in provider')
  }

  const { isDisplayingAddSegmentModal, setIsDisplayingAddSegmentModal } =
    addSegmentContext
  const { blockChartId, setBlockChartId } = blockChartContext
  const { dashboardElements, dashboardDappId } = dashboardContentContext

  useEffect(() => {
    const editedChartElement = dashboardElements.filter(
      (item) => item.id === blockChartId
    )[0]
    if (editedChartElement) {
      setIsScBreakdown(editedChartElement.breakdown)
      setSelectedUnit({
        value: editedChartElement.metric,
        name: editedChartElement.metric,
        label: editedChartElement.metric,
      })
      setChartTitle(editedChartElement.title)
      setChartType(editedChartElement.visType)
      const calls = editedChartElement.filters.filter(
        (item: any) => item.type === 'call'
      )
      setSelectedCalls(calls)
      const events = editedChartElement.filters.filter(
        (item: any) => item.type === 'event'
      )
      setSelectedEvents(events)
    }
  }, [blockChartId])

  useEffect(() => {
    if (selectedUnit) {
      const fetchData = async () => {
        try {
          setIsLoadingResult(true)
          const selectedCallsWithoutFilterOptions =
            removeUselessProperties(selectedCalls)
          const selectedEventsWithoutFilterOptions =
            removeUselessProperties(selectedEvents)
          const bodyRequest = {
            breakdown: isScBreakdown,
            filters: [],
          }
          selectedCallsWithoutFilterOptions &&
            bodyRequest.filters.push(...selectedCallsWithoutFilterOptions)
          selectedEventsWithoutFilterOptions &&
            bodyRequest.filters.push(...selectedEventsWithoutFilterOptions)
          const fetchedResultData = await fetchResultData(
            dashboardDappId,
            selectedUnit.value,
            bodyRequest
          )
          const validatedResultData = ResultSchema.safeParse(
            fetchedResultData.output
          )
          if (!validatedResultData.success) {
            throw new Error(validatedResultData.error.message)
          }
          setIsLoadingResult(false)
          setResult(validatedResultData.data)
        } catch (err) {
          setIsLoadingResult(false)
          logger.error(err.message)
          toast.error(getValidationErrorMessage('result'))
        }
      }

      fetchData()
    } else {
      setResult(undefined)
      setIsScBreakdown(false)
    }
  }, [selectedUnit?.value, isScBreakdown, selectedCalls, selectedEvents])

  return (
    <>
      {isDisplayingAddSegmentModal && <AddSegment dappId={dashboardDappId} />}
      <Window>
        <div className={Style['builder-container']}>
          <MetricsSidebar
            setBlockChartId={setBlockChartId}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            selectedEvents={selectedEvents}
            setSelectedEvents={setSelectedEvents}
            selectedCalls={selectedCalls}
            setSelectedCalls={setSelectedCalls}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
            isScBreakdown={isScBreakdown}
            setIsScBreakdown={setIsScBreakdown}
            dappId={dashboardDappId}
          />
          <div className={Style['preview']}>
            <TopBar />
            {!result && !isLoadingResult && (
              <div className={Style['prevew-content-container']}>
                <img
                  src={dappSymbol}
                  alt="dapp symbol"
                  className={Style['img-distance']}
                />
                <Typography
                  text="Select a unit to get started"
                  size="m"
                  weight="semi-bold"
                  color="gray900"
                  tag="p"
                />
              </div>
            )}
            {result && (
              <>
                <SettingsChart
                  setChartType={setChartType}
                  chartType={chartType}
                  setChartTitle={setChartTitle}
                  chartTitle={chartTitle}
                  dappId={dashboardDappId}
                  selectedUnit={selectedUnit}
                  isScBreakdown={isScBreakdown}
                  result={result}
                  selectedCalls={selectedCalls}
                  selectedEvents={selectedEvents}
                />
                <ResultView
                  data={result}
                  selectedUnit={selectedUnit}
                  chartType={chartType}
                  chartTitle={chartTitle}
                />
              </>
            )}
            {isLoadingResult && <Loader />}
          </div>
        </div>
      </Window>
    </>
  )
}
