/***
 *
 *   GI DAPP BREAKDOWN DASHBOARD
 *
 **********/

import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'

import { ViewContext, Loader, Breadcrumbs, useLocation } from 'components/lib'
import { endpoint } from 'utils/paths'
import { useSeoPage } from 'hooks/useSeoPage'
import { breadcrumbs } from 'utils/constans'

import { COLLECTION_NAME } from 'features/gi/shared/utils/constans'
import { DappBreakdownFilters } from 'features/gi/dapp/giDappBreakdownDashboard/components/dappBreakdownFilters/DappBreakdownFilters'
import { ChartSection } from 'features/gi/dapp/shared/components/chartSection/chartSection'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import cumulativeIcon from 'features/gi/shared/assets/icons/cumulative.svg'
import {
  USER_ACQUISTION,
  USER_RETENTION,
  USERS_THAT_CHURNED,
  TVL,
  MONETIZATION_FEES,
  INTERACTIONS,
  TG_GROWTH_INDEX,
} from 'features/gi/dapp/shared/utils/constans'
import { transformToSingleValueFormat } from 'features/gi/dapp/giDappBreakdownDashboard/utils/helpers'
import { TopSection } from 'features/gi/dapp/giDappBreakdownDashboard/components/topSection/topSection'
import { useDapp } from 'features/gi/shared/hooks/useDapp'

export function GiDappBreakdownDashboard() {
  const location = useLocation()
  const [, , secondSegment] = location.pathname.split('/')
  const { selectedDapp } = useDapp(secondSegment)
  const {
    verifiedDappSegment,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
    verifiedParamsStr,
    verifiedBlockchainsParam,
    verifiedBlockchainBreakdownParam,
    selectedDappsToCompare,
    selectedBlockchains,
  } = useVerifiedGiDappsFiltersParams(selectedDapp)
  document.title = `dApp Growth Breakdown - ${selectedDapp?.name || ''}`
  const breadcrumbsItems = [
    breadcrumbs.home,
    breadcrumbs.dappsOverview,
    {
      key: 'analytics',
      url: `/app/${secondSegment}/analytics`,
      name: selectedDapp?.name ? `${selectedDapp?.name}` : 'dApp Analytics',
    },
    {
      key: 'charts',
      url: `/app/${secondSegment}/charts${location.search}`,
      name: 'Charts',
    },
  ]
  const [chartsData, setChartsData] = useState([
    { name: TG_GROWTH_INDEX, slug: 'tg_growth_index', value: 0 },
    { name: USER_ACQUISTION, slug: 'new_users', value: 0 },
    {
      name: TVL,
      slug: 'tvl',
      value: 0,
      round: 2,
      formatValue: true,
      prefixValue: '$',
    },
    {
      name: USER_RETENTION,
      slug: 'returning_users',
      value: 0,
      formatValue: true,
    },
    {
      name: USERS_THAT_CHURNED,
      slug: 'churned_users',
      value: 0,
      formatValue: true,
    },
    {
      name: MONETIZATION_FEES,
      slug: 'fees_usd',
      value: 0,
      round: 2,
      formatValue: true,
      prefixValue: '$',
    },
    { name: INTERACTIONS, slug: 'calls', value: 0, formatValue: true },
  ])
  const [timelineDataLoadingStatus, setTimelineDataLoadingStatus] = useState({
    [USER_ACQUISTION]: true,
    [TVL]: false,
    [USER_RETENTION]: false,
    [USERS_THAT_CHURNED]: false,
    [TG_GROWTH_INDEX]: false,
    [INTERACTIONS]: false,
    [MONETIZATION_FEES]: false,
  })
  const [dapp, setDapp] = useState()
  const [comparison, setComparison] = useState()
  const viewContext = useContext(ViewContext)
  const requestControllerTL = {}
  requestControllerTL[USER_RETENTION] = useRef()
  requestControllerTL[USER_ACQUISTION] = useRef()
  requestControllerTL[USERS_THAT_CHURNED] = useRef()
  requestControllerTL[TG_GROWTH_INDEX] = useRef()
  requestControllerTL[TVL] = useRef()
  requestControllerTL[INTERACTIONS] = useRef()
  requestControllerTL[MONETIZATION_FEES] = useRef()

  useSeoPage({
    title: `${selectedDapp?.name || ' '} Charts | Patterns`,
    description: `Analyze the growth potential of  ${selectedDapp?.name} with easy to understand “dApp name” charts. Monitor its user activity, number of new users, deposits and other metrics.`,
    keywords: [`${selectedDapp?.name} charts`],
    ogTitle: `${selectedDapp?.name || ' '} Charts | Patterns`,
    ogDescription: `Analyze the growth potential of  ${selectedDapp?.name} with easy to understand “dApp name” charts. Monitor its user activity, number of new users, deposits and other metrics.`,
    ogImage: selectedDapp?.icon,
    // ogUrl: to set
  })

  useEffect(() => {
    if (verifiedDappSegment && verifiedParamsStr) {
      if (verifiedCompareWithParam && selectedDappsToCompare) {
        let comparisonLogo
        let comparisonName
        if (selectedDappsToCompare.length > 1) {
          comparisonLogo = cumulativeIcon
          comparisonName = COLLECTION_NAME
        } else {
          comparisonLogo = selectedDappsToCompare[0].icon
          comparisonName = selectedDappsToCompare[0].name
        }
        setComparison({
          name: comparisonName,
          logo: comparisonLogo,
        })
      }

      setDapp({
        name: selectedDapp.name,
        logo: selectedDapp.icon,
      })

      const fetchTimeline = async (name, metric) => {
        try {
          setTimelineDataLoadingStatus((prevState) => ({
            ...prevState,
            [name]: true,
          }))
          let url = endpoint.apiGiDappTimelineData

          if (requestControllerTL[name].current) {
            requestControllerTL[name].current.abort()
          }

          requestControllerTL[name].current = new AbortController()
          const signal = requestControllerTL[name].current.signal
          let compare = []
          if (selectedDappsToCompare && selectedDappsToCompare.length > 0) {
            compare = selectedDappsToCompare.map((item) => item.name)
          }

          const postData = {
            dAppName: selectedDapp?.name,
            period: verifiedDateRangeParam,
            metric: metric,
            compareWith: compare,
            blockchainBreakdown: verifiedBlockchainBreakdownParam,
          }
          if (verifiedBlockchainsParam) {
            postData.chains = selectedBlockchains.map(
              (blockchain) => blockchain.slug
            )
          }

          const response = await axios.post(url, postData, {
            signal: signal,
          })
          const singleValueFormatresponse = transformToSingleValueFormat(
            response.data
          )
          setChartsData((prevState) =>
            prevState.map((obj) => {
              if (obj.name === name) {
                return {
                  ...obj,
                  timelineData: response.data,
                  singleValueData: singleValueFormatresponse,
                }
              } else {
                return obj
              }
            })
          )

          setTimelineDataLoadingStatus((prevState) => ({
            ...prevState,
            [name]: false,
          }))
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setTimelineDataLoadingStatus((prevState) => ({
              ...prevState,
              [name]: false,
            }))
            viewContext && viewContext.handleError(err)
          }
        }
      }

      fetchTimeline(TG_GROWTH_INDEX, 'tg_growth_index')
      fetchTimeline(USER_ACQUISTION, 'new_users')
      fetchTimeline(TVL, 'tvl')
      fetchTimeline(USER_RETENTION, 'returning_users')
      fetchTimeline(USERS_THAT_CHURNED, 'churned_users')
      fetchTimeline(INTERACTIONS, 'calls')
      fetchTimeline(MONETIZATION_FEES, 'fees_usd')
    }
  }, [verifiedDappSegment, verifiedParamsStr])

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems} />
      </div>
      {verifiedDappSegment && verifiedParamsStr ? (
        <>
          <TopSection
            tgGrowthIndexTimeline={chartsData[0].timelineData}
            isTgGrowthIndexTimelineLoading={
              timelineDataLoadingStatus[TG_GROWTH_INDEX]
            }
            dapp={dapp}
            comparison={comparison}
          />
          <DappBreakdownFilters
            selectedDapp={selectedDapp}
            selectedDappsToCompare={selectedDappsToCompare}
            verifiedDappSegment={verifiedDappSegment}
            verifiedCompareWithParam={verifiedCompareWithParam}
            verifiedDateRangeParam={verifiedDateRangeParam}
            verifiedBlockchainBreakdownParam={verifiedBlockchainBreakdownParam}
            verifiedBlockchainsParam={verifiedBlockchainsParam}
            selectedBlockchains={selectedBlockchains}
          />
          {chartsData.slice(1).map((item) => {
            return (
              <ChartSection
                key={item.name}
                title={item.name}
                loadingTimelineFlag={timelineDataLoadingStatus[item.name]}
                metric={item.name}
                timelineData={item.timelineData}
                singleValueData={item.singleValueData}
                dapp={dapp}
                comparison={comparison}
                round={item?.round}
                prefixValue={item?.prefixValue}
                formatValue={item?.formatValue}
              />
            )
          })}
        </>
      ) : (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </>
  )
}
