/***
 *
 *   GROWTH INDEX DAPPS OVERVIEW
 *
 **********/

import React from 'react'

import { Loader, SmallHeadline, Typography } from 'components/lib'
import { paths } from 'utils/paths'

import { useGiBlockchainsData } from 'features/gi/ecosystem/shared/hooks/useGiBlockchainsData'
import { GiBannersContainer } from 'features/gi/shared/components/giBannersContainer/giBannersContainer'
import { GiLegend } from 'features/gi/shared/components/giLegend/giLegend'
import { RankingBanner } from 'features/gi/shared/components/rankingBanner/RankingBanner'
import { CardOfBanners } from 'features/gi/shared/components/cardOfBanners/CardOfBanners'
import { NewsBanner } from 'features/gi/shared/components/newsBanner/newsBanner'
import { BlockchainTable } from 'features/gi/ecosystem/giEcosystemOverview/components/blockchainTable'

export function GiEcosystemOverview() {
  const { giBlockchainsData, isGiBlockchainsDataLoading } =
    useGiBlockchainsData()

  return (
    <>
      <CardOfBanners bgColor="ecosystem-color" className="mt24">
        <Typography
          tag="h1"
          text="Ecosystem Overview"
          weight="semi-bold"
          size="l"
          color="gray900"
        />
        <GiBannersContainer>
          <GiLegend />
          <RankingBanner
            iconName="dapp"
            text="dApp Overview"
            link={paths.giDappOverview}
          />
          <NewsBanner />
        </GiBannersContainer>
      </CardOfBanners>
      <Typography
        tag="h2"
        text="Select Blockchain"
        weight="semi-bold"
        size="m"
        color="primary500"
      />
      <Typography
        tag="h3"
        text="Please select a blockchain to continue"
        weight="medium"
        size="s"
        color="gray500"
        className="pb20"
      />
      {!isGiBlockchainsDataLoading && giBlockchainsData.length > 0 ? (
        <BlockchainTable data={giBlockchainsData} />
      ) : null}
      {isGiBlockchainsDataLoading && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </>
  )
}
