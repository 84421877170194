import React from 'react'
import { Label } from 'components/lib'
import './reactSelectWithIcon.scss'
import Select, { components } from 'react-select'
import { transformSnakeCaseToSentence } from 'utils/helpers'

export const ReactSelectWithIcon = ({
  label,
  options,
  change,
  name,
  placeholder,
  isSearchable,
  classNamePrefix,
  value,
  defaultValue,
  size,
}) => {
  const { Control, Option, Placeholder } = components
  const customStyles = {
    menu: (base) => ({
      ...base,
      width: 'max-content',
      minWidth: '100%',
      borderRadius: '6px',
      marginTop: '8px',
    }),
  }

  const IconControl = ({ children, ...props }) => (
    <Control {...props}>
      <>
        {props.selectProps?.value?.icon && (
          <img
            src={props.selectProps.value.icon}
            alt={props.selectProps.value.value}
          />
        )}
        {children}
      </>
    </Control>
  )

  const IconOption = (props) => (
    <Option {...props}>
      {props.data.icon && <img src={props.data.icon} alt={props.data.label} />}
      {props.data.label}
    </Option>
  )

  const IconValuePlaceholder = ({ children, ...props }) => {
    const selectedOption = props.selectProps.options.find(
      (option) => option.value === props.selectProps.placeholder
    )

    return (
      <Placeholder {...props}>
        {selectedOption?.icon && selectedOption?.label && (
          <img
            src={selectedOption.icon}
            alt={selectedOption.label}
            className={`${classNamePrefix}__selected-icon`}
          />
        )}
        {transformSnakeCaseToSentence(children)}
      </Placeholder>
    )
  }

  return (
    <div className="react-select-with-icon-container">
      {label && <Label text={label} size={size} />}

      <Select
        value={value}
        options={options}
        onChange={change}
        id={name}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        classNamePrefix={classNamePrefix}
        styles={customStyles}
        components={{
          Option: IconOption,
          Control: IconControl,
          // ValueContainer: IconValueContainer,
          Placeholder: IconValuePlaceholder,
        }}
      />
    </div>
  )
}
