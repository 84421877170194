/***
 *
 *   useGiBasicDappsData hook
 *   for getting growth index dapps data and set in to context
 *
 **********/

import { useState, useEffect, useContext } from 'react'

import { logger } from 'utils/logger'
import { ViewContext } from 'components/lib'
import { fetchBlockchain } from 'utils/fetches/blockchain'
import { SingleBlockchainSchema } from 'types/blockchain'
import { getValidationErrorMessage } from 'utils/helpers'

export const useBlockchain = (slug: string) => {
  const [blockchain, setBlockchain] = useState()
  const [isBlockchainLoading, setIsBlockchainLoading] = useState(false)
  const viewContext = useContext(ViewContext)

  useEffect(() => {
    if (slug) {
      const fetchData = async () => {
        try {
          setIsBlockchainLoading(true)
          const fetchedBlockchain = await fetchBlockchain(slug)
          const validatedBlockchain = SingleBlockchainSchema.safeParse(
            fetchedBlockchain.data
          )
          if (!validatedBlockchain.success) {
            logger.error(validatedBlockchain.error)
            throw Error(getValidationErrorMessage('Blockchain'))
          }
          setBlockchain(fetchedBlockchain.data)
          setIsBlockchainLoading(false)
        } catch (err) {
          setIsBlockchainLoading(false)
          logger.error(err)
          viewContext.handleError(getValidationErrorMessage('Blockchain'))
        }
      }
      fetchData()
    }
  }, [slug])

  return {
    selectedBlockchain: blockchain,
    blockchain,
    isBlockchainLoading,
  }
}
