import React from 'react'
import { Card, CustomAreaChart, Loader, Typography } from 'components/lib'

import { SectionTopBar } from 'features/gi/shared/components/sectionTopBar/sectionTopBar'
import { CoverChartSection } from 'features/gi/shared/components/coverChartSection/coverChartSection'
import { ValueChartLabel } from '../valueChartLabel/ValueChartLabel'

export const ChartSection = ({
  loadingTimelineFlag,
  timelineData,
  singleValueData,
  title,
  blockchain,
  comparison,
  round,
  formatValue,
  prefixValue,
}) => {
  return (
    <Card>
      {singleValueData && (
        <ValueChartLabel
          singleValueData={singleValueData}
          comparison={comparison}
          blockchain={blockchain}
        />
      )}
      <SectionTopBar>
        <Typography
          text={title}
          tag="span"
          color="primary500"
          size="m"
          weight="regular"
        />
      </SectionTopBar>
      {loadingTimelineFlag && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
      {!loadingTimelineFlag && timelineData?.length > 0 && (
        <CustomAreaChart
          data={timelineData}
          additionalLegendData={[blockchain, comparison]}
          round={round}
          formatValue={formatValue}
          prefixValue={prefixValue}
          legend={
            timelineData.length > 0 && 'differential' in timelineData[0]
              ? true
              : false
          }
        />
      )}
      {!loadingTimelineFlag && timelineData?.data?.length === 0 && (
        <div className="min-height relative">
          <p className="align-xy">No data</p>
        </div>
      )}
      {!loadingTimelineFlag && timelineData?.message && <CoverChartSection />}
    </Card>
  )
}
