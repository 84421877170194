import { DashboardsPage } from 'views/dashboards/dashboards'
import { paths } from 'utils/paths'

const Routes = [
  {
    path: paths.dashboardsPublic,
    view: DashboardsPage,
    layout: 'redesignApp',
    title: 'Web3 Analytics | Patterns',
  },
  {
    path: '/dashboards/',
    view: DashboardsPage,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Dashboards',
  },
  {
    path: paths.dashboardsPersonal,
    view: DashboardsPage,
    layout: 'redesignApp',
    permission: 'user',
    title: 'My Dashboards',
  },
  {
    path: paths.dashboardsShared,
    view: DashboardsPage,
    layout: 'redesignApp',
    permission: 'user',
    title: 'Shared Dashboards',
  },
]

export default Routes
