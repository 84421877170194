/***
 *
 *   DAPP USERS
 *
 **********/

import React, { useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Loader,
  Paginate,
  ViewContext,
  Container,
  Breadcrumbs,
  useLocation,
  Typography,
} from 'components/lib'
import { useSeoPage } from 'hooks/useSeoPage'
import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'
import { breadcrumbs } from 'utils/constans'

import { fetchGiDappUserInsights } from 'utils/fetches/gi'

import { DappUsersFilters } from 'features/gi/crm/dappUsers/components/dappUsersFilters'
import { useDappUserInsightsFilters } from 'features/gi/crm/dappUsers/hooks/useDappUserInsightsFilters'
import { UsersBasicValues } from 'features/gi/crm/shared/components/UsersBasicValues'
import { ListOfUsers } from 'features/gi/crm/shared/components/listOfUsers'
import { DappUserProfile } from 'features/gi/crm/shared/components/dappUserProfile'
import { DappUsersResponseSchema } from 'features/gi/shared/types/dappUsersResponseSchema'
import placeholder from 'features/gi/shared/assets/icons/placeholder.svg'
import { useDapp } from 'features/gi/shared/hooks/useDapp'
import crmOG from 'assets/images/og/crm.png'

export function DappUsers() {
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [users, setUsers] = useState()
  const [metrics, setMetrics] = useState()
  const [totalRecords, setTotalRecords] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [, , secondSegment] = location.pathname.split('/')
  const { selectedDapp } = useDapp(secondSegment)
  const {
    verifiedDappSegment,
    sortByParam,
    sortTypeParam,
    verifiedAddressSegment,
    verifiedPage,
    verifiedPageSize,
    searchAddressParam,
    usedDappsOptions,
    verifiedOtherDappsUsedParam,
    selectedOtherDappsUsed,
    minDepositParam,
    maxDepositParam,
    minInteractionsParam,
    maxInteractionsParam,
    joinedStartDate,
    joinedEndDate,
    lastInteractionStartDateParam,
    lastInteractionEndDateParam,
    verifiedBlockchainsParam,
    selectedBlockchains,
    dappsOptions,
  } = useDappUserInsightsFilters(selectedDapp)
  const viewContext = useContext(ViewContext)
  const breadcrumbsItems = [
    breadcrumbs.home,
    breadcrumbs.dappsOverview,
    {
      key: 'analytics',
      url: `/app/${secondSegment}/analytics`,
      name: selectedDapp?.name ? `${selectedDapp?.name}` : 'dApp Analytics',
    },
    {
      key: 'web3 crm',
      url: `/app/${secondSegment}/web3-crm${location.search}`,
      name: 'Web3 CRM',
    },
  ]
  useSeoPage({
    title: selectedDapp?.name
      ? `${selectedDapp?.name} CRM | Patterns`
      : 'dApp CRM | Patterns',
    description: `Discover ${selectedDapp?.name} users, their conversions and other crypto apps used. Use our web3 CRM to boost ${selectedDapp?.name} revenue and minimize churn`,
    keywords: [`${selectedDapp?.name} CRM`, `${selectedDapp?.name} users`],
    ogTitle: selectedDapp?.name
      ? `${selectedDapp?.name} CRM | Patterns`
      : 'dApp CRM | Patterns',
    ogDescription: `Discover ${selectedDapp?.name} users, their conversions and other crypto apps used. Use our web3 CRM to boost ${selectedDapp?.name} revenue and minimize churn`,
    ogImage: crmOG,
    // ogUrl: to set,
  })

  const handleCurrentPage = (currentPage) => {
    searchParams.delete('page')
    searchParams.append('page', currentPage)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (selectedDapp) {
      const fetchData = async () => {
        try {
          setIsLoadingData(true)
          const bodyRequest = {
            dappSlug: selectedDapp.slug,
            orderType: sortTypeParam?.toUpperCase() || 'DESC',
            orderColumn: sortByParam || 'deposits',
            page: verifiedPage,
            pageSize: verifiedPageSize,
            filters: {
              minDeposit: minDepositParam,
              maxDeposit: maxDepositParam,
              joinedStartDate: joinedStartDate,
              joinedEndDate: joinedEndDate,
              lastInteractionStartDate: lastInteractionStartDateParam,
              lastInteractionEndDate: lastInteractionEndDateParam,
              minInteractions: minInteractionsParam,
              maxInteractions: maxInteractionsParam,
            },
          }

          if (verifiedBlockchainsParam) {
            bodyRequest.chains = selectedBlockchains.map(
              (blockchain) => blockchain.slug
            )
          }

          if (searchAddressParam) {
            bodyRequest.caller = searchAddressParam
          }

          if (verifiedOtherDappsUsedParam?.length > 0) {
            bodyRequest.otherDappsUsed = verifiedOtherDappsUsedParam.split(',')
          }

          const response = await fetchGiDappUserInsights(bodyRequest)
          const validatedDappUsers = DappUsersResponseSchema.safeParse(response)
          if (!validatedDappUsers.success) {
            logger.error(validatedDappUsers.error)
            throw Error(getValidationErrorMessage('Users'))
          }
          setUsers(validatedDappUsers.data.data)
          setTotalRecords(validatedDappUsers.data.totalRecords)
          setMetrics(validatedDappUsers.data.metrics)
          setIsLoadingData(false)
        } catch (err) {
          setIsLoadingData(false)
          viewContext.handleError(err)
        }
      }

      fetchData()
    }
  }, [
    verifiedDappSegment,
    verifiedPageSize,
    sortByParam,
    verifiedPage,
    sortTypeParam,
    searchAddressParam,
    verifiedOtherDappsUsedParam,
    maxDepositParam,
    minDepositParam,
    minInteractionsParam,
    maxInteractionsParam,
    joinedStartDate,
    joinedEndDate,
    lastInteractionStartDateParam,
    lastInteractionEndDateParam,
    verifiedBlockchainsParam,
  ])

  const prepareOptions = (arr) => {
    return arr?.map((item) => {
      return {
        label: item.name,
        value: item.slug,
        icon:
          item.logo && item.logo !== 'not available' ? item.logo : placeholder,
      }
    })
  }

  return (
    <Container noPadding className="mb20">
      <Breadcrumbs items={breadcrumbsItems} />
      {verifiedDappSegment ? (
        <>
          <Typography
            tag="h1"
            text={`${selectedDapp.name} - Web3 CRM`}
            className="pt20"
            color="primary500"
            size="m"
            weight="semi-bold"
          />
          {selectedOtherDappsUsed.length > 0 ||
          selectedOtherDappsUsed.length === 0 ? (
            <DappUsersFilters
              pageSize={verifiedPageSize}
              searchAddress={searchAddressParam}
              usedDappsOptions={prepareOptions(usedDappsOptions)}
              selectedUsedDapps={prepareOptions(selectedOtherDappsUsed)}
              selectedDapp={selectedDapp}
              selectedBlockchains={selectedBlockchains}
              verifiedDappSegment={verifiedDappSegment}
              dappsOptions={dappsOptions}
            />
          ) : null}
          <UsersBasicValues metrics={metrics} isLoadingData={isLoadingData} />
          <ListOfUsers
            verifiedAddressSegment={verifiedAddressSegment}
            isLoadingData={isLoadingData}
            users={users}
            sortByParam={sortByParam}
            sortTypeParam={sortTypeParam}
            minInteractionsParam={minInteractionsParam}
            maxInteractionsParam={maxInteractionsParam}
            maxDepositParam={maxDepositParam}
            minDepositParam={minDepositParam}
            joinedStartDate={joinedStartDate}
            joinedEndDate={joinedEndDate}
            lastInteractionStartDateParam={lastInteractionStartDateParam}
            lastInteractionEndDateParam={lastInteractionEndDateParam}
            dappSlug={selectedDapp.slug}
          />
          {!isLoadingData && totalRecords ? (
            <div className="align-center mt24 mb24">
              <Paginate
                totalRecords={totalRecords}
                limit={verifiedPageSize}
                currentPage={verifiedPage}
                onChange={(currentPage) => handleCurrentPage(currentPage)}
              />
            </div>
          ) : null}
          {verifiedAddressSegment && (
            <DappUserProfile
              verifiedAddress={verifiedAddressSegment}
              userData={users?.filter(
                (item) => item.caller === verifiedAddressSegment
              )}
              dappSlug={selectedDapp.slug}
            />
          )}
        </>
      ) : (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </Container>
  )
}
