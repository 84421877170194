/***
 *
 *   DashboardsSection
 *
 **********/

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Loader,
  Typography,
  Icon,
  Grid,
  Slide,
  ButtonNew,
} from 'components/lib'
import { fetchDashboards } from 'utils/fetches/dashboard'
import { paths } from 'utils/paths'

import { SlideAddDashboard } from 'features/gi/shared/components/slideAddDashboard/SlideAddDashboard'
import Style from './DashboardsSection.module.css'

type TDashboardSection = {
  slug: string
  id?: string
  type: 'blockchain' | 'dapp'
}

export const DashboardsSection = ({ slug, id, type }: TDashboardSection) => {
  const [dashboards, setDashboards] = useState<undefined | Array<any>>()
  const [isLoadingDashboards, setIsLoadingDashboards] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (type) {
      const fetchData = async () => {
        try {
          let param: string
          if (type === 'blockchain') {
            param = `/public?&blockchains=${slug}`
          } else {
            param = `/public?&dapps=${id}`
          }
          setIsLoadingDashboards(true)
          const response = await fetchDashboards(param)
          setDashboards(response)
          setIsLoadingDashboards(false)
        } catch (err) {
          setIsLoadingDashboards(false)
        }
      }

      fetchData()
    }
  }, [slug, id, type])

  return (
    <>
      <div className={Style['bar-container']}>
        <Typography
          text="dApp Dashboards"
          tag="h2"
          weight="medium"
          color="secondary800"
          size="m"
        />
        <ButtonNew color="white" moveTo={`/dashboards/public?${type}=${slug}`}>
          <Typography
            tag="span"
            text="View All"
            size="s"
            color="gray900"
            weight="medium"
          />
          <Icon name="diagonalArrow" height={12} width={12} color="gray700" />
        </ButtonNew>
      </div>
      {!isLoadingDashboards && dashboards?.length > 0 ? (
        <>
          <Grid displayGrid>
            {dashboards &&
              dashboards.length > 0 &&
              dashboards.map(({ title, id, dappName, dappLogo }) => {
                return (
                  <Slide
                    path={`${paths.dashboard}/${id}`}
                    id={id}
                    title={title}
                    key={id}
                    dappName={dappName}
                    dappLogo={dappLogo}
                  />
                )
              })}
          </Grid>
        </>
      ) : null}
      {!isLoadingDashboards && dashboards?.length === 0 ? (
        <SlideAddDashboard />
      ) : null}
      {isLoadingDashboards && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </>
  )
}
