import axios from 'axios'

import { endpoint } from 'utils/paths'
import settings from 'settings.json'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchGiDappUserInsightTimeline = async (
  verifiedAddress: string,
  chains: Array<string>,
  dappSlug: string
) => {
  let params = {
    userAddress: verifiedAddress,
    chains: chains,
  }

  if (dappSlug) {
    params.dapp = dappSlug
  }

  const response = await axios.get(
    `${serverURL}/${endpoint.apiGiDappUserInsightTimeline}`,
    {
      params: params,
    }
  )

  return response.data
}

export const fetchOtherDappsUsedData = async (dappSlug: string) => {
  const response = await axios.get(
    `${serverURL}/api/dapp/other-dapps?dappSlug=${dappSlug}`
  )

  return response.data
}
