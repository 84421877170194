import React, { Fragment } from 'react'

import { useSeoPage } from 'hooks/useSeoPage'
import {
  Card,
  Form,
  Message,
  Loader,
  useAPI,
  Container,
  useMobile,
} from 'components/lib'

export function Help() {
  const user = useAPI('/api/user')
  const isMobile = useMobile()
  useSeoPage({
    // title: to set,
    // description: to set,
    // keywords: to set,
    // ogTitle: to set,
    // ogDescription: to set,
    // ogImage: to set,
    // ogUrl: to set
  })

  return (
    <Fragment>
      <Container>
        <Message
          title="Need Help?"
          type="info"
          text="Contact our support team below. You can also enable or disable permission for our support team to access your account to help resolve any issues."
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {user.loading ? (
            <Loader />
          ) : (
            <Fragment>
              {/* Contact Support Card (Left Side) */}
              <div style={{ flex: 1, marginRight: '1rem' }}>
                <Card title="Contact Support">
                  <Form
                    data={{
                      support_enabled: {
                        type: 'radio',
                        options: ['Yes', 'No'],
                        required: true,
                        errorMessage: 'Please enter a message',
                        default: user.data?.support_enabled ? 'Yes' : 'No',
                        label: 'Can our support team access your account?',
                      },
                    }}
                    submitOnChange
                    method="PATCH"
                    url="/api/user"
                  />
                  <Form
                    data={{
                      email: {
                        type: 'hidden',
                        value: user?.data?.email,
                      },
                      name: {
                        type: 'hidden',
                        value: user?.data?.name,
                      },
                      template: {
                        type: 'hidden',
                        value: 'help',
                      },
                      message: {
                        type: 'textarea',
                        label: 'What can we help with?',
                        errorMessage: 'Please enter a message',
                        required: true,
                      },
                    }}
                    method="POST"
                    url="/api/utility/mail"
                    buttonText="Send Message"
                  />
                </Card>
              </div>
              {!isMobile && (
                <>
                  <div style={{ width: '1rem' }} />

                  {/* FAQ Card (Right Side) */}
                  <div style={{ flex: 1, marginLeft: '1rem' }}>
                    {/* <Card title="FAQ"></Card> */}
                  </div>
                </>
              )}
            </Fragment>
          )}
        </div>
      </Container>
    </Fragment>
  )
}
