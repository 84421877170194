/***
 *
 *   useGiBlockchainsData hook
 *   for getting growth index blockchains data and set in to context
 *
 **********/

import { useState, useEffect, useContext } from 'react'

import { fetchGiBlockchainsData } from 'utils/fetches/gi'
import { ViewContext, GiBlockchainsDataContext } from 'components/lib'

export const useGiBlockchainsData = () => {
  const [giBlockchainsData, setGiBlockchainsData] = useState([])
  const [isGiBlockchainsDataLoading, setIsGiBlockchainsDataLoading] =
    useState(false)
  const viewContext = useContext(ViewContext)
  const { giBlockchainsDataContext, setGiBlockchainsDataContext } = useContext(
    GiBlockchainsDataContext
  )

  useEffect(() => {
    if (giBlockchainsDataContext.length > 0) {
      setGiBlockchainsData(giBlockchainsDataContext)
    } else {
      const getGiBlockchainsData = async () => {
        try {
          setIsGiBlockchainsDataLoading(true)
          const fetchedGrowthIndexChainData = await fetchGiBlockchainsData()
          setGiBlockchainsData(fetchedGrowthIndexChainData.data)
          setGiBlockchainsDataContext(fetchedGrowthIndexChainData.data)
          setIsGiBlockchainsDataLoading(false)
        } catch (err) {
          setIsGiBlockchainsDataLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
      getGiBlockchainsData()
    }
  }, [])

  return { giBlockchainsData, isGiBlockchainsDataLoading }
}
