/***
 *
 *   PUBLIC DASHBOARDS
 *   Page with grid of public dashbboars
 *
 **********/

import React, { useEffect, useState } from 'react'

import {
  Animate,
  Grid,
  Typography,
  Loader,
  Container,
  Slide,
} from 'components/lib'
import { paths } from 'utils/paths'
import { fetchDashboards } from 'utils/fetches/dashboard'

import { usePublicDashboardsFilters } from 'features/dashboardsGrid/public/hooks/usePublicDashboardsFilters'
import { PublicDashboardsFilters } from 'features/dashboardsGrid/public/components/dappBasicFilters/PublicDashboardsFilters'

export const PublicDashboardsGrid = () => {
  const [publicDashboards, setPublicDashboards] = useState()
  const [isLoadingDashboards, setIsLoadingDashboards] = useState(false)
  const { selectedBlockchain, blockchainsOptions, dappsOptions, selectedDapp } =
    usePublicDashboardsFilters()

  useEffect(() => {
    if (
      selectedBlockchain &&
      blockchainsOptions &&
      dappsOptions &&
      selectedDapp
    ) {
      const fetchData = async () => {
        try {
          let param = `/public`
          if (selectedBlockchain) {
            param += `?blockchains=${selectedBlockchain.slug}`
          }

          if (selectedDapp) {
            param += selectedBlockchain
              ? `&dapps=${selectedDapp.id}`
              : `?dapps=${selectedDapp.id}`
          }
          setIsLoadingDashboards(true)
          const response = await fetchDashboards(param)
          setPublicDashboards(response)
          setIsLoadingDashboards(false)
        } catch (err) {
          setIsLoadingDashboards(false)
        }
      }

      fetchData()
    }
  }, [selectedBlockchain, blockchainsOptions, dappsOptions, selectedDapp])

  return (
    <Container marginTop16 marginBottom8 noPadding>
      <Animate type="pop">
        <PublicDashboardsFilters />
        <div className="relative min-height">
          <Grid displayGrid>
            {publicDashboards &&
              publicDashboards.length > 0 &&
              publicDashboards.map(({ title, id, dappName, dappLogo }) => {
                return (
                  <Slide
                    path={`${paths.dashboard}/${id}`}
                    id={id}
                    title={title}
                    key={id}
                    dappName={dappName}
                    dappLogo={dappLogo}
                  />
                )
              })}
            {!isLoadingDashboards &&
              publicDashboards &&
              publicDashboards?.length === 0 && (
                <Typography tag="p" text="No public dashboards" size="s" />
              )}
          </Grid>
          {isLoadingDashboards && <Loader />}
        </div>
      </Animate>
    </Container>
  )
}
