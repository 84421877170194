/***
 *
 *   RULESNAV
 *
 **********/

import React from 'react'
import { NavLink } from 'react-router-dom'

import { Typography } from 'components/lib'
import Style from './RulesNav.module.css'

export const RulesNav = ({ user }) => (
  <div className={Style['rules-nav-container']}>
    <NavLink to="/privacy-policy" target="_blank">
      <Typography
        text="Privacy Policy"
        tag="p"
        size="xs"
        weight="regular"
        color="gray400"
      />
    </NavLink>
    <NavLink to="/terms-of-use" target="_blank">
      <Typography
        text="Terms of Use"
        tag="p"
        size="xs"
        weight="regular"
        color="gray400"
      />
    </NavLink>
  </div>
)
