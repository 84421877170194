import React, { useRef } from 'react'

import { logger } from 'utils/logger'
import {
  Label,
  ButtonNew,
  IconButton,
  Typography,
  Line,
  TextInputNew,
} from 'components/lib'
import { Icon } from 'components/icon/Icon'
import { TSmartContractData } from 'types/dapp'
import { generateUniqueString } from 'utils/helpers'

import {
  convertToFileSize,
  shortenFileString,
} from 'features/dapps/addDapp/utils/helpers'
import Style from './SmartContractsSection.module.css'

export const SmartContractsSection = ({
  setDappToAdd,
  dappToAdd,
  previewMode,
}) => {
  const hiddenFileInputRefs = useRef([])
  const handleSCName = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: string
  ) => {
    const newAbis = dappToAdd.abis.map(
      (item: TSmartContractData, i: string) => {
        if (i === index) {
          return {
            ...item,
            name: e.target.value,
          }
        } else {
          return item
        }
      }
    )
    setDappToAdd((prevState) => ({
      ...prevState,
      abis: newAbis,
    }))
  }

  const handleSCAddress = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: string
  ) => {
    const newAbis = dappToAdd.abis.map(
      (item: TSmartContractData, i: string) => {
        if (i === index) {
          return {
            ...item,
            address: e.target.value,
          }
        } else {
          return item
        }
      }
    )
    setDappToAdd((prevState) => ({
      ...prevState,
      abis: newAbis,
    }))
  }

  const handleABIFileUpload = (e, index) => {
    const file = e.target.files[0]
    const fileSize = file?.size
    const fileName = file?.name
    const reader = new FileReader()
    reader.onload = (event) => {
      try {
        const abi = JSON.parse(event.target.result)

        const newAbis = dappToAdd.abis.map(
          (item: TSmartContractData, i: string) => {
            if (i === index) {
              return {
                ...item,
                abi: abi,
                fileSize: fileSize,
                fileName: fileName,
              }
            } else {
              return item
            }
          }
        )
        setDappToAdd((prevState) => ({
          ...prevState,
          abis: newAbis,
        }))
      } catch (error) {
        console.error('Error parsing JSON', error)
      }
    }
    reader.readAsText(file)
  }

  const addScFields = () => {
    setDappToAdd((prevState) => ({
      ...prevState,
      abis: [
        ...prevState.abis,
        {
          name: '',
          address: '',
          abi: '',
          i: generateUniqueString(prevState.abis),
        },
      ],
    }))
  }

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>, index) => {
    if (hiddenFileInputRefs.current) {
      hiddenFileInputRefs.current[index].click()
    } else {
      logger.error('hiddenFileInput ref is not assigned')
    }
  }

  const handleRemoveABI = (abiToRemove: TSmartContractData) => {
    const newAbis = dappToAdd.abis.filter(
      (item: TSmartContractData) => item.i !== abiToRemove.i
    )
    setDappToAdd((prevState) => ({
      ...prevState,
      abis: newAbis,
    }))
  }

  const verifyDisplayingAddScButton = () => {
    if (dappToAdd?.abis && dappToAdd?.abis.length > 0) {
      const abisLastIndex = dappToAdd?.abis.length - 1
      const abisLastPosition = dappToAdd?.abis[abisLastIndex]
      if (
        abisLastPosition.name &&
        abisLastPosition.address &&
        abisLastPosition.abi
      ) {
        return true
      }
    }

    return false
  }

  const isVisibleAddScButton = verifyDisplayingAddScButton()

  return (
    <>
      <Line className="mt24 mb16" />
      <Typography
        text="Smart Contracts"
        tag="h2"
        size="m"
        weight="medium"
        color="gray900"
      />
      {dappToAdd?.abis?.length > 0 ? (
        <ul className={Style['sc-content-container']}>
          {dappToAdd.abis.map((item: TSmartContractData, index: string) => {
            return (
              <li className={Style['sc-list-row']} key={item.i}>
                <div className={Style['numeration-column']}>
                  <Typography
                    text={index + 1}
                    tag="p"
                    size="m"
                    weight="medium"
                    color="gray900"
                  />
                </div>
                <TextInputNew
                  label="Smart Contract Name*"
                  name="Smart Contract Name"
                  change={(e) => handleSCName(e, index)}
                  size="large"
                  placeholder="Enter your Smart Contract"
                  value={item.name}
                  disabled={previewMode}
                />
                <TextInputNew
                  label="Smart Contract Address*"
                  name="Smart Contract Address"
                  change={(e) => handleSCAddress(e, index)}
                  size="large"
                  placeholder="Enter your Contract Address"
                  value={item.address}
                  disabled={previewMode}
                />
                <div className={Style['upload-sc-container']}>
                  {!previewMode ? (
                    <>
                      <Label text="&nbsp;" />
                      <input
                        type="file"
                        name="u"
                        accept=".json"
                        onChange={(e) => handleABIFileUpload(e, index)}
                        style={{ display: 'none' }}
                        ref={(el) => (hiddenFileInputRefs.current[index] = el)}
                      />
                      <div className={Style['close-button-container']}>
                        <ButtonNew
                          size="large"
                          variant="outline"
                          color="green500"
                          fullWidth
                          startIcon={
                            item?.abi ? (
                              <Icon name="file" height="16" width="16" />
                            ) : (
                              <Icon
                                name="upload"
                                color="green500"
                                width="16"
                                height="16"
                              />
                            )
                          }
                          onClick={(e) => handleClick(e, index)}
                        >
                          {item?.abi
                            ? `${shortenFileString(
                                item?.fileName
                              )} ${convertToFileSize(item?.fileSize)}`
                            : 'Upload ABI'}
                        </ButtonNew>
                        {dappToAdd?.abis?.length > 1 ? (
                          <IconButton
                            icon={
                              <Icon
                                name="exit"
                                height="16"
                                width="16"
                                color="gray600"
                              />
                            }
                            onClick={() => handleRemoveABI(item)}
                          />
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <TextInputNew
                      label="ABI"
                      name="ABI"
                      change={() => console.log('')}
                      size="large"
                      value={JSON.stringify(item.abi)}
                      disabled={previewMode}
                    />
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      ) : null}
      {dappToAdd?.abis?.length === 0 || !dappToAdd?.abis ? (
        <div className={Style['no-sc-container']}>
          <Typography
            text="No added Smart Contracts"
            tag="p"
            size="s"
            weight="regular"
            color="gray700"
          />
        </div>
      ) : null}
      {isVisibleAddScButton && !previewMode ? (
        <ButtonNew
          size="medium"
          color="gray"
          onClick={addScFields}
          startIcon={<Icon name="plusGray" width="16" height="16" />}
          fullWidth
        >
          Add More Smart Contracts
        </ButtonNew>
      ) : null}
    </>
  )
}
