import React from 'react'
import clsx from 'clsx'

import Style from './Label.module.css'

type TLabelProps = {
  size?: 'small'
  text: string
  forInput?: string
  capitalize?: boolean
}

export const Label = (props: TLabelProps) => {
  const labelStyle = clsx(
    Style['label'],
    props.size && Style[props.size],
    props.capitalize && Style['capitalize']
  )

  return (
    <label className={labelStyle} htmlFor={props.forInput}>
      {props.text}
    </label>
  )
}
