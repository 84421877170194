import { HomePage } from 'views/website/Home'

const Routes = [
  {
    path: '/',
    view: HomePage,
    layout: 'redesignApp',
    title: 'Patterns - User Activity of Top DeFi & Crypto Apps',
  },
]

export default Routes
