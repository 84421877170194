import React from 'react'

import { Icon, Typography } from 'components/lib'
import { INDICATOR_NAME } from 'utils/constans'

import { GiLegendItem } from 'features/gi/shared/components/giLegendItem/giLegendItem'
import { items } from 'features/gi/shared/data/legend'
import Style from './giLegend.module.scss'

export const GiLegend = () => (
  <div className={Style.giLegend}>
    <div className={Style.bgOpacity}></div>
    <div className={Style.titleContainer}>
      <Icon name="growthIndex" width="16" height="16" />
      <Typography
        tag="p"
        color="primary500"
        weight="medium"
        size="s"
        text={`${INDICATOR_NAME} Legend`}
      />
    </div>
    <ul className={Style.growthIndexLegendContent}>
      {items.map((item) => {
        return (
          <GiLegendItem
            key={item.level}
            scope={item.scope}
            icon={item.icon}
            level={item.level}
            info={item.info}
          />
        )
      })}
    </ul>
  </div>
)
