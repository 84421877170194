/***
 *
 *   DAPP USERS PAGE
 *
 **********/

import React from 'react'

import { Animate, MainContentContainer } from 'components/lib'

import { DappUsers } from 'features/gi/crm/dappUsers/dappUsers'

export function DappUsersPage() {
  return (
    <Animate type="pop">
      <MainContentContainer>
        <DappUsers />
      </MainContentContainer>
    </Animate>
  )
}
