import React from 'react'
import { useSearchParams, Link } from 'react-router-dom'

import {
  Icon,
  IconLib,
  useMobile,
  DropdownNew,
  Typography,
  Loader,
} from 'components/lib'
import { convertFormatDate, formatToMoney, shortenAddress } from 'utils/helpers'

import { OverflowElements } from 'features/gi/shared/components/overflowElements/OverflowElements'
import { DropdownNumbersContent } from 'features/gi/crm/shared/components/dropdownNumbersContent'
import { DropdownDateContent } from 'features/gi/crm/shared/components/dropdownDateContent'
import Style from './listOfUsers.module.scss'

export function ListOfUsers({
  users,
  verifiedAddressSegment,
  sortByParam,
  sortTypeParam,
  minDepositParam,
  maxDepositParam,
  minInteractionsParam,
  maxInteractionsParam,
  joinedStartDate,
  joinedEndDate,
  lastInteractionStartDateParam,
  lastInteractionEndDateParam,
  dappSlug,
  blockchainSlug,
  isLoadingData,
}) {
  const isMobile = useMobile()

  return (
    <div className={Style.tableContainer}>
      {!isLoadingData && users?.length > 0 ? (
        <table className={Style.usersTable}>
          <thead>
            <tr className={Style.head}>
              <th>Address</th>
              <th>Blockchain</th>
              <th role="button" className={Style.headCell}>
                <DropdownNew
                  content={
                    <DropdownNumbersContent
                      column="deposits"
                      sortTypeParam={sortTypeParam}
                      sortByParam={sortByParam}
                      minName="min-deposit"
                      maxName="max-deposit"
                      minParamValue={minDepositParam}
                      maxParamValue={maxDepositParam}
                      prefix="$"
                    />
                  }
                  id="deposits"
                >
                  <p className={Style.headCellContainer}>
                    Deposits
                    <IconLib
                      pack="own"
                      iconHeight="16"
                      iconWidth="16"
                      image="funnel"
                      color="gray600"
                      active={sortByParam === 'deposits'}
                    />
                  </p>
                </DropdownNew>
              </th>
              <th>
                <DropdownNew
                  content={
                    <DropdownDateContent
                      column="date_joined"
                      sortTypeParam={sortTypeParam}
                      sortByParam={sortByParam}
                      minName="joined-start-date"
                      maxName="joined-end-date"
                      minParamValue={joinedStartDate}
                      maxParamValue={joinedEndDate}
                    />
                  }
                  id="date_joined"
                >
                  <p className={Style.headCellContainer}>
                    Date Joined
                    <IconLib
                      pack="own"
                      iconHeight="16"
                      iconWidth="16"
                      image="funnel"
                      color="gray600"
                      active={sortByParam === 'date_joined'}
                    />
                  </p>
                </DropdownNew>
              </th>
              <th>
                <DropdownNew
                  content={
                    <DropdownDateContent
                      column="last_interaction_date"
                      sortTypeParam={sortTypeParam}
                      sortByParam={sortByParam}
                      minName="last-interaction-start-date"
                      maxName="last-interaction-end-date"
                      minParamValue={lastInteractionStartDateParam}
                      maxParamValue={lastInteractionEndDateParam}
                    />
                  }
                  id="last_interaction_date"
                >
                  <p className={Style.headCellContainer}>
                    Last Interaction
                    <IconLib
                      pack="own"
                      iconHeight="16"
                      iconWidth="16"
                      image="funnel"
                      color="gray600"
                      active={sortByParam === 'last_interaction_date'}
                    />
                  </p>
                </DropdownNew>
              </th>
              <th className={Style.headCell}>
                <DropdownNew
                  content={
                    <DropdownNumbersContent
                      column="interactions"
                      sortTypeParam={sortTypeParam}
                      sortByParam={sortByParam}
                      minName="min-interactions"
                      maxName="max-interactions"
                      minParamValue={minInteractionsParam}
                      maxParamValue={maxInteractionsParam}
                    />
                  }
                  id="interactions"
                >
                  <p className={Style.headCellContainer}>
                    Interactions
                    <IconLib
                      pack="own"
                      iconHeight="16"
                      iconWidth="16"
                      image="funnel"
                      color="gray600"
                      active={sortByParam === 'interactions'}
                    />
                  </p>
                </DropdownNew>
              </th>
              <th>Other dApps Used</th>
            </tr>
          </thead>
          <tbody>
            {users && users.length > 0
              ? users.map((user) => (
                  <tr
                    className={
                      verifiedAddressSegment === user.caller
                        ? `${Style.row} ${Style.activeRow}`
                        : Style.row
                    }
                    key={user.caller}
                  >
                    <DappUserColumn
                      user={user}
                      dappSlug={dappSlug}
                      blockchainSlug={blockchainSlug}
                    >
                      {user.user && (
                        <p className={Style.nameLabel}>{user.user}</p>
                      )}
                      {user.caller && Array.isArray(user.caller) ? (
                        <ul
                          className={`${Style.listOfAdresses} ${Style['column-margin']}`}
                        >
                          {user.caller.slice(0, 3).map((item) => {
                            return (
                              <li key={item}>
                                <span className={Style.addressText}>
                                  <Icon
                                    width="12"
                                    height="12"
                                    name="wallet"
                                    color="gray800"
                                  />
                                  {shortenAddress(item)}
                                </span>
                              </li>
                            )
                          })}
                        </ul>
                      ) : (
                        <span
                          className={`${Style.addressText} ${Style['column-margin']}`}
                        >
                          <Icon
                            width="12"
                            height="12"
                            name="wallet"
                            color="gray800"
                          />
                          {isMobile ? shortenAddress(user.caller) : user.caller}
                        </span>
                      )}
                    </DappUserColumn>
                    <DappUserColumn
                      user={user}
                      dappSlug={dappSlug}
                      blockchainSlug={blockchainSlug}
                    >
                      <div className={Style['blockchain-container']}>
                        <OverflowElements
                          data={users}
                          elements={[user.chain]}
                          type="blockchain"
                          size="m"
                          withFrame
                          id={user.name}
                        />
                      </div>
                    </DappUserColumn>
                    <DappUserColumn
                      user={user}
                      dappSlug={dappSlug}
                      blockchainSlug={blockchainSlug}
                    >
                      <Typography
                        text={formatToMoney(user.deposits)}
                        size="s"
                        color="primary500"
                        tag="p"
                      />
                    </DappUserColumn>
                    <DappUserColumn
                      user={user}
                      dappSlug={dappSlug}
                      blockchainSlug={blockchainSlug}
                    >
                      <Typography
                        text={convertFormatDate(user.date_joined)}
                        size="s"
                        color="primary500"
                        tag="p"
                      />
                    </DappUserColumn>
                    <DappUserColumn
                      user={user}
                      dappSlug={dappSlug}
                      blockchainSlug={blockchainSlug}
                    >
                      <Typography
                        text={convertFormatDate(user.last_interaction_date)}
                        size="s"
                        color="primary500"
                        tag="p"
                      />
                    </DappUserColumn>
                    <DappUserColumn
                      user={user}
                      dappSlug={dappSlug}
                      blockchainSlug={blockchainSlug}
                    >
                      <p className={Style.interactionBadge}>
                        {user.interactions}
                      </p>
                    </DappUserColumn>
                    <DappUserColumn
                      user={user}
                      dappSlug={dappSlug}
                      blockchainSlug={blockchainSlug}
                    >
                      <div className={Style['other-dapps-container']}>
                        <OverflowElements
                          data={users}
                          elements={user.other_dapps_used}
                          type="dapp"
                          size="xl"
                        />
                      </div>
                    </DappUserColumn>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      ) : null}
      {users?.length === 0 && !isLoadingData ? (
        <Typography
          tag="p"
          text="No Data"
          size="s"
          weight="regular"
          className="min-height align-center mt48 mb24"
        />
      ) : null}
      {isLoadingData && <Loader />}
    </div>
  )
}

const DappUserColumn = ({ children, dappSlug, blockchainSlug, user }) => {
  const [searchParams, _setSearchParams] = useSearchParams()
  const generateUserProfileUrl = (userCaller) =>
    dappSlug
      ? `/app/${dappSlug}/web3-crm/${userCaller}?${searchParams}`
      : `/crypto/${blockchainSlug}/web3-crm/${userCaller}?${searchParams}`
  console.log(`/crypto/${blockchainSlug}/web3-crm/user-caller?${searchParams}`)
  return (
    <td>
      <Link to={generateUserProfileUrl(user.caller)}>
        <div className={Style['column-container']}>{children}</div>
      </Link>
    </td>
  )
}
