import { z } from 'zod'

export const SingleValueSchema = z.object({
  currentValue: z.number(),
  previousValue: z.number(),
  change: z.number(),
  title: z.string(),
})

const CumulativeSchema = z
  .object({
    tg_growth_index: SingleValueSchema,
    avg_dau: SingleValueSchema,
    weekly_txns: SingleValueSchema,
    tvl: SingleValueSchema,
    market_cap: SingleValueSchema,
    weekly_fees: SingleValueSchema,
    cum_deployed_scs: SingleValueSchema,
  })
  .or(z.object({}).partial())

export const BasicValuesSchema = z.object({
  mainBlockchainName: z.string(),
  compareWithBlockchainNames: z.array(z.string()),
  blockchain: z.object({
    tg_growth_index: SingleValueSchema,
    avg_dau: SingleValueSchema,
    weekly_txns: SingleValueSchema,
    tvl: SingleValueSchema,
    market_cap: SingleValueSchema,
    weekly_fees: SingleValueSchema,
    cum_deployed_scs: SingleValueSchema,
  }),
  cumulative: CumulativeSchema,
})
