/***
 *
 *   Dashboard Builder Page
 *
 **********/

import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Typography, useMobile } from 'components/lib'
import { useSeoPage } from 'hooks/useSeoPage'

import { AddSegmentModalProvider } from 'features/builders/shared/contexts/AddSegmentModalContext'
import { DeleteSegmentModalProvider } from 'features/builders/shared/contexts/DeleteSegmentModalContext'
import { SegmentManagementModalProvider } from 'features/builders/shared/contexts/SegmentManagementModalContext'
import { BlockFlowProvider } from 'features/builders/shared/contexts/BlockFlowContext'
import { BlockChartProvider } from 'features/builders/shared/contexts/BlockChartContext'
import { BlockButtonProvider } from 'features/builders/shared/contexts/BlockButtonContext'
import { BlockTextProvider } from 'features/builders/shared/contexts/BlockTextContext'
import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { DashboardBuilder } from 'features/builders/dashboardBuilder/DashboardBuilder'
import { useDashboard } from 'components/hooks/useDashboard'
import {
  prepareElementsFormat,
  prepareLayoutFormat,
} from 'features/dashboard/utils/helpers'
import { tokenguardTheme } from 'utils/themes/tokenguard'
import builderOG from 'assets/images/og/builder.png'

export const DashboardBuilderPage = () => {
  const { id, dappId } = useParams()
  const isMobile = useMobile()
  const { dashboard } = useDashboard(id)
  const {
    setDashboardTitle,
    setDashboardId,
    setDashboardElements,
    setDashboardLayout,
    setDashboardTheme,
    setDashboardFilters,
    setDashboardDappId,
  } = useContext(DashboardContentContext)
  useSeoPage({
    // title: to set,
    // description: to set,
    // keywords: to set,
    // ogTitle: to set,
    // ogDescription: to set,
    ogImage: builderOG,
    // ogUrl: to set
  })

  useEffect(() => {
    if (dashboard) {
      setDashboardTitle(dashboard.dashboardCreator.title)
      setDashboardId(dashboard.dashboard.id)
      setDashboardDappId(dashboard.dashboardCreator.dappId)
      setDashboardElements(
        prepareElementsFormat(dashboard.dashboardCreator.elements)
      )
      setDashboardLayout(
        prepareLayoutFormat(dashboard.dashboardCreator.layout, false)
      )
      setDashboardTheme(
        dashboard.dashboardCreator.theme
          ? dashboard.dashboardCreator.theme
          : tokenguardTheme
      )
      setDashboardFilters(dashboard.filters)
    } else {
      setDashboardElements([])
      setDashboardLayout([])
      setDashboardTheme(tokenguardTheme)
      setDashboardDappId(dappId)
      setDashboardTitle('Default Dashboard')
    }
  }, [dashboard, dappId])

  if (isMobile) {
    return (
      <div className="min-height align-xy mt48">
        <Typography
          tag="p"
          size="s"
          text="The dashboard creator is only available on the desktop"
          align="center"
        />
      </div>
    )
  }

  return (
    <AddSegmentModalProvider>
      <DeleteSegmentModalProvider>
        <SegmentManagementModalProvider>
          <BlockFlowProvider>
            <BlockChartProvider>
              <BlockButtonProvider>
                <BlockTextProvider>
                  <DashboardBuilder />
                </BlockTextProvider>
              </BlockButtonProvider>
            </BlockChartProvider>
          </BlockFlowProvider>
        </SegmentManagementModalProvider>
      </DeleteSegmentModalProvider>
    </AddSegmentModalProvider>
  )
}
