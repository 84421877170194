/***
 *
 *   VALUE CHART LABEL
 *   small label for displaying final result of chart metric
 *
 **********/

import React from 'react'
import Style from './valueChartLabel.module.scss'
import { tidyNumbers } from 'features/gi/ecosystem/shared/utils/helpers'

export const ValueChartLabel = ({
  singleValueData,
  blockchain,
  comparison,
}) => {
  if (
    singleValueData?.blockchain?.currentValue &&
    !singleValueData.cumulative?.currentValue
  ) {
    return (
      <div className={Style.bigLabel}>
        {tidyNumbers(singleValueData.blockchain.currentValue)}
      </div>
    )
  } else if (
    singleValueData?.blockchain?.currentValue &&
    singleValueData.cumulative?.currentValue
  ) {
    return (
      <div className={Style.labelsContainer}>
        <div className={`${Style.smallLabel} ${Style.primary}`}>
          <img src={blockchain.logo} alt="" />
          <p>
            {typeof singleValueData.blockchain?.currentValue === 'number'
              ? tidyNumbers(singleValueData.blockchain?.currentValue)
              : 'N/A'}
          </p>
        </div>
        <div className={`${Style.smallLabel} ${Style.dark}`}>
          <img src={comparison.logo} alt="" />
          <p>
            {typeof singleValueData.cumulative.currentValue === 'number'
              ? tidyNumbers(singleValueData.cumulative.currentValue)
              : 'N/A'}
          </p>
        </div>
      </div>
    )
  } else {
    return null
  }
}
