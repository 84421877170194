import React from 'react'
import ReactDOMServer from 'react-dom/server'

import { CustomTooltip } from 'components/lib'

import Style from './giLegendItem.module.scss'

export const GiLegendItem = ({ scope, icon, level, info }) => {
  return (
    <li className={Style.tgGrowthLegendItem} key={level}>
      <div
        className="relative"
        data-tooltip-id={info}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          <TgGrowthIndexLevelInformation
            scope={scope}
            info={info}
            icon={icon}
          />
        )}
        data-tooltip-place="bottom-start"
        data-tooltip-position-strategy="absolute"
        data-tooltip-offset={8}
      >
        <img className={Style.icon} src={icon} alt="" />
        <div className={`${Style.stripe} ${Style[level]}`} />
        <p className={Style.scope}>{scope}</p>
        <CustomTooltip id={info} light />
      </div>
    </li>
  )
}

const TgGrowthIndexLevelInformation = ({ info, scope, icon }) => (
  <div className={Style.tooltipContainer}>
    <div className={Style.tooltipScopeContainer}>
      <img src={icon} alt="level icon" />
      <p className={Style.tooltipScope}>{scope}</p>
    </div>
    <p className={Style.tooltipInfo}>{info}</p>
  </div>
)
