import React, { useState, useEffect, useContext } from 'react'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { ModalNew } from 'components/lib'

import { BlockTextContext } from 'features/builders/shared/contexts/BlockTextContext'
import { TextEditor } from '../textEditor/TextEditor'
import Style from './BlockTextModifier.module.css'

export const BlockTextModifier = () => {
  const blockTextContext = useContext(BlockTextContext)
  const [contentText, setContentText] = useState('')
  const { blockTextId, setBlockTextId } = blockTextContext
  const {
    dashboardElements,
    setDashboardElements,
    setDashboardLayout,
    dashboardLayout,
  } = useContext(DashboardContentContext)

  useEffect(() => {
    const textData = dashboardElements.filter(
      (element) => element.i === blockTextId
    )

    if (textData.length > 0) {
      setContentText(textData[0].text)
    }
  }, [blockTextId])

  const updateTextBlock = (modifiedText: string) => {
    setContentText(modifiedText)
    const textData = dashboardElements.filter(
      (element) => element.i === blockTextId
    )

    if (textData.length > 0) {
      const updatedDashboardElements = dashboardElements.map((element) => {
        if (element.i === blockTextId) {
          return {
            text: modifiedText,
            type: 'text',
            i: blockTextId,
          }
        } else {
          return element
        }
      })
      const updatedDashboardLayout = dashboardLayout.map((item) => {
        return item
      })
      setDashboardElements(updatedDashboardElements)
      setDashboardLayout(updatedDashboardLayout)
      setBlockTextId(undefined)
    } else {
      setDashboardElements([
        ...dashboardElements,
        {
          text: modifiedText,
          type: 'text',
          i: blockTextId,
        },
      ])
      setDashboardLayout([
        ...dashboardLayout,
        { i: blockTextId, x: 0, y: 0, w: 1, h: 2, minH: 2 },
      ])
      setBlockTextId(undefined)
    }
  }

  return (
    <>
      {blockTextId && (
        <ModalNew
          title="Text Settings"
          hasCloseButton
          isOpen={() => setBlockTextId(undefined)}
        >
          <div className={Style['container']}>
            <TextEditor data={contentText} saveDataFn={updateTextBlock} />
          </div>
        </ModalNew>
      )}
    </>
  )
}
