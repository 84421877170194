/***
 *
 *   BUILDER APP LAYOUT
 *   Main application layout, visible for logged user
 *
 *   PROPS
 *   children
 *
 **********/

import React from 'react'

import stainTop from 'assets/images/background-stain-top.svg'
import stainBottom from 'assets/images/background-stain-bottom.svg'

import Style from './BuilderLayout.module.css'

export function BuilderLayout(props) {
  return (
    <>
      <main className={Style['builder-layout']}>
        {<props.children {...props.data} />}
      </main>
      <img className={Style['bg-stain-top']} src={stainTop} alt="" />
      <img className={Style['bg-stain-bottom']} src={stainBottom} alt="" />
    </>
  )
}
