import { z } from 'zod'

export const SingleRowResultSchema = z
  .object({
    dimension: z.union([z.string(), z.number()]),
  })
  .passthrough()
  .refine((obj) => Object.keys(obj).length > 1, {
    message:
      "The object must contain at least one additional field besides 'dimension'",
  })

export const ResultSchema = z.array(SingleRowResultSchema)

export type TResult = z.infer<typeof ResultSchema>
export type TRowResult = z.infer<typeof SingleRowResultSchema>
