import React, { useState, useEffect, useContext } from 'react'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { ButtonNew, TextInputNew, ModalNew } from 'components/lib'
import { BlockButtonContext } from 'features/builders/shared/contexts/BlockButtonContext'

import Style from './BlockButtonModifier.module.css'

export const BlockButtonModifier = () => {
  const [buttonText, setButtonText] = useState('')
  const [buttonLink, setButtonLink] = useState('')
  const buttonContext = useContext(BlockButtonContext)
  const { blockButtonId, setBlockButtonId } = buttonContext
  const {
    dashboardElements,
    setDashboardElements,
    setDashboardLayout,
    dashboardLayout,
  } = useContext(DashboardContentContext)

  useEffect(() => {
    const buttonData = dashboardElements.filter(
      (element) => element.i === blockButtonId
    )

    if (buttonData.length > 0) {
      setButtonText(buttonData[0].text)
      setButtonLink(buttonData[0].link)
    }
  }, [blockButtonId])

  const saveButton = () => {
    const buttonData = dashboardElements.filter(
      (element) => element.i === blockButtonId
    )

    if (buttonData.length > 0) {
      const updatedDashboardElements = dashboardElements.map((element) => {
        if (element.i === blockButtonId) {
          return {
            link: buttonLink,
            text: buttonText,
            type: 'button',
            i: blockButtonId,
          }
        } else {
          return element
        }
      })
      setDashboardElements(updatedDashboardElements)
      const updatedDashboardLayout = dashboardLayout.map((item) => {
        return item
      })
      setDashboardLayout(updatedDashboardLayout)
      setBlockButtonId(undefined)
    } else {
      setDashboardElements([
        ...dashboardElements,
        {
          link: buttonLink,
          text: buttonText,
          type: 'button',
          i: blockButtonId,
        },
      ])
      setDashboardLayout([
        ...dashboardLayout,
        { i: blockButtonId, x: 0, y: 0, w: 1, h: 2, minH: 2 },
      ])
      setBlockButtonId(undefined)
    }
  }

  return (
    <>
      {blockButtonId && (
        <ModalNew
          title="Button Settings"
          hasCloseButton
          isOpen={() => setBlockButtonId(undefined)}
        >
          <div className={Style['container']}>
            <TextInputNew
              label="Button Text"
              name="button-text"
              defaultValue={buttonText}
              value={buttonText}
              placeholder={buttonText || 'Example Button'}
              change={(e) => setButtonText(e.target.value)}
            />
            <TextInputNew
              label="Link"
              name="link"
              defaultValue={buttonLink}
              value={buttonLink}
              placeholder={buttonLink || 'Enter button link'}
              change={(e) => setButtonLink(e.target.value)}
            />
            <ButtonNew onClick={() => saveButton()}>Save</ButtonNew>
          </div>
        </ModalNew>
      )}
    </>
  )
}
