import { z } from 'zod'

export const SingleBlockchainSchema = z.object({
  active: z.boolean(),
  dappanalytics: z.boolean().optional(),
  dappgrowth: z.boolean(),
  growthindex: z.boolean(),
  id: z.string(),
  logo: z.string(),
  name: z.string(),
  network: z.string(),
  slug: z.string(),
  // database: "xxx_mainnet"
  // __v: 0
  // _id: ""
})

export const BlockchainsSchema = z.array(SingleBlockchainSchema)

export const BlockchainOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  name: z.string(),
  icon: z.string(),
  logo: z.string().optional(),
  slug: z.string(),
})

export type TBlockchains = z.infer<typeof BlockchainsSchema>
export type TSingleBlockchain = z.infer<typeof SingleBlockchainSchema>
export type TBlockchainOption = z.infer<typeof BlockchainOptionSchema>
