import React, { useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

import { Label, Icon } from 'components/lib'

import './DateRangeInput.css'
import './reactCalendar.css'
import Style from './reactDatePicker.module.css'

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

export const DateRangeInput = (props) => {
  const [value, onChange] = useState<Value>([new Date(), new Date()])

  return (
    <div className={Style['date-picker-container']}>
      {props.label && <Label text={props.label} size={props.size} />}
      <DateRangePicker
        onChange={props.onChange}
        value={props.value}
        clearIcon={props.clearIcon}
        locale="en-EN"
        showLeadingZeros
        format={props.format}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        calendarIcon={
          <Icon width="14" height="16" name="calendar" color="gray900" />
        }
      />
    </div>
  )
}
