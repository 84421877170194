import React, { useContext } from 'react'

import {
  CustomAreaChart,
  CustomPieChart,
  SingleValue,
  CustomBarChart,
} from 'components/lib'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { adjustForSingleValue, verifyDataForSingleValue } from 'utils/helpers'

import Style from './ResultingChart.module.css'

export const ResultingChart = ({
  data,
  chartType,
  chartTitle,
  selectedUnit,
}) => {
  const { dashboardTheme } = useContext(DashboardContentContext)
  const validateSingleValueData = (data: unknown) => {
    if (verifyDataForSingleValue(data, selectedUnit?.value)) {
      return (
        <div className={Style['single-value-container']}>
          <SingleValue
            data={adjustForSingleValue(data, 'N/A', selectedUnit?.value)}
            theme={dashboardTheme}
            title={chartTitle}
            loading={false}
            prefix={''}
          />
        </div>
      )
    } else {
      return (
        <p className={Style['text-notification']}>
          Not possible to display single value metric for selected data
        </p>
      )
    }
  }

  return (
    <div className={Style['resulting-chart-container']}>
      {data &&
        {
          areaChart: <CustomAreaChart data={data} theme={dashboardTheme} />,
          lineChart: <CustomAreaChart data={data} theme={dashboardTheme} />,
          barChart: <CustomBarChart data={data} theme={dashboardTheme} />,
          pieChart: <CustomPieChart data={data} theme={dashboardTheme} />,
          singleValue: <>{validateSingleValueData(data)}</>,
        }[chartType]}
    </div>
  )
}
