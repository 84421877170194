import React from 'react'
import DatePicker from 'react-date-picker'

import { Label, Icon } from 'components/lib'

import './reactDatePicker.css'
import './reactCalendar.css'
import Style from './reactDatePicker.module.css'

export const ReactDatePicker = (props) => {
  return (
    <div className={Style['date-picker-container']}>
      {props.label && <Label text={props.label} size={props.size} />}
      <DatePicker
        onChange={props.onChange}
        value={props.value}
        clearIcon={props.clearIcon}
        locale="en-EN"
        showLeadingZeros
        format={props.format}
        calendarIcon={
          <Icon width="14" height="16" name="calendar" color="gray900" />
        }
      />
    </div>
  )
}
