import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { SmallHeadline, CustomSelectNew, Typography } from 'components/lib'
import { objectToQueryString } from 'utils/helpers'
import { INDICATOR_NAME } from 'utils/constans'

import { Sorter } from 'features/gi/dapp/giDappOverview/components/sorter'
import Style from './giDappOverviewFilters.module.scss'

export const GiDappOverviewFilters = ({
  giSortValue,
  selectedBlockchain,
  blockchainsOptions,
  verifiedBlockchainParam,
  verifiedPageSize,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const positionQuantityOptions = [
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  const handleBlockchinFilter = (option) => {
    const queryParams = {
      blockchain: option.value,
    }
    if (giSortValue) {
      queryParams['growth-index'] = giSortValue
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  const handlePositionQuantity = (option) => {
    searchParams.delete('page')
    searchParams.append('page', 1)
    searchParams.delete('page-size')
    searchParams.append('page-size', option.value)
    setSearchParams(searchParams)
  }

  const sortByGi = (value) => {
    const queryParams = {
      'growth-index': value,
    }
    if (verifiedBlockchainParam) {
      queryParams['blockchain'] = verifiedBlockchainParam
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  return (
    <>
      <Typography
        tag="p"
        text="Filter:"
        weight="semi-bold"
        size="m"
        color="gray900"
      />
      <section className={Style.section}>
        <div className={Style.filters}>
          <CustomSelectNew
            options={blockchainsOptions}
            change={handleBlockchinFilter}
            name="blockchain"
            size="medium"
            color="green500"
            value={{
              value: selectedBlockchain.slug,
              label: selectedBlockchain.name,
              icon: selectedBlockchain.logo || selectedBlockchain.icon,
            }}
          />
          <Sorter
            title={INDICATOR_NAME}
            size="medium"
            action={() => sortByGi(giSortValue === 'desc' ? 'asc' : 'desc')}
            sortOrder={giSortValue || 'random'}
          />
        </div>
        <CustomSelectNew
          isSearchable={false}
          withArrow
          prefix="Show on page:"
          defaultValue={{
            value: verifiedPageSize,
            label: verifiedPageSize.toString(),
          }}
          options={positionQuantityOptions}
          change={handlePositionQuantity}
          size="medium"
        />
      </section>
    </>
  )
}
