/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from 'react'

import {
  AuthContext,
  ViewContext,
  Form,
  Card,
  Typography,
  Button,
  useNavigate,
  TabBar,
  useAPI,
  Event,
  useMobile,
  Breadcrumbs,
  MainContentContainer,
} from 'components/lib'
import { useSeoPage } from 'hooks/useSeoPage'
import { paths } from 'utils/paths'
import { breadcrumbs } from 'utils/constans'

export function Profile() {
  const navigate = useNavigate()
  const isMobile = useMobile()
  const breadcrumbsItems = [
    breadcrumbs.home,
    {
      key: 'profile',
      url: '/account/profile',
      name: 'Settings',
    },
  ]
  const tabs = [
    {
      name: 'Profile',
      path: `/account/profile`,
      onClick: () => navigate(`/account/profile`),
    },
    {
      name: 'Password',
      path: `/account/password`,
      onClick: () => navigate(`/account/password`),
    },
  ]
  useSeoPage({
    // title: to set,
    // description: to set,
    // keywords: to set,
    // ogTitle: to set,
    // ogDescription: to set,
    // ogImage: to set,
    // ogUrl: to set
  })

  // context
  const authContext = useContext(AuthContext)
  const viewContext = useContext(ViewContext)

  // fetch
  const user = useAPI('/api/user')

  function closeAccount() {
    viewContext.modal.show(
      {
        title: 'Close Your Account',
        form: {},
        buttonText: 'Close Account',
        url: authContext.permission.owner ? '/api/account' : '/api/user',
        method: 'DELETE',
        destructive: true,
        text:
          'Are you sure you want to delete your account? ' +
          'You will lose all of your data and this can not be undone.',
      },
      () => {
        // destory user
        Event('closed_account')
        localStorage.clear()
        authContext.signout()
      }
    )
  }

  return (
    <MainContentContainer>
      <Breadcrumbs items={breadcrumbsItems} />
      <Typography
        tag="h1"
        text="Settings"
        size="l"
        color="gray900"
        weight="semibold"
        className="mt24 mb8"
      />
      <TabBar underlyingLine tabs={tabs} />
      <div
        style={
          isMobile
            ? { marginTop: '24px' }
            : { marginRight: '70%', minWidth: '450px', marginTop: '24px' }
        }
      >
        <Card loading={user.loading} restrictWidth>
          {user?.data && (
            <Form
              buttonText="Save"
              url="/api/user"
              method="PATCH"
              data={{
                name: {
                  label: 'Your Name',
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: 'Please enter your name',
                },
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: user.data.email,
                  errorMessage: 'Please enter your email address',
                },
                ...(user.data.permission === 'owner' && {
                  account_name: {
                    type: 'text',
                    label: 'Account Name',
                    value: user.data.account_name,
                  },
                }),
                ...(user.data.accounts?.length > 1 && {
                  default_account: {
                    label: 'Default Account',
                    type: 'select',
                    default: user.data.default_account,
                    options: user.data.accounts.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      }
                    }),
                  },
                }),
              }}
              callback={(res) => {
                // update the account name
                if (authContext.user?.accounts?.length > 0) {
                  const accounts = [...authContext.user.accounts]
                  accounts[
                    accounts.findIndex(
                      (x) => x.id === authContext.user.account_id
                    )
                  ].name = res.data.data.account_name
                  authContext.update({ accounts: accounts })
                }

                // update the user name
                authContext.update({ name: res.data.data.name })
              }}
            />
          )}

          <Fragment>
            <br />
            <Button textOnly action={closeAccount} text="Close Account" />
          </Fragment>
        </Card>
      </div>
    </MainContentContainer>
  )
}
