import React from 'react'
import { useState } from 'react'

import { useOutsideClick } from 'hooks/useOutsideClick'
import { IconButton, TextInputNew, Icon } from 'components/lib'

import Style from './SegmentTitle.module.css'

export const SegmentTitle = ({ name, setName }) => {
  const [editing, setEditing] = useState<boolean>(false)

  const ref = useOutsideClick(() => {
    setEditing(false)
  })

  const handleClick = () => {
    setEditing(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleBlur = () => {
    setEditing(false)
  }

  return (
    <div className={Style['segment-title-container']} ref={ref}>
      {editing ? (
        <TextInputNew
          name="segment-title"
          value={name}
          change={handleChange}
          blur={handleBlur}
        />
      ) : (
        <p className={Style['title']}>{name}</p>
      )}
      <IconButton
        onClick={handleClick}
        icon={<Icon name="edit" height="1.6rem" width="1.6rem" />}
      />
    </div>
  )
}
