import React from 'react'

import { Label, IconLib } from 'components/lib'

import Style from './sorter.module.scss'

export const Sorter = ({ title, label, action, sortOrder, size }) => {
  const verifySortIcon = (sortOrder) => {
    switch (sortOrder) {
      case 'asc':
        return (
          <IconLib
            pack="own"
            image="asc"
            iconHeight="1.2rem"
            iconWidth="1.2rem"
          />
        )
      case 'desc':
        return (
          <IconLib
            pack="own"
            image="desc"
            iconHeight="1.2rem"
            iconWidth="1.2rem"
          />
        )
      default:
        return (
          <IconLib
            pack="own"
            image="random"
            iconHeight="1.2rem"
            iconWidth="1.2rem"
          />
        )
    }
  }

  return (
    <div className={Style.sorterContainer}>
      {label && <Label text={label} size={size} />}

      <button className={Style.sorterButton} onClick={() => action()}>
        {verifySortIcon(sortOrder)}
        <p className={Style.sorterText}>{title}</p>
      </button>
    </div>
  )
}
