import React from 'react'
import ReactDOMServer from 'react-dom/server'
import clsx from 'clsx'

import { AnimationValue, CustomTooltip, Typography, Icon } from 'components/lib'

import Style from './insightSingleValue.module.scss'

export const InsightSingleValue = ({
  title,
  value,
  prefix,
  color,
  tooltipInfo,
}) => {
  const insightSingleValueStyle = clsx(
    Style['container'],
    color && Style[color]
  )

  return (
    <div className={insightSingleValueStyle}>
      <p className={Style.value}>
        <AnimationValue value={value} prefix={prefix} />
      </p>
      <div className={Style.titleContainer}>
        <p className={Style.title}>{title}</p>
        <div
          className="relative"
          data-tooltip-id={title}
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <Typography
              text={tooltipInfo}
              tag="p"
              weight="regular"
              size="s"
              color="gray700"
            />
          )}
          data-tooltip-place="bottom-start"
          data-tooltip-position-strategy="fixed"
          data-tooltip-offset={8}
        >
          <Icon name="information" width="16" height="16" />
          <CustomTooltip id={title} light />
        </div>
      </div>
    </div>
  )
}
