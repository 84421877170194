/***
 *
 *   APP LAYOUT
 *   Main application layout, visible for logged user
 *
 *   PROPS
 *   children
 *
 **********/

import React, { Fragment } from 'react'
import { Footer, Page } from 'components/lib'
import Style from './app.module.scss'

export function AppLayout(props) {
  return (
    <Fragment>
      <Page>
        <main className={Style.app}>{<props.children {...props.data} />}</main>
        <Footer />
      </Page>
    </Fragment>
  )
}
