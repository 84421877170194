import React from 'react'
import { Tooltip } from 'react-tooltip'
import clsx from 'clsx'
import 'react-tooltip/dist/react-tooltip.css'

import Style from './CustomTooltip.module.css'

type TReactTooltipProps = {
  light: boolean
  size?: 'small'
  withArrow?: boolean
  id: string
}

export const CustomTooltip = ({
  id,
  withArrow,
  light,
  size,
}: TReactTooltipProps) => {
  const tooltipStyle = clsx(
    Style['tooltip-base'],
    size ? Style[size] : null,
    light && Style['light']
  )

  return (
    <Tooltip
      id={id}
      className={tooltipStyle}
      classNameArrow={withArrow ? Style.arrow : Style.noArrow}
    />
  )
}
