import axios from 'axios'

import { endpoint } from 'utils/paths'
import settings from 'settings'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchGiBlockchainsData = async () => {
  const response = await axios.get(
    `${serverURL}/${endpoint.apiGiBlockchainsList}`
  )

  return response.data
}

export const fetchGiDappAIInsights = async (bodyRequest) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.apiDappAIInsight}`,
    bodyRequest
  )

  return response.data
}

export const fetchGiDappUserInsights = async (bodyRequest) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.apiDappUserInsight}`,
    bodyRequest
  )

  return response.data
}

export const fetchGiDappUserInsightsByChain = async (bodyRequest) => {
  const response = await axios.post(
    `${serverURL}/${endpoint.apiDappUserInsightByChain}`,
    bodyRequest
  )

  return response.data
}
