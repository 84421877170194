/***
 *
 *   PAGINATE
 *   Pagination control to split results into multple pages
 *   Returns a new offset
 *
 **********/

import React from 'react'

import { Button, ClassHelper } from 'components/lib'

import Style from './paginate.module.scss'

export const Paginate = ({
  totalRecords,
  limit,
  currentPage,
  onChange,
  className,
  loading,
}) => {
  const totalPages = Math.ceil(totalRecords / limit)

  function prev() {
    if (currentPage > 1) {
      onChange(currentPage - 1)
    }
  }

  function next() {
    if (currentPage < totalPages) {
      onChange(currentPage + 1)
    }
  }

  const paginateStyle = ClassHelper(Style, {
    paginate: true,
    className: className,
  })

  return (
    <section className={paginateStyle}>
      <Button
        icon="chevron-left"
        size={20}
        color="transparent"
        className={Style.button}
        iconColor={currentPage > 1 || loading ? 'primary' : 'grey'}
        action={prev}
      />

      <span className={Style.counter}>
        {currentPage || limit
          ? ` ${currentPage} of ${totalPages} `
          : 'No Results'}
      </span>

      <Button
        icon="chevron-right"
        size={20}
        color="transparent"
        className={Style.button}
        iconColor={currentPage < totalPages || loading ? 'primary' : 'grey'}
        action={next}
      />
    </section>
  )
}
