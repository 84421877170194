/***
 *
 *   DASHBOARD BUILDER
 *
 **********/

import React, { useContext } from 'react'

import { Breadcrumbs } from 'components/lib'
import { paths } from 'utils/paths'
import { breadcrumbs } from 'utils/constans'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { BlockButtonContext } from 'features/builders/shared/contexts/BlockButtonContext'
import { BlockTextContext } from 'features/builders/shared/contexts/BlockTextContext'
import { BlockChartContext } from 'features/builders/shared/contexts/BlockChartContext'
import { BlockFlowContext } from 'features/builders/shared/contexts/BlockFlowContext'

import { ChartBuilder } from 'features/builders/chartBuilder/ChartBuilder'
import { FlowBuilder } from 'features/builders/flowBuilder/FlowBuilder'
import { DesignContainer } from './components/designContainer/DesignContainer'
import { DesignContent } from './components/designContent/DesignContent'
import { ActionBar } from './components/actionBar/ActionBar'
import { Toolbar } from './components/toolbar/Toolbar'
import { AddNewElement } from './components/addNewElement/AddNewElement'
import { BlockButtonModifier } from './components/blockButtonModifier/BlockButtonModifier'
import { BlockTextModifier } from './components/blockTextModifier/BlockTextModifier'
import Style from './DashboardBuilder.module.css'

export function DashboardBuilder() {
  const dashboardContent = useContext(DashboardContentContext)
  const { blockButtonId } = useContext(BlockButtonContext)
  const { blockTextId } = useContext(BlockTextContext)
  const { blockChartId } = useContext(BlockChartContext)
  const { blockFlowId } = useContext(BlockFlowContext)

  const breadcrumbsItems = [
    breadcrumbs.home,
    {
      key: 'dashboard-builder',
      url: paths.dashboardBuilder,
      name: 'Dashboard Builder',
    },
  ]

  if (blockChartId) {
    return <ChartBuilder />
  }

  // if (blockFlowId) {
  //   return <FlowBuilder />
  // }

  if (dashboardContent?.dashboardElements?.length === 0) {
    return (
      <div className={Style['builder-container']}>
        <Breadcrumbs items={breadcrumbsItems} />
        <ActionBar />
        <AddNewElement title />
        {blockButtonId && <BlockButtonModifier />}
        {blockTextId && <BlockTextModifier />}
      </div>
    )
  }

  return (
    <div className={Style['builder-container']}>
      <Breadcrumbs items={breadcrumbsItems} />
      <ActionBar />
      <DesignContainer>
        <DesignContent />
      </DesignContainer>
      <Toolbar />
      {blockButtonId && <BlockButtonModifier />}
      {blockTextId && <BlockTextModifier />}
    </div>
  )
}
