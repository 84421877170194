import React, { useState, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'

import { CustomTooltip, useElementDimensions } from 'components/lib'
import { TBlockchainOption } from 'types/blockchain'
import { TDapp } from 'types/dapp'

import { ElementsTooltipInfo } from 'features/gi/shared/components/overflowElements/ElementsTooltipInfo'
import { TOverflowElementsProps } from 'features/gi/dapp/shared/types/overflowElements'
import { handleImageSource } from 'features/gi/dapp/shared/utils/helpers'
import Style from './OverflowElements.module.css'

export const OverflowElements = ({
  elements,
  type,
  size,
  withFrame,
  id,
}: TOverflowElementsProps) => {
  const [elementsQuantityInRow, setElementsQuantityInRow] = useState<number>()
  const componentRef = useRef()
  const { width } = useElementDimensions(componentRef)
  useEffect(() => {
    const segment = size === 'm' ? 22 : 34
    const result = width / segment
    setElementsQuantityInRow(Math.floor(result))
  }, [width])

  const handleDisplayingElements = (
    elements: Array<TBlockchainOption | TDapp>
  ) => {
    if (elements?.length > elementsQuantityInRow && elementsQuantityInRow > 0) {
      const difference = elements?.length - elementsQuantityInRow
      return (
        <>
          {elements?.length > 0 &&
            elements.slice(0, elementsQuantityInRow - 1).map((element) => {
              return (
                <img
                  key={element.name}
                  src={handleImageSource(element, type)}
                  alt={`${type} ${element.name}`}
                  className={Style[`logo-size-${size}`]}
                />
              )
            })}
          <p className={Style['info-text']}>{`+${difference + 1}`}</p>
        </>
      )
    }

    return (
      <>
        {elements?.length > 0 &&
          elements.map((element) => {
            return (
              <img
                key={element?.name}
                src={handleImageSource(element, type)}
                alt={`${type} ${element?.name}`}
                className={Style[`logo-size-${size}`]}
              />
            )
          })}
      </>
    )
  }

  return (
    <div ref={componentRef} className={Style['content-container']}>
      {elements?.length > 0 ? (
        <div
          className={Style[`content-gap-${size}`]}
          data-tooltip-id={id}
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <ElementsTooltipInfo list={elements} type={type} size={size} />
          )}
          data-tooltip-place="bottom-start"
          data-tooltip-position-strategy="absolute"
          data-tooltip-offset={8}
        >
          {withFrame ? (
            <div className={Style['frame']}>
              {handleDisplayingElements(elements)}
            </div>
          ) : (
            <>{handleDisplayingElements(elements)}</>
          )}
          <CustomTooltip id={id} light />
        </div>
      ) : null}
    </div>
  )
}
