/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from 'react'

import { Form, Modal } from 'components/lib'

export function ForgotPassword() {
  return (
    <Modal title="Reset your password" redirect>
      <p className="mb16">
        Enter your email address and we&apos;ll send you instructions to reset
        your password.
      </p>

      <Form
        data={{
          email: {
            label: 'Email',
            type: 'email',
            required: true,
          },
        }}
        url="/api/auth/password/reset/request"
        method="POST"
        buttonText="Reset Password"
      />
    </Modal>
  )
}
