import React, { useContext, useEffect } from 'react'
import {
  Avatar,
  Icon,
  Button,
  IconButton,
  ViewContext,
  useMobile,
} from 'components/lib'
import { removeEmailFromDashboard } from 'utils/fetches/dashboard'
import { removeEmailFromProject } from 'utils/fetches/project'
import { UserSettingsItemContainer } from '../userSettingsItemContainer/userSettingsItemContainer'
import { UserContainer } from '../userContainer/userContainer'
import Style from './usersWithAccess.module.scss'
import { sortUsers } from 'utils/helpers'

export const UsersWithAccess = ({
  usersData,
  fetchUsers,
  contentId,
  contentType,
}) => {
  const [confirmStatus, setConfirmStatus] = React.useState({})
  const timerRef = React.useRef({})
  const context = useContext(ViewContext)
  const isMobile = useMobile()

  useEffect(() => {
    return () => {
      Object.keys(timerRef.current).forEach((email) => {
        clearTimeout(timerRef.current[email])
      })
    }
  }, [])

  const handleRemoveEmail = async (emailToRemove) => {
    const deleteEmailFunction =
      contentType === 'project'
        ? removeEmailFromProject
        : removeEmailFromDashboard

    try {
      const result = await deleteEmailFunction(contentId, emailToRemove)

      if (result) {
        fetchUsers(contentId, contentType)
      }
    } catch (error) {
      context.handleError(error)
    }
  }

  const handleConfirmToggle = (email) => {
    setConfirmStatus((prevState) => ({
      ...prevState,
      [email]: !prevState[email],
    }))

    if (!confirmStatus[email]) {
      timerRef.current[email] = setTimeout(() => {
        setConfirmStatus((prevState) => ({
          ...prevState,
          [email]: false,
        }))
      }, 5000)
    } else {
      clearTimeout(timerRef.current[email])
    }
  }

  const sortedUsers = sortUsers([...usersData])

  return (
    <ul className={sortedUsers.length > 4 && !isMobile ? Style.scrollable : ''}>
      {sortedUsers.map((user, index) => (
        <li key={index} className={Style['users-with-access-container']}>
          <UserContainer>
            {!isMobile && <Avatar user={user} />}
            <UserSettingsItemContainer>
              <h3 className={user.tag === 'waiting' ? '' : Style.userName}>
                {user.tag === 'waiting' ? (
                  <p style={{ color: 'grey' }}>(Pending)</p>
                ) : (
                  user.name || 'Undefined'
                )}
              </h3>
              <div className={Style.userEmailNoMargin}>
                <p>{user.email}</p>
              </div>
            </UserSettingsItemContainer>
          </UserContainer>
          {user.tag !== 'owner' ? (
            <div>
              {confirmStatus[user.email] ? (
                <Button
                  xsmall
                  color="outlineRed"
                  text="Confirm"
                  action={() => handleRemoveEmail(user.email)}
                />
              ) : (
                <IconButton
                  onClick={() => handleConfirmToggle(user.email)}
                  icon={
                    <Icon
                      name="exit"
                      height="1.6rem"
                      width="1.6rem"
                      color="danger500"
                    />
                  }
                />
              )}
            </div>
          ) : (
            <div className={Style.box}>Owner</div>
          )}
        </li>
      ))}
    </ul>
  )
}
