import axios from 'axios'
import settings from 'settings.json'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchResultData = async (
  id: string,
  metric: string,
  bodyRequest: unknown
) => {
  const response = await axios.post(
    `${serverURL}/api/dapp-analytics/data/${id}/${metric}`,
    bodyRequest
  )
  const data = response.data

  return data
}
