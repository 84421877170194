import React, { useEffect, useState } from 'react'

import { SingleValue, CompareSingleValue } from 'components/lib'
import informations from 'utils/informations.json'

import { COLLECTION_NAME } from 'features/gi/shared/utils/constans'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import cumulativeIcon from 'features/gi/shared/assets/icons/cumulative.svg'
import {
  DAILY_ACTIVE_USERS,
  TRANSACTIONS,
  TVL,
  SMART_CONTRACTS,
  FEES_PAID,
  MARKET_CAP,
} from 'features/gi/ecosystem/shared/utils/constans'
import Style from './singleValueGrid.module.scss'

export const SingleValueGrid = ({ data, loadingData }) => {
  const { selectedBlockchain, selectedBlockchainsToCompare } =
    useVerifiedFiltersParams()
  const [singleVaulesData, setSingleValuesData] = useState([
    { name: DAILY_ACTIVE_USERS, id: 'avg_dau' },
    { name: TRANSACTIONS, id: 'weekly_txns' },
    { name: SMART_CONTRACTS, id: 'cum_deployed_scs' },
    { name: TVL, prefix: '$', id: 'tvl' },
    { name: FEES_PAID, prefix: '$', id: 'weekly_fees' },
    { name: MARKET_CAP, prefix: '$', id: 'market_cap' },
  ])

  useEffect(() => {
    if (data) {
      const metricsArr = Object.keys(data.blockchain)
      metricsArr.forEach((item) => {
        setSingleValuesData((prevState) =>
          prevState.map((obj) => {
            if (obj.id === item) {
              if (data.compareWithBlockchainNames.length > 1) {
                return {
                  ...obj,
                  data: [
                    {
                      name: selectedBlockchain.blockchain.name,
                      logo: selectedBlockchain.blockchain.logo,
                      value: data.blockchain[item],
                    },
                    {
                      name: COLLECTION_NAME,
                      logo: cumulativeIcon,
                      value: data.cumulative[item],
                    },
                  ],
                }
              } else if (data.compareWithBlockchainNames.length === 0) {
                return {
                  ...obj,
                  data: data.blockchain[item],
                }
              } else if (data.compareWithBlockchainNames.length === 1) {
                return {
                  ...obj,
                  data: [
                    {
                      name: selectedBlockchain.blockchain.name,
                      logo: selectedBlockchain.blockchain.logo,
                      value: data.blockchain[item],
                    },
                    {
                      name: selectedBlockchainsToCompare[0].blockchain.name,
                      logo: selectedBlockchainsToCompare[0].blockchain.logo,
                      value: data.cumulative[item],
                    },
                  ],
                }
              }
            }
            return obj
          })
        )
      })
    }
  }, [data])

  return (
    <div className={Style.grid}>
      {singleVaulesData.map((item) => {
        if (
          data?.compareWithBlockchainNames.length > 0 &&
          selectedBlockchainsToCompare?.length > 0
        ) {
          return (
            <CompareSingleValue
              darkMode
              showTrend
              key={item.name}
              name={item.name}
              data={item.data}
              loading={loadingData}
              tooltipInfo={informations.ecosystem[item.name]}
            />
          )
        } else {
          return (
            <SingleValue
              showTrend
              darkMode
              key={item.name}
              title={item.name}
              prefix={item.prefix}
              data={item.data}
              loading={loadingData}
              tooltipInfo={informations.ecosystem[item.name]}
            />
          )
        }
      })}
    </div>
  )
}
