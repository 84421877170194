export const verifyDateRangeParam = (str) => {
  if (
    str === '3 months' ||
    str === '6 months' ||
    str === 'last year' ||
    str === 'all time'
  ) {
    return str
  } else {
    return '3 months'
  }
}

// make nicly numbers
export function tidyNumbers(numbers) {
  return Intl.NumberFormat('en', { notation: 'compact' }).format(numbers)
}
