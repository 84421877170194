import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { PieChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

import tokenguard from '../tokenguard'

import { useContainerDimensions } from 'hooks/useContainerDimensions'
import { palette } from 'utils/constans'
import { TTheme } from 'types/dashboard/theme'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  CanvasRenderer,
  PieChart,
  LegendComponent,
])

export const calcWidthOfPieChartLegend = () => {
  const widthOfSpaceWithoutMargins = 0.94
  const result = widthOfSpaceWithoutMargins
  const formattedResult = Math.round(result * 100).toString()

  return formattedResult
}

function convertToPieChartFormat(data: Array<any>) {
  return data.map((obj) => {
    const newObject = {}
    for (let key in obj) {
      if (key === 'dimension') {
        newObject['name'] = obj[key]
      } else {
        newObject['value'] = obj[key]
      }
    }

    return newObject
  })
}

type TCustomPieChart = {
  data: Array<any>
  height?: string
  locked?: boolean
  theme: TTheme
}

export const CustomPieChart = ({
  data,
  theme,
  locked,
  height,
}: TCustomPieChart) => {
  const [legendWidth, setLegendWidth] = useState<string>()
  const componentRef = useRef()
  const { width } = useContainerDimensions(componentRef)
  const pieChartFormatData = convertToPieChartFormat(data)
  const legendsData = pieChartFormatData.map((item: any) => item.name)

  useEffect(() => {
    setLegendWidth(calcWidthOfPieChartLegend())
  }, [width])

  // legend
  let selectorLabelColor = palette.gray700
  let itemLegendTextColor = palette.gray700

  let legendSelector = [
    {
      type: 'all',
      title: 'All',
    },
    {
      type: 'inverse',
      title: 'Inv',
    },
  ]
  let legendObj: any = {
    data: legendsData,
    top: 0,
    width: `${legendWidth}%`,
    left: '2%',
    type: 'scroll',
    orient: 'horizontal',
    icon: 'circle',
    pageIconColor: '#062434',
    textStyle: {
      overflow: 'breakAll',
      color: itemLegendTextColor,
    },
    itemGap: 16,
    itemWidth: 10,
    itemHeight: 10,
    selectorItemGap: 4,
    selectorLabel: {
      color: selectorLabelColor,
      width: 38,
      height: 23,
      padding: 0,
      fontSize: 12,
      verticalAlign: 'middle',
      align: 'center',
      backgroundColor: '#F4F4F4',
      borderColor: '#CBCBCB',
      borderWidth: 0.6,
      borderRadius: 4,
    },
    emphasis: {
      selectorLabel: {
        color: '#fff',
        backgroundColor: '#062434',
        borderColor: '#062434',
      },
    },
  }

  if (pieChartFormatData.length > 10) {
    legendObj.selector = legendSelector
    legendObj.right = '4%'
  } else {
    legendObj.selected = pieChartFormatData
    legendObj.selector = []
    legendObj.left = '2%'
  }

  if (theme) {
    legendObj.textStyle.color = theme.textColor
  }

  const style: CSSProperties = {
    height: height ? height : '500px',
    margin: 'auto',
  }

  if (locked) {
    style.pointerEvents = 'none'
  }

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: legendObj,
    grid: {
      top: 50,
      left: '2%',
      right: '4%',
      width: '94%',
      containLabel: true,
    },
    series: [
      {
        label: {
          show: false,
        },
        type: 'pie',
        radius: '50%',
        data: pieChartFormatData,
      },
    ],
  }

  return (
    <div
      ref={componentRef}
      style={{
        width: '100%',
        margin: 'auto',
      }}
    >
      <ReactEChartsCore
        echarts={echarts}
        option={option}
        notMerge={true}
        lazyUpdate={true}
        theme={tokenguard}
        style={style}
      />
    </div>
  )
}
