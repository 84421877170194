import React from 'react'
import Style from './gaugeLegend.module.scss'

export const GaugeLegend = () => {
  return (
    <div className={Style.container}>
      <div className={Style.legendOptionContainer}>
        <div className={`${Style.circle} ${Style.low}`}></div>
        <span>0-20</span>
      </div>
      <div className={Style.legendOptionContainer}>
        <div className={`${Style.circle} ${Style.lowMid}`}></div>
        <span>20-40</span>
      </div>
      <div className={Style.legendOptionContainer}>
        <div className={`${Style.circle} ${Style.mid}`}></div>
        <span>40-60</span>
      </div>
      <div className={Style.legendOptionContainer}>
        <div className={`${Style.circle} ${Style.midHigh}`}></div>
        <span>60-80</span>
      </div>
      <div className={Style.legendOptionContainer}>
        <div className={`${Style.circle} ${Style.high}`}></div>
        <span>80-100</span>
      </div>
    </div>
  )
}
