/***
 *
 *   useVerifiedFiltersParam hook
 *
 **********/

import _React, { useState, useEffect, useContext } from 'react'
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getValidationErrorMessage } from 'utils/helpers'
import { logger } from 'utils/logger'
import { ViewContext } from 'components/lib'
import { useBlockchains } from 'hooks/useBlockchains'
import { TSingleBlockchain } from 'types/blockchain'
import { DappsBasicSchema, TBasicDapp } from 'types/dapp'
import { fetchDapps } from 'utils/fetches/dapp'

export const useEcosystemUserInsightsFilters = (
  selectedBlockchain: TSingleBlockchain
) => {
  const location = useLocation()
  const [searchParams, _setSearchParams] = useSearchParams()
  const [verifiedBlockchainSegment, setVerifiedBlockchainSegment] = useState()
  const [verifiedOtherDappsUsedParam, setVerifiedOtherDappsUsedParam] =
    useState()
  const [dappsOptions, setDappsOptions] = useState()
  const [isDappsOptionsLoading, setIsDappsOptionsLoading] = useState(false)
  const [verifiedParamsStr, setVerifiedParamsStr] = useState('')
  const [blockchainsOptions, setBlockchainsOptions] = useState()
  const [selectedOtherDappsUsed, setSelectedOtherDappsUsed] = useState()
  const pageParam = searchParams.get('page')
  const sortByParam = searchParams.get('sort-by')
  const sortTypeParam = searchParams.get('sort-type')
  const searchAddressParam = searchParams.get('search-address')
  const pageSizeParam = searchParams.get('page-size')
  const minDepositParam = searchParams.get('min-deposit')
  const maxDepositParam = searchParams.get('max-deposit')
  const minInteractionsParam = searchParams.get('min-interactions')
  const maxInteractionsParam = searchParams.get('max-interactions')
  const lastInteractionStartDateParam = searchParams.get(
    'last-interaction-start-date'
  )
  const lastInteractionEndDateParam = searchParams.get(
    'last-interaction-end-date'
  )
  const joinedStartDate = searchParams.get('joined-start-date')
  const joinedEndDate = searchParams.get('joined-end-date')
  const otherDappsUsedParam = searchParams.get('other-dapps-used')
  const [, , blockchainSegment, , addressSegment] = location.pathname.split('/')
  const [usedDappsOptions, setUsedDappsOptions] = useState()
  const [_isUsedDappsOptionsLoading, setIsUsedDappsOptionsLoading] =
    useState(false)
  const viewContext = useContext(ViewContext)
  const { blockchains } = useBlockchains('?dappgrowth=true')
  const navigate = useNavigate()

  useEffect(() => {
    if (blockchains?.length > 0) {
      const onlyMainnetBlockchains = blockchains.filter(
        (item) => item.network === 'Mainnet'
      )
      if (!blockchainSegment) {
        navigate(`/crypto/${onlyMainnetBlockchains[0].slug}/web3-crm`)
      }
      setBlockchainsOptions(
        onlyMainnetBlockchains
          .filter(
            (item: TSingleBlockchain) => item.name !== selectedBlockchain?.name
          )
          .map((item: TSingleBlockchain) => {
            return {
              label: item.name,
              value: item.slug,
              icon: item.logo,
              network: item?.network || 'Mainnet',
              name: item.name,
              id: item.id,
            }
          })
      )
    }

    if (selectedBlockchain) {
      setVerifiedBlockchainSegment(selectedBlockchain.slug)

      const getUsedDappsData = async () => {
        try {
          setIsUsedDappsOptionsLoading(true)
          const response = await fetchDapps(
            `blockchains=${selectedBlockchain.slug}&active=true`
          )
          const fetchedDapps = response.data

          // const validatedOtherDappstData = DappsBasicSchema.safeParse(fetchedDapps)
          // if (!validatedOtherDappstData.success) {
          //   throw new Error(validatedOtherDappstData.error)
          // }

          const fetchedDappsOptions = fetchedDapps.map((item: TBasicDapp) => {
            return {
              label: item.name,
              value: item.slug,
              icon: item.icon,
              slug: item.slug,
            }
          })
          setUsedDappsOptions(fetchedDappsOptions)
          setIsUsedDappsOptionsLoading(false)
        } catch (err) {
          setIsUsedDappsOptionsLoading(false)
          setUsedDappsOptions([])
          logger.error(err.message)
          toast.error(getValidationErrorMessage('other dapps used'))
        }
      }

      getUsedDappsData()
    }
  }, [blockchains, blockchainSegment, selectedBlockchain])

  useEffect(() => {
    if (otherDappsUsedParam && usedDappsOptions?.length > 0) {
      const otherDappsUsedParamArr = otherDappsUsedParam.split(',')
      let verifiedOtherDappsUsedParamArr = []
      let filteredOtherDappsUsedArr = []
      otherDappsUsedParamArr.forEach((param) => {
        const matchingObject = usedDappsOptions.find(
          (obj) =>
            obj.slug.toLowerCase() === param.toLowerCase() &&
            obj.slug !== filteredOtherDappsUsedArr.slug
        )
        if (matchingObject) {
          verifiedOtherDappsUsedParamArr.push(param)
          filteredOtherDappsUsedArr.push(matchingObject)
        }
      })
      if (verifiedOtherDappsUsedParamArr.length > 0) {
        const verifiedCompareWithParamStr = verifiedOtherDappsUsedParamArr
          .join(',')
          .toLowerCase()
        setVerifiedOtherDappsUsedParam(verifiedCompareWithParamStr)
        setSelectedOtherDappsUsed(filteredOtherDappsUsedArr)
      } else {
        setVerifiedOtherDappsUsedParam(undefined)
        setSelectedOtherDappsUsed([])
      }
    } else {
      setVerifiedOtherDappsUsedParam(undefined)
      setSelectedOtherDappsUsed([])
    }
  }, [JSON.stringify(usedDappsOptions), otherDappsUsedParam])

  return {
    verifiedAddressSegment: addressSegment,
    verifiedPage: pageParam ? Number(pageParam) : 1,
    verifiedPageSize: pageSizeParam || 10,
    verifiedBlockchainSegment: verifiedBlockchainSegment,
    searchAddressParam: searchAddressParam,
    sortTypeParam: sortTypeParam,
    sortByParam: sortByParam,
    usedDappsOptions: usedDappsOptions,
    blockchainsOptions: blockchainsOptions,
    verifiedOtherDappsUsedParam: verifiedOtherDappsUsedParam,
    selectedOtherDappsUsed: selectedOtherDappsUsed,
    minDepositParam: minDepositParam,
    maxDepositParam: maxDepositParam,
    minInteractionsParam: minInteractionsParam,
    maxInteractionsParam: maxInteractionsParam,
    lastInteractionStartDateParam: lastInteractionStartDateParam,
    lastInteractionEndDateParam: lastInteractionEndDateParam,
    joinedEndDate: joinedEndDate,
    joinedStartDate: joinedStartDate,
  }
}
