/***
 *
 *   GI ECOSYSTEM BASIC DASHBOARD COMPONENT
 *
 **********/

import React, { useContext } from 'react'

import {
  AuthContext,
  Typography,
  Loader,
  Breadcrumbs,
  useLocation,
} from 'components/lib'
import { breadcrumbs, plan } from 'utils/constans'
import { useSeoPage } from 'hooks/useSeoPage'

import { FiltersSection } from 'features/gi/ecosystem/shared/components/filtersSection/filtersSection'
import { BasicValuesSection } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/basicValuesSection/BasicValuesSection'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import { ComparisionSection } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/comparisionSection/ComparisionSection'
import { TgGrowthIndexTimeLineSection } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/tgGrowthIndexTimelineSection/tgGrowthIndexTimelineSection'
import { DashboardsSection } from 'features/gi/shared/components/dashboardsSection/DashboardsSection'

export function GiEcosystemBasicDashboard() {
  const authContext = useContext(AuthContext)
  const { verifiedBlockchainSegment, verifiedParamsStr, selectedBlockchain } =
    useVerifiedFiltersParams()
  const location = useLocation()
  useSeoPage({
    title: `${selectedBlockchain?.blockchain?.name || ' '} - Crypto`,
    description: `View ${
      selectedBlockchain?.blockchain?.name || ' '
    } activity, explore detailed chain analysis metrics & track developer activity`,
    keywords: ['crypto activity', 'developer activity', 'chain analysis'],
    ogTitle: `${selectedBlockchain?.blockchain?.name || ' '} - Crypto`,
    ogDescription: `View ${
      selectedBlockchain?.blockchain?.name || ' '
    } activity, explore detailed chain analysis metrics & track developer activity`,
    ogImage: selectedBlockchain?.blockchain?.logo,
    // ogUrl: to set
  })
  const [, , secondSegment] = location.pathname.split('/')
  const breadcrumbsItems = [
    breadcrumbs.home,
    breadcrumbs.ecosystemOverview,
    {
      key: 'basic',
      url: `/crypto/${secondSegment}${location.search}`,
      name: `${selectedBlockchain?.blockchain?.name || ''}`,
    },
  ]

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems} />
      </div>
      {verifiedBlockchainSegment && verifiedParamsStr ? (
        <>
          <BasicValuesSection />
          <Typography
            tag="h2"
            text="Historical Values"
            weight="semi-bold"
            size="m"
            color="primary500"
            className="mt24 mb16"
          />
          <FiltersSection />
          <TgGrowthIndexTimeLineSection />
          {authContext?.user?.plan === plan.alephZero && <ComparisionSection />}
          {selectedBlockchain?.blockchain?.dappanalytics && (
            <DashboardsSection
              slug={selectedBlockchain.blockchain.slug}
              type="blockchain"
            />
          )}
        </>
      ) : (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </>
  )
}
