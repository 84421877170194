import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'

import { Typography, Line } from 'components/lib'
import { Icon } from 'components/icon/Icon'

import Style from './EventCallDropdown.module.css'

export const EventCallDropdown = ({
  id,
  children,
  position,
  eventsOptions,
  callsOptions,
}) => {
  const [_clickedOutside, setClickedOutside] = useState(false)
  const [isOpenDropDown, setOpenDropDown] = useState(false)
  const [isEventsVisible, setIsEventsVisible] = useState(false)
  const [isCallsVisible, setIsCallsVisible] = useState(false)
  const myRef = useRef(null)

  const dropDownStyle = clsx(Style['list'], position && Style[position])

  const handleOptionClick = (action) => {
    setOpenDropDown(false)
    action(id)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (
      e.target instanceof HTMLElement &&
      !myRef?.current?.contains(e.target)
    ) {
      setClickedOutside(true)
      setOpenDropDown(false)
    }
  }

  const handleClickInside = () => setClickedOutside(false)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  return (
    <div ref={myRef} onClick={handleClickInside}>
      <div
        className={Style['wrapper']}
        onClick={() => setOpenDropDown(!isOpenDropDown)}
      >
        {children}
      </div>

      {isOpenDropDown && (
        <div className={dropDownStyle}>
          <Typography size="m" tag="p" text="Add Event / Call" />
          <Line className="mt8 mb8" />
          <div
            className={Style['eventcall-option']}
            onClick={() => setIsEventsVisible(!isEventsVisible)}
          >
            <span className={Style['secondary-dot']}></span>
            <Typography tag="p" text="Events" size="m" weight="medium" />
            <Icon
              name={isEventsVisible ? 'chevronUp' : 'chevronDown'}
              width="16"
              height="16"
            />
          </div>
          <ul>
            {isEventsVisible && eventsOptions && eventsOptions.length > 0
              ? eventsOptions.map(({ label, action, type }) => {
                  return (
                    <li
                      onClick={() => handleOptionClick(action)}
                      key={label}
                      className={
                        type ? `${Style[type]} ${Style.option}` : Style.option
                      }
                    >
                      <p>{label}</p>
                    </li>
                  )
                })
              : null}
          </ul>
          <div
            onClick={() => setIsCallsVisible(!isCallsVisible)}
            className={Style['eventcall-option']}
          >
            <span className={Style['primary-dot']}></span>
            <Typography tag="p" text="Calls" size="m" weight="medium" />
            <Icon
              name={isCallsVisible ? 'chevronUp' : 'chevronDown'}
              width="16"
              height="16"
            />
          </div>
          <ul>
            {isCallsVisible &&
              callsOptions.map(({ label, action, type }) => {
                return (
                  <li
                    onClick={() => handleOptionClick(action)}
                    key={label}
                    className={
                      type ? `${Style[type]} ${Style.option}` : Style.option
                    }
                  >
                    <p>{label}</p>
                  </li>
                )
              })}
          </ul>
        </div>
      )}
    </div>
  )
}
