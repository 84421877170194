/***
 *
 *   HOMEPAGE
 *
 **********/

import React from 'react'
import { Helmet } from 'react-helmet'

import { Breadcrumbs, MainContentContainer } from 'components/lib'
import { useSeoPage } from 'hooks/useSeoPage'
import ogImage from 'assets/images/og/dapps-ranking.png'

import { GiDappOverview } from 'features/gi/dapp/giDappOverview/giDappOverview'
import { breadcrumbs } from 'utils/constans'

export function HomePage() {
  useSeoPage({
    title: 'Patterns - User Activity of Top DeFi & Crypto Apps',
    description:
      'Explore activity of top DeFi protocols, crypto apps and their users. Analyze top wallet behaviour and improve DeFi & crypto app revenue.',
    keywords: ['defi', 'crypto apps', 'users'],
    ogTitle: 'Patterns - User Activity of Top DeFi & Crypto Apps',
    ogDescription:
      'Explore activity of top DeFi protocols, crypto apps and their users. Analyze top wallet behaviour and improve DeFi & crypto app revenue.',
    ogImage: ogImage,
    // ogUrl: to set
  })
  const breadcrumbsItems = [breadcrumbs.home]

  return (
    <>
      <Helmet>
        <link rel="canonical" href="http://patterns.build" />
      </Helmet>
      <MainContentContainer>
        <div className="mb24">
          <Breadcrumbs items={breadcrumbsItems} />
        </div>
        <GiDappOverview />
      </MainContentContainer>
    </>
  )
}
