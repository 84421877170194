/***
 *
 *   useGiBasicDappsData hook
 *   for getting growth index dapps data and set in to context
 *
 **********/

import { useState, useEffect, useContext } from 'react'

import { ViewContext } from 'components/lib'
import { fetchDappBySlug } from 'utils/fetches/dapp'

export const useDapp = (slug: string) => {
  const [dapp, setDapp] = useState()
  const [isDappLoading, setIsDappLoading] = useState(false)
  const viewContext = useContext(ViewContext)

  useEffect(() => {
    if (slug) {
      const fetchData = async () => {
        try {
          setIsDappLoading(true)
          const fetchedDappsBasicInfo = await fetchDappBySlug(slug)
          setDapp(fetchedDappsBasicInfo.data)
          setIsDappLoading(false)
        } catch (err) {
          setIsDappLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
      fetchData()
    }
  }, [slug])

  return {
    selectedDapp: dapp,
    dapp,
    isDappLoading,
  }
}
