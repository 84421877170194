/***
 *
 *   BASIC VALUES SECTION
 *
 **********/

import React from 'react'

import {
  Icon,
  Card,
  useNavigate,
  useLocation,
  ButtonNew,
  Typography,
} from 'components/lib'
import { INDICATOR_NAME } from 'utils/constans'

import { SingleValueGrid } from 'features/gi/dapp/giDappBasicDashboard/components/singleValueGrid/singleValueGrid'
import { BasicValuesContainer } from 'features/gi/dapp/giDappBasicDashboard/components/basicValuesContainer/basicValuesContainer'
import { useGiDappBasicValuesData } from 'features/gi/dapp/giDappBasicDashboard/hooks/useGiDappBasicValuesData'
import { GaugeSection } from 'features/gi/dapp/shared/components/gaugeSection/gaugeSection'
import Style from './BasicValuesSection.module.css'

export const BasicValuesSection = ({
  verifiedDappSegment,
  selectedDapp,
  selectedDappsToCompare,
  verifiedParamsStr,
  verifiedBlockchainsParam,
  selectedBlockchains,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { giDappBasicValuesData, isGiDappBasicValuesDataLoading } =
    useGiDappBasicValuesData(
      verifiedDappSegment,
      selectedDapp,
      selectedDappsToCompare,
      verifiedParamsStr,
      verifiedBlockchainsParam,
      selectedBlockchains
    )

  return (
    <Card className="mt24">
      <div className={Style['title-container']}>
        <Typography
          text={`${selectedDapp?.name} Analytics Last Week`}
          tag="h1"
          size="m"
          weight="semi-bold"
          color="primary500"
        />
        <ButtonNew
          size="medium"
          color="primary"
          moveTo={`/app/${verifiedDappSegment}/charts${location.search}`}
          endIcon={
            <Icon name="rightChevron" color="white" width="12" height="16" />
          }
        >
          Explore {selectedDapp?.name} {INDICATOR_NAME}
        </ButtonNew>
      </div>
      <BasicValuesContainer>
        <GaugeSection
          title={`dApp ${INDICATOR_NAME}`}
          data={giDappBasicValuesData}
          loadingData={isGiDappBasicValuesDataLoading}
        />
        <SingleValueGrid
          data={giDappBasicValuesData}
          loadingData={isGiDappBasicValuesDataLoading}
        />
      </BasicValuesContainer>
    </Card>
  )
}
