import { DappUsersPage } from 'views/gi/crm/dappUsersPage'
import { EcosystemUsersPage } from 'views/gi/crm/EcosystemUsersPage'

import { paths } from 'utils/paths'

const Routes = [
  {
    path: paths.crm,
    view: DappUsersPage,
    layout: 'redesignApp',
  },
  {
    path: `/crypto/:ecosystemName${paths.crm}`,
    view: EcosystemUsersPage,
    layout: 'redesignApp',
  },
  {
    path: `/crypto/:dappName${paths.crm}/:address`,
    view: EcosystemUsersPage,
    layout: 'redesignApp',
  },
  {
    path: `/app/:dAppName${paths.crm}`,
    view: DappUsersPage,
    layout: 'redesignApp',
  },
  {
    path: `/app/:dappName${paths.crm}/:address`,
    view: DappUsersPage,
    layout: 'redesignApp',
  },
]

export default Routes
