import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { ResultSchema } from 'types/result'
import { fetchResultData } from 'utils/fetches/resultData'
// import { Loader } from '@/components/loader/Loader'
// import { SingleValue } from '@/components/charts/singleValue/SingleValue'
// import { AreaChart } from '@/components/charts/areaChart/AreaChart'
// import { CustomPieChart } from '@/components/charts/pieChart/PieChart'
// import { CustomBarChart } from '@/components/charts/barChart/BarChart'
import {
  CustomBarChart,
  CustomPieChart,
  CustomAreaChart,
  SingleValue,
  Loader,
} from 'components/lib'
import { logger } from 'utils/logger'
import { adjustForSingleValue, removeUselessProperties } from 'utils/helpers'

import { prepareFiltersBodyRequestFormat } from 'features/dashboard/utils/helpers'

import Style from './Visualization.module.css'

function calcHeight(height: number) {
  const fullHeightOfCard = height * 2 * 10
  const result = fullHeightOfCard - 48 - 18 - 24

  return `${result}px`
}

export const Visualization = ({
  element,
  dashboardId,
  filters,
  dashboardTheme,
  elementHeight,
  dappId,
}) => {
  const [data, setData] = useState()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const filtersBodyRequest = filters
    ? prepareFiltersBodyRequestFormat(filters)
    : []

  useEffect(() => {
    if (!element.data) {
      const fetchData = async () => {
        try {
          const elFiltersForRequest = removeUselessProperties(element.filters)
          setIsDataLoading(true)
          const bodyRequest = {
            breakdown: element.breakdown,
            filters: elFiltersForRequest,
          }
          const fetchedResultData = await fetchResultData(
            dappId,
            element.metric,
            bodyRequest
          )
          const validatedResultData = ResultSchema.safeParse(
            fetchedResultData.output
          )
          if (!validatedResultData.success) {
            logger.error(validatedResultData.error)
            throw Error('Unable to get data - incorrect response format.')
          }
          setData(validatedResultData.data)
          setIsDataLoading(false)
        } catch (err) {
          setIsDataLoading(false)
          toast.error('Upss.. There was a problem to load data')
        }
      }

      fetchData()
    } else {
      setData(element.data)
    }
  }, [element?.type, JSON.stringify(filtersBodyRequest), dashboardId])

  return (
    <>
      {element?.visType === 'singleValue' ? (
        <SingleValue
          data={adjustForSingleValue(data, 'N/A', element.metric)}
          title={element?.title}
          loading={isDataLoading}
          theme={dashboardTheme}
        />
      ) : (
        <div
          className={Style['grid-item']}
          style={{
            borderRadius: dashboardTheme.itemGridRadius,
            backgroundColor: dashboardTheme.itemGridBgColor,
            color: dashboardTheme.textColor,
            borderColor: dashboardTheme.strokeColor,
          }}
        >
          <h3
            className={Style['item-title']}
            style={{
              color: dashboardTheme.textColor,
              fontFamily: dashboardTheme.font,
            }}
          >
            {element?.title}
          </h3>
          {isDataLoading && <Loader />}
          {!isDataLoading &&
            data &&
            {
              areaChart: (
                <CustomAreaChart
                  data={data}
                  height={calcHeight(elementHeight)}
                  theme={dashboardTheme}
                />
              ),
              lineChart: (
                <CustomAreaChart
                  data={data}
                  height={calcHeight(elementHeight)}
                  theme={dashboardTheme}
                />
              ),
              barChart: (
                <CustomBarChart
                  data={data}
                  height={calcHeight(elementHeight)}
                  theme={dashboardTheme}
                />
              ),
              pieChart: (
                <CustomPieChart
                  data={data}
                  height={calcHeight(elementHeight)}
                  theme={dashboardTheme}
                />
              ),
            }[element?.visType]}
        </div>
      )}
    </>
  )
}
