import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Responsive, WidthProvider } from 'react-grid-layout'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
const ResponsiveGridLayout = WidthProvider(Responsive)

import { ButtonNew, Typography } from 'components/lib'

import { Visualization } from './components/Visualization'
import { prepareElementsFormat, prepareLayoutFormat } from './utils/helpers'
import { useVerifiedDashboardFilters } from './hooks/useVerifiedDashboardFilters'

import Style from './Dashboard.module.css'

const initialTheme = {
  name: 'Tokenguard',
  primaryColor: '#48BD98',
  secondaryColor: '#0A425E',
  additionalColor: '#E6A627',
  bgColor: '#E2D5D5',
  itemGridRadius: '6px',
  itemGridBgColor: '#FFFFFF',
  font: 'Roboto',
  textColor: '#656565',
  itemGridStroke: '#ECECEC',
  chartGradient: true,
  bottomTimeline: true,
}

const dashboardTheme = {
  ...initialTheme,
}

export const Dashboard = ({ dashboardData }) => {
  const [searchParams, _setSearchParams] = useSearchParams()
  const [_dashboardLayout, setDashboardLayout] = useState()
  const { dashboard, dashboardCreator } = dashboardData
  const layout = prepareLayoutFormat(dashboardCreator.layout, true)
  const elements = prepareElementsFormat(dashboardCreator.elements)
  const { verifiedFilters } = useVerifiedDashboardFilters(
    dashboardCreator.filters,
    searchParams
  )
  const onLayoutChange = (layout: any, layouts: any) => {
    setDashboardLayout(layout)
  }

  return (
    <section
      className={Style['dashboard']}
      style={{ backgroundColor: dashboardCreator.theme.bgColor }}
    >
      <div className={Style['layout']}>
        <Typography
          tag="h1"
          text={dashboardCreator.title}
          color="secondary800"
          size="xl"
          weight="semi-bold"
        />
      </div>
      {layout?.length > 0 && elements?.length > 0 ? (
        <ResponsiveGridLayout
          layout={_dashboardLayout}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 2, xs: 2, xxs: 1 }}
          onLayoutChange={onLayoutChange}
          rowHeight={10}
          allowOverlap={false}
          preventCollision={true}
          className={Style['layout']}
          compactType="horizontal"
        >
          {layout.map((item) => {
            const element = elements.find((element) => element.i === item.i)
            if (element?.type !== 'text' && element?.type !== 'button') {
              return (
                <div key={item.i} data-grid={item}>
                  <Visualization
                    dashboardId={dashboard?.id || dashboard._id}
                    element={element}
                    elementHeight={item.h}
                    filters={verifiedFilters}
                    dashboardTheme={dashboardCreator?.theme || dashboardTheme}
                    dappId={dashboardCreator.dappId}
                  />
                </div>
              )
            } else if (element.type === 'text') {
              return (
                <div key={item.i} data-grid={item}>
                  <div
                    dangerouslySetInnerHTML={{ __html: element.text }}
                    style={{
                      color: dashboardCreator.theme.textColor,
                      fontFamily: dashboardCreator?.theme?.font,
                    }}
                  />
                </div>
              )
            } else if (element.type === 'button') {
              return (
                <div key={item.i} data-grid={item}>
                  <a href={element.link}>
                    <ButtonNew>{element.text}</ButtonNew>
                  </a>
                </div>
              )
            }
          })}
        </ResponsiveGridLayout>
      ) : null}
    </section>
  )
}
