export function filterRecordsLast30Days(records) {
  const today = new Date()
  const thirtyDaysAgo = new Date(today)
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
  const uniqueDappNamesMap = new Map()
  const filteredRecords = records.filter((record) => {
    const recordDate = new Date(record.week_of_record)

    if (recordDate >= thirtyDaysAgo && recordDate <= today) {
      if (!uniqueDappNamesMap.has(record.dapp_name)) {
        uniqueDappNamesMap.set(record.dapp_name, true)

        return true
      }
    }

    return false
  })

  return filteredRecords
}

export function groupDataByDate(input) {
  const result = {}

  input.forEach((item) => {
    const { week_of_record, caller, dapp_name, dapp } = item
    if (!result[week_of_record]) {
      result[week_of_record] = {
        week_of_record: week_of_record,
        data: [],
      }
    }

    result[week_of_record].data.push({
      caller,
      dapp_name,
      dapp,
    })
  })

  return Object.values(result)
}
