import React from 'react'
import { Link } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'

import {
  Icon,
  CustomTooltip,
  CustomSimpleAreaChart,
  useMobile,
  Typography,
} from 'components/lib'
import { handleGrowthIndexLevel, capitalizeWords } from 'utils/helpers'
import { INDICATOR_NAME } from 'utils/constans'
import informations from 'utils/informations.json'

import { GiTooltipInformation } from 'features/gi/shared/components/giTooltipInformation/giTooltipInformation'
import { OverflowElements } from 'features/gi/shared/components/overflowElements/OverflowElements'
import { determineTrend } from 'features/gi/shared/utils/helpers'
import Style from './dappTable.module.scss'

export const DappTable = ({ data }) => {
  const isMobile = useMobile()

  const determineRankChange = (change) => {
    if (change === 0) {
      return ''
    } else if (change > 0) {
      return (
        <div className={Style.changeRankContainer}>
          <Icon height="10" width="7" name="trendUp2" />
          <p className={Style.progressRank}>{change}</p>
        </div>
      )
    } else {
      return (
        <div className={Style.changeRankContainer}>
          <Icon height="10" width="7" name="trendDown2" />
          <p className={Style.regressRank}>{Math.abs(change)}</p>
        </div>
      )
    }
  }

  return (
    <>
      {data && (
        <div className={Style.tableWrapper}>
          <table className={Style.dappTable} data-testid="dapp-table">
            <thead>
              <tr className={Style.head}>
                <th>No</th>
                <th>dApp</th>
                <th>Blockchain</th>
                <th>
                  <div className={Style.growthIndexCell}>
                    {!isMobile && (
                      <Icon
                        name="growthIndex"
                        color="gray500"
                        width="16"
                        height="16"
                      />
                    )}
                    {INDICATOR_NAME}
                    <div
                      className="relative"
                      data-tooltip-id="dapp-info"
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <GiTooltipInformation
                          title={`dApp ${INDICATOR_NAME}`}
                          information={informations.dapp.gi}
                          metrics={informations.dapp.giListOfMetrics}
                        />
                      )}
                      data-tooltip-place="bottom-start"
                      data-tooltip-position-strategy="fixed"
                      data-tooltip-offset={8}
                    >
                      <Icon name="information" width="16" height="16" />
                      <CustomTooltip id="dapp-info" light />
                    </div>
                  </div>
                </th>
                <th>
                  <div className={Style.growthIndexTimelineColumnContainer}>
                    {!isMobile && (
                      <Icon
                        name="growthIndex"
                        color="gray500"
                        width="16"
                        height="16"
                      />
                    )}
                    {INDICATOR_NAME} Timeline (90d)
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((row, index) => (
                  <tr className={Style.row} key={row.dApp.id}>
                    <DappColumn dappSlug={row.dApp.slug}>
                      <div className={Style.numberCell}>
                        <Typography
                          tag="span"
                          text={row.rank}
                          color="primary500"
                          size="m"
                        />
                        {determineRankChange(row.rankChange)}
                      </div>
                    </DappColumn>
                    <DappColumn dappSlug={row.dApp.slug}>
                      <div className={Style.dAppCell}>
                        <img className={Style.dApplogo} src={row?.dApp?.icon} />
                        <Typography
                          tag="span"
                          text={row.dApp.name}
                          color="primary500"
                          size="m"
                        />
                      </div>
                    </DappColumn>
                    <DappColumn dappSlug={row.dApp.slug}>
                      <OverflowElements
                        elements={row.dApp.blockchains}
                        type="blockchain"
                        size="m"
                        id={row.dApp.id}
                        withFrame
                      />
                    </DappColumn>
                    <DappColumn dappSlug={row.dApp.slug}>
                      <div className={Style.growthIndexCell}>
                        <Icon
                          name={`growthIndex${capitalizeWords(
                            handleGrowthIndexLevel(row.growthIndex)
                          )}`}
                          width="16"
                          height="16"
                        />
                        <Typography
                          tag="span"
                          text={row.growthIndex?.toFixed(0) || 0}
                          color="primary500"
                          size="m"
                        />
                      </div>
                    </DappColumn>
                    <DappColumn dappSlug={row.dApp.slug}>
                      <CustomSimpleAreaChart
                        data={row.data || []}
                        style={{
                          height: '7.2rem',
                          width: !isMobile ? '100%' : 'auto',
                          margin: '0.4rem 0 0.2rem 0',
                        }}
                        trend={determineTrend(row.data || [])}
                      />
                    </DappColumn>
                  </tr>
                ))}
            </tbody>
          </table>
          {data.length === 0 && (
            <div className={Style.announcementContainer}>
              <p className={Style.announcement}>No data</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

const DappColumn = ({ children, dappSlug }) => {
  const generateDappUrl = (dappSlug) => `/app/${dappSlug}/analytics`

  return (
    <td>
      <Link to={generateDappUrl(dappSlug)}>
        <div className={Style['dapp-column-container']}>{children}</div>
      </Link>
    </td>
  )
}
