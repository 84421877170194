/***
 *
 *   LOGO
 *   logo brand with link to homepage
 *
 **********/

import React from 'react'

import { Link } from 'components/lib'
import brandLogo from 'assets/images/logo-patterns.svg'

import Style from './logo.module.scss'

export const Logo = () => (
  <Link url="/" className={Style.logo}>
    <img src={brandLogo} alt="Logo Patterns" />
  </Link>
)
