/***
 *
 *   VALUE CHART LABEL
 *   small label for displaying final result of chart metric
 *
 **********/

import React from 'react'

import Style from './valueChartLabel.module.scss'
import { tidyNumbers } from 'features/gi/ecosystem/shared/utils/helpers'

export const ValueChartLabel = ({ singleValueData, dapp, comparison }) => {
  if (Array.isArray(singleValueData)) {
    if (comparison && dapp) {
      return (
        <div className={Style.labelsContainer}>
          {singleValueData.map((item) => {
            if (item.name === dapp.name) {
              return (
                <div
                  key={item.name}
                  className={`${Style.smallLabel} ${Style.primary}`}
                >
                  <img src={dapp.logo} alt="" />
                  <p>
                    {typeof item.currentValue === 'number'
                      ? tidyNumbers(item.currentValue)
                      : 'N/A'}
                  </p>
                </div>
              )
            }

            if (item.name === comparison.name) {
              return (
                <div className={`${Style.smallLabel} ${Style.dark}`}>
                  <img src={comparison.logo} alt="" />
                  <p>
                    {typeof item.currentValue === 'number'
                      ? tidyNumbers(item.currentValue)
                      : 'N/A'}
                  </p>
                </div>
              )
            }
          })}
        </div>
      )
    }
  } else {
    return (
      <div className={Style.bigLabel}>
        {tidyNumbers(singleValueData.currentValue)}
      </div>
    )
  }
}
