import React from 'react'
import {
  Image,
  Button,
  useNavigate,
  Title,
  Card,
  Icon,
  ErrorContainer,
  ButtonNew,
  Typography,
} from 'components/lib'
import sadFace from 'assets/images/sad-face.png'

export function NotFound() {
  const navigate = useNavigate()

  return (
    <Card>
      <ErrorContainer>
        <div className="align-xy align-center">
          <Image source={sadFace} alt="not found page, sad face" marginAuto />
          <Title big dark>
            Error: <span>404</span>
          </Title>
          <Typography
            tag="p"
            text="Oops! Looks like the page your looking for doesn’t exist"
            size="s"
            weight="regular"
          />
          <ButtonNew
            onClick={() => navigate('/')}
            size="xlarge"
            color="secondary"
            startIcon={
              <Icon name="leftChevron" width="16" height="16" color="white" />
            }
          >
            Go Back Home
          </ButtonNew>
        </div>
      </ErrorContainer>
    </Card>
  )
}
