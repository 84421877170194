import React from 'react'

import { IconLib, Typography } from 'components/lib'

import Style from './TrendLabel.module.css'

type TTrendLabelProps = {
  trend: number
  variant: 'comparison' | undefined
}

export const TrendLabel = ({ trend, variant }: TTrendLabelProps) => {
  const formattedTrendValue = trend.toFixed(0)

  if (Math.abs(Number(formattedTrendValue)) === 0) {
    return (
      <div className={Style['trend-label']}>
        <div className={Style.trendLabelValue}>
          <p>{Math.abs(Number(formattedTrendValue))}%</p>
        </div>
      </div>
    )
  }

  if (Number(formattedTrendValue) > 0) {
    return (
      <div className={`${Style['trend-label']} ${Style['trend-growth']}`}>
        <div className={Style.trendLabelValue}>
          <Typography
            tag="p"
            text={`+${formattedTrendValue}%`}
            weight="semi-bold"
            size="xs"
            color="green500"
          />
          {variant === 'comparison' ? (
            <IconLib
              pack="own"
              iconHeight="11"
              iconWidth="7"
              image="trendUp2"
            />
          ) : (
            <IconLib
              pack="own"
              iconHeight="14"
              iconWidth="14"
              image="trendUp"
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={`${Style['trend-label']} ${Style['trend-decrease']}`}>
      <div className={Style.trendLabelValue}>
        <Typography
          tag="p"
          text={`${formattedTrendValue}%`}
          weight="semi-bold"
          size="xs"
          color="red500"
        />
        {variant === 'comparison' ? (
          <IconLib
            pack="own"
            iconHeight="11"
            iconWidth="7"
            image="trendDown2"
          />
        ) : (
          <IconLib
            pack="own"
            iconHeight="14"
            iconWidth="14"
            image="trendDown"
          />
        )}
      </div>
    </div>
  )
}
