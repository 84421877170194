import { z } from 'zod'

export const SingleDappAIInsightSchema = z.object({
  chain: z.string(),
  dapp_name: z.string(),
  metric: z.string(),
  content: z.string(),
  execution_time: z.string(),
  title: z.string(),
  logo: z.string(),
})

export const DappAIInsightsSchema = z.array(SingleDappAIInsightSchema)
