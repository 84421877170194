import axios from 'axios'
import settings from 'settings.json'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchUnits = async () => {
  const response = await axios.get(`${serverURL}/api/dapp-analytics/units`)
  const data = response.data

  return data
}

export const fetchEvents = async (dappId: string) => {
  const response = await axios.get(
    `${serverURL}/api/dapp-analytics/dapp/${dappId}/abi/events`
  )
  const data = response.data

  return data
}

export const fetchCalls = async (dappId: string) => {
  const response = await axios.get(
    `${serverURL}/api/dapp-analytics/dapp/${dappId}/abi/calls`
  )
  const data = response.data

  return data
}
