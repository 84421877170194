/***
 *
 *   CARD
 *
 **********/

import React, { ReactNode } from 'react'
import clsx from 'clsx'

import Style from './Card.module.css'

interface CardProps {
  children: ReactNode
  title?: string
  size?: 'full'
  darkMode?: boolean
}

export const Card: React.FC<CardProps> = ({
  children,
  title,
  size,
  darkMode,
}) => {
  const cardStyle = clsx(
    Style['card'],
    size && Style[size],
    darkMode && Style['darkMode']
  )

  return (
    <div className={cardStyle}>
      {title && (
        <header className={Style.header}>
          <h1 className={Style.title}>{title}</h1>
        </header>
      )}
      {children}
    </div>
  )
}
