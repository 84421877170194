import React, { useState, useEffect, useContext } from 'react'
import { TwitterShareButton } from 'react-share'

import {
  Image,
  Loader,
  IconLib,
  IconButton,
  ViewContext,
  Typography,
} from 'components/lib'
import { logger } from 'utils/logger'
import { fetchGiDappAIInsights } from 'utils/fetches/gi'
import { getValidationErrorMessage } from 'utils/helpers'
import glossy from 'assets/icons/glossy.svg'

import { DappAIInsightsSchema } from 'features/gi/dapp/giDappBasicDashboard/types/dappAIInsightsSchema'
import Style from './giDappAIInsights.module.scss'

export function GiDappAIInsights({ dappName, selectedBlockchains, title }) {
  const [insights, setInsights] = useState()
  const [isLoadingInsights, setIsLoadingInsights] = useState(false)
  const viewContext = useContext(ViewContext)

  useEffect(() => {
    if (dappName) {
      const fetchData = async () => {
        try {
          setIsLoadingInsights(true)
          let bodyRequest = {
            dappNames: [dappName],
          }
          if (selectedBlockchains.length === 1) {
            bodyRequest.blockchain = selectedBlockchains[0].slug
          }
          const response = await fetchGiDappAIInsights(bodyRequest)
          const validatedAIInsights = DappAIInsightsSchema.safeParse(
            response.data.data
          )
          if (!validatedAIInsights.success) {
            logger.error(validatedAIInsights.error)
            throw Error(getValidationErrorMessage('Insights'))
          }
          setInsights(validatedAIInsights.data)
          setIsLoadingInsights(false)
        } catch (err) {
          setIsLoadingInsights(false)
          viewContext.handleError(err)
        }
      }

      fetchData()
    }
  }, [dappName, selectedBlockchains])

  return (
    <section className={Style.insights}>
      <div className={Style.header}>
        <div className={Style.titleContainer}>
          <Image source={glossy} />
          <Typography
            tag="h2"
            color="gray900"
            size="m"
            weight="semi-bold"
            text={title}
            className="nowrap"
          />
        </div>
      </div>
      <div className={Style.cardContainer}>
        {isLoadingInsights && <Loader />}
        {!isLoadingInsights &&
          insights?.length > 0 &&
          insights.map((insight, index) => (
            <div key={index} className={Style.insightCard}>
              <div className={Style.cardHeader}>
                <div className={Style.cardTitleContainer}>
                  <img src={insight.logo} alt="" />
                  <h3 className={Style.cardTitle}>{insight.title}</h3>
                </div>
                <div className={Style.insightSettings}>
                  <TwitterShareButton url={window.location.href}>
                    <div className={Style.buttonShare}>
                      Share to
                      <IconLib
                        pack="own"
                        image="portalx"
                        color="gray600"
                        size="12"
                      />
                    </div>
                  </TwitterShareButton>
                  <IconButton
                    icon={
                      <IconLib
                        pack="own"
                        image="copy"
                        color="gray600"
                        size="24"
                      />
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(insight.content)
                    }}
                  />
                </div>
              </div>
              <p className={Style.content}>{insight.content}</p>
            </div>
          ))}
        {!isLoadingInsights && insights?.length === 0 && (
          <Typography
            tag="p"
            size="s"
            weight="regular"
            color="secondary800"
            text="No insights at the moment ;) Stay tuned!"
          />
        )}
      </div>
    </section>
  )
}
