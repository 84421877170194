export function getUniqueObjsBySelectors(contracts: Array<unknown>) {
  const selectors = new Map()

  contracts.forEach((contract) => {
    contract.calls.forEach((call) => {
      if (!selectors.has(call.selector)) {
        selectors.set(call.selector, call)
      }
    })
  })

  return Array.from(selectors.values())
}

export function getUniqueObjsByName(contracts: Array<unknown>) {
  const names = new Map()

  contracts.forEach((contract) => {
    contract.events.forEach((event) => {
      if (!names.has(event.name)) {
        names.set(event.name, event)
      }
    })
  })

  return Array.from(names.values())
}
