import { z } from 'zod'

export const DashboardInfoSchema = z.object({
  title: z.string().optional(),
  preview: z.string().optional(),
  logo: z.string().optional(),
  info: z.string().optional(),
  tags: z.array(z.string()).optional(),
  _id: z.string(),
  temporary_id: z.string().optional(),
  created_by: z.string().optional(),
  is_user_made: z.boolean(),
  likes: z.array(z.unknown()),
  state: z.string(),
  view_count: z.number(),
  access_type: z.string(),
  access_link: z.nullable(z.string()), // Null or string
  emails_with_access: z.array(z.string()),
  hidden_emails_with_access: z.array(z.string()),
  created_at: z.string(),
  modified_at: z.string(),
  __v: z.number(),
  // creator: z.object({
  //   _id: z.string(),
  //   name: z.string(),
  //   email: z.string()
  // })
})

export type TDashboardInfo = z.infer<typeof dashboardInfoSchema>
