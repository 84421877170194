import React from 'react'
import ReactDOMServer from 'react-dom/server'

import Style from './compareSingleValue.module.scss'
import {
  Loader,
  AnimationValue,
  ClassHelper,
  IconLib,
  Typography,
  CustomTooltip,
} from 'components/lib'
import { TrendLabel } from './TrendLabel'

export const CompareSingleValue = ({
  data,
  showTrend,
  name,
  loading,
  prefix,
  darkMode,
  tooltipInfo,
}) => {
  const singleValueStyle = ClassHelper(Style, {
    darkMode: darkMode,
  })

  return (
    <div className={singleValueStyle}>
      {loading ? (
        <div className={Style['loader-container']}>
          <Loader />
        </div>
      ) : (
        <ValuesContainer
          darkMode={darkMode}
          prefix={prefix}
          value={data}
          showTrend={showTrend}
        />
      )}

      {name && (
        <div className={Style['name-container']}>
          <p className={Style.name}>{name}</p>
          <div
            className="relative"
            data-tooltip-id={name}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <Typography
                text={tooltipInfo}
                tag="p"
                weight="regular"
                size="s"
                color="gray700"
              />
            )}
            data-tooltip-place="bottom-start"
            data-tooltip-position-strategy="fixed"
            data-tooltip-offset={8}
          >
            <IconLib image="information" pack="own" size="16" />
            <CustomTooltip id={name} light />
          </div>
        </div>
      )}
    </div>
  )
}

const ValuesContainer = ({ value, showTrend, prefix, darkMode }) => {
  if (typeof value !== 'object') {
    return <span className={Style.message}>N/A</span>
  }

  if (value?.length === 0 || value === 0) {
    return <span className={Style.message}>No data</span>
  }

  if (showTrend) {
    return (
      <div className={Style.valuesContainerWithTrend}>
        <div className={Style.leftColumn}>
          {value[0]?.value.change !== null &&
          value[0]?.value.change !== undefined ? (
            <TrendLabel
              trend={value[0]?.value.change}
              darkMode={darkMode}
              variant="comparison"
            />
          ) : (
            <p className={Style.emptyBlock}></p>
          )}
          <div key={value[0]?.title} className={Style.leftRow}>
            <img
              src={value[0]?.logo}
              alt={`${value[0]?.title} logo`}
              className={Style.logo}
            />
            <div className={Style.value}>
              <AnimationValue
                value={value[0]?.value.currentValue}
                prefix={prefix}
              />
            </div>
          </div>
        </div>
        <div className={Style.line}></div>
        <div className={Style.rightColumn}>
          {value[1]?.value?.change !== null &&
          value[1]?.value?.change !== undefined ? (
            <TrendLabel
              trend={value[1]?.value.change}
              darkMode={darkMode}
              variant="comparison"
            />
          ) : (
            <p className={Style.emptyBlock}></p>
          )}
          <div key={value[1]?.title} className={Style.rightRow}>
            <img
              src={value[1]?.logo}
              alt={`${value[1]?.title} logo`}
              className={Style.logo}
            />
            <div className={Style.value}>
              <AnimationValue
                value={value[1]?.value?.currentValue}
                prefix={prefix}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={Style.valuesContainer}>
      {value.map((item) => (
        <div key={item.title} className={Style.valueRow}>
          <img
            src={item.logo}
            alt={`${item.title} logo`}
            className={Style.logo}
          />
          <div className={Style.value}>
            <AnimationValue value={item.value} prefix={prefix} />
          </div>
        </div>
      ))}
    </div>
  )
}
