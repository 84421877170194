import React from 'react'

import {
  Image,
  ButtonNew,
  useNavigate,
  Title,
  Card,
  Typography,
  Icon,
  ErrorContainer,
} from 'components/lib'
import forbiddenFace from 'assets/images/sad-face.png'

export function Forbidden() {
  const navigate = useNavigate()

  return (
    <Card>
      <ErrorContainer>
        <div className="align-xy align-center">
          <Image
            source={forbiddenFace}
            alt="403 forbidden, sad face"
            marginAuto
          />
          <Title big dark>
            Error: <span>403</span>
          </Title>
          <Typography
            tag="p"
            text="You don't have the permissions to access this page"
            size="s"
            weight="regular"
          />
          <ButtonNew
            onClick={() => navigate('/')}
            size="xlarge"
            color="secondary"
            startIcon={
              <Icon name="leftChevron" width="16" height="16" color="white" />
            }
          >
            Go Back Home
          </ButtonNew>
        </div>
      </ErrorContainer>
    </Card>
  )
}
