import React from 'react'
import { NavLink } from 'react-router-dom'

import { Icon, Typography } from 'components/lib'
import { paths } from 'utils/paths'

import Style from './SlideButton.module.css'

export const SlideButton = () => (
  <NavLink to={paths.dashboardBuilder} className={Style['slide-button']}>
    <Icon name="plus" active width="1.6rem" height="1.6rem" color="green700" />
    <Typography tag="p" size="s" text="Create new dashboard" color="green700" />
  </NavLink>
)
