/***
 *
 *   GROWTH INDEX DAPPS DATA CONTEXT
 *
 **********/

import React, { useState, createContext } from 'react'

export const GiDappsDataContext = createContext()

export function GiDappsDataProvider({ children }) {
  const [giDappsDataContext, setGiDappsDataContext] = useState([])

  return (
    <GiDappsDataContext.Provider
      value={{ giDappsDataContext, setGiDappsDataContext }}
    >
      {children}
    </GiDappsDataContext.Provider>
  )
}
