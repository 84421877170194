/***
 *
 *   TG GI BASIC DASHBOARD PAGE
 *
 **********/

import React from 'react'

import { Animate, MainContentContainer } from 'components/lib'

import { GiEcosystemBasicDashboard } from 'features/gi/ecosystem/giEcosystemBasicDashboard/giEcosystemBasicDashboard'

export function BasicPage() {
  return (
    <Animate type="pop">
      <MainContentContainer>
        <GiEcosystemBasicDashboard />
      </MainContentContainer>
    </Animate>
  )
}
