import React from 'react'

import { ResultingChart } from 'features/builders/chartBuilder/components/ResultingChart'
import { ResultingTable } from 'features/builders/chartBuilder/components/ResultingTable'

import Style from './ResultView.module.css'

export const ResultView = ({ chartType, chartTitle, data, selectedUnit }) => {
  return (
    <div className={Style['result-view-container']}>
      <ResultingChart
        data={data}
        chartType={chartType}
        chartTitle={chartTitle}
        selectedUnit={selectedUnit}
      />
      <ResultingTable data={data} />
    </div>
  )
}
