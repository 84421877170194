import axios from 'axios'
import settings from 'settings.json'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchAnalyticsDapps = async () => {
  const response = await axios.get(`${serverURL}/api/dapp-analytics/dapp/all`)
  const data = response.data

  return data
}

export const fetchDappsBasicInfo = async (param: string) => {
  const response = await axios.get(`api/dapps?${param}`)
  const data = response.data

  return data
}

export const fetchDapp = async (dappId: string) => {
  const response = await axios.get(
    `${serverURL}/api/dapp-analytics/dapp/${dappId}`
  )
  const data = response.data

  return data
}

export const fetchDappBySlug = async (slug: string) => {
  const response = await axios.get(`${serverURL}/api/dapp/${slug}`)
  const data = response.data

  return data
}

export const fetchDapps = async (params: string) => {
  const response = await axios.get(`${serverURL}/api/dapps?${params}`)
  const data = response.data

  return data
}
