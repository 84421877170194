/***
 *
 *  SHARED DASHBOARDS
 *  Page with grid of dashbboars shared to the user
 *
 **********/

import React, { useEffect, useState } from 'react'

import {
  Animate,
  useDashboards,
  Grid,
  Typography,
  Loader,
  Container,
  Slide,
} from 'components/lib'
import { paths } from 'utils/paths'

export const SharedDashboardGrid = () => {
  const [userDashboards, setUserDashboards] = useState()
  const { dashboards, isLoadingDashboards } = useDashboards(`/user-shared`)

  useEffect(() => {
    if (dashboards.length >= 0) {
      setUserDashboards(dashboards)
    }
  }, [dashboards])

  return (
    <Container marginTop16 marginBottom8 noPadding>
      <Animate type="pop">
        <div className="relative min-height">
          <Grid displayGrid>
            {userDashboards &&
              userDashboards.length > 0 &&
              userDashboards.map(({ title, id, dappName, dappLogo }) => {
                return (
                  <Slide
                    path={`${paths.dashboard}/${id}`}
                    id={id}
                    title={title}
                    key={id}
                    dappName={dappName}
                    dappLogo={dappLogo}
                  />
                )
              })}
            {!isLoadingDashboards &&
              userDashboards &&
              userDashboards?.length === 0 && (
                <Typography tag="p" text="No shared dashboards" size="s" />
              )}
          </Grid>
          {isLoadingDashboards && <Loader />}
        </div>
      </Animate>
    </Container>
  )
}
