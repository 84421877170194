import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import settings from 'settings'

import { getValidationErrorMessage } from 'utils/helpers'
import { logger } from 'utils/logger'
import {
  ModalNew,
  Typography,
  Line,
  ButtonNew,
  DateRangeInput,
  CustomSelectNew,
  CustomSelect,
  NumberInput,
  Icon,
  Loader,
} from 'components/lib'
import { useAnalyticsDapps } from 'hooks/useAnalyticsDapps'
import { DappsBasicSchema, TBasicDapp } from 'types/dapp'
import { fetchDapps } from 'utils/fetches/dapp'
import { TOptionOfDropdown } from 'types/optionOfDropdown'

import { SegmentTitle } from 'features/builders/shared/components/SegmentTitle'
import { AddSegmentModalContext } from 'features/builders/shared/contexts/AddSegmentModalContext'

import Style from './AddSegment.module.css'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

type ValuePiece = Date | null
type Value = ValuePiece | [ValuePiece, ValuePiece]
type TAddSegmentProps = {
  dappId?: string
}
type TSegmentToAdd = {
  name: string
  isAirdropRecipient?: boolean
  firstInteractionMin: ValuePiece
  firstInteractionMax: ValuePiece
  lastObservationMin: ValuePiece
  lastObservationMax: ValuePiece
  airdropValueMin?: number
  airdropValueMax?: number
  firstAirdropTimestampMin?: ValuePiece
  firstAirdropTimestampMax?: ValuePiece
}

export const AddSegment = ({ dappId }: TAddSegmentProps) => {
  const [segment, setSegment] = useState<TSegmentToAdd>({
    name: 'Default name',
    firstInteractionMin: null,
    firstInteractionMax: null,
    lastObservationMin: null,
    lastObservationMax: null,
  })
  const [isAirdropRecipientExpanded, setIsAirdropRecipientExpanded] =
    useState<boolean>(false)
  const [selectedBlockchainOption, setSelectedBlockchainOption] = useState<
    TOptionOfDropdown | undefined
  >()
  const [selectedDappOption, setSelectedDappOption] = useState<
    TOptionOfDropdown | undefined
  >()
  const { dapps, isLoadingDapps } = useAnalyticsDapps()
  const [dappsOptions, setDappsOptions] = useState<
    Array<TOptionOfDropdown> | undefined
  >()
  const [otherDappsUsedOptions, setOtherDappsUsedOptions] = useState<
    Array<TOptionOfDropdown> | undefined | []
  >()
  const [blockchainsOptions, setBlockchainsOptions] = useState<
    Array<TOptionOfDropdown> | undefined
  >()
  const addSegmentContext = useContext(AddSegmentModalContext)

  if (!addSegmentContext) {
    throw Error('Segment modal context has to be in provider')
  }

  const { setIsDisplayingAddSegmentModal } = addSegmentContext

  const prepareUsedOtherDapps = async (blockchainName: string) => {
    try {
      const response = await fetchDapps(
        `active=true&dapp_growth_index=true&blockchains=${blockchainName}`
      )

      const validatedOtherDappstData = DappsBasicSchema.safeParse(response.data)
      if (!validatedOtherDappstData.success) {
        throw new Error(validatedOtherDappstData.error)
      }
      const preparedUsedOtherDappsOptions = response.data.map(
        (item: TBasicDapp) => {
          return {
            label: item.name,
            value: item.slug,
            icon: item.icon,
          }
        }
      )
      setOtherDappsUsedOptions(preparedUsedOtherDappsOptions)
    } catch (err) {
      setOtherDappsUsedOptions([])
      logger.error(err.message)
      toast.error(getValidationErrorMessage('other dapps used'))
    }
  }

  useEffect(() => {
    if (dapps && dappId) {
      const preparedDappsOptions = dapps.map((item) => {
        return {
          label: item.name,
          value: item.name,
          icon: item.logo,
          name: item.name,
        }
      })
      setDappsOptions(preparedDappsOptions)
      const selectedDapp = dapps.filter((dapp) => dapp.id === dappId)
      setSelectedDappOption({
        value: selectedDapp[0].name,
        label: selectedDapp[0].name,
      })

      setSelectedBlockchainOption({
        value: selectedDapp[0].blockchain?.slug,
        label: selectedDapp[0].blockchain?.name,
        icon: selectedDapp[0].blockchain?.logo,
      })
      setBlockchainsOptions([
        {
          value: selectedDapp[0].blockchain?.slug,
          label: selectedDapp[0].blockchain?.name,
        },
      ])
      setSegment((prevState) => ({
        ...prevState,
        dappId: dappId,
      }))

      prepareUsedOtherDapps(selectedDapp[0].blockchain?.slug)
    }
  }, [dapps, dappId])

  const saveSegment = async () => {
    try {
      await axios.post(`${serverURL}/api/segments`, segment)
      toast.success('Segment created successfully')
      setIsDisplayingAddSegmentModal(false)
    } catch (err) {
      toast.error(
        `We're sorry, the segment could not be saved. Please try again later.`
      )
    }
  }

  const handleAirdropRecipient = (option: TOptionOfDropdown) => {
    if (option.value === 'yes') {
      setIsAirdropRecipientExpanded(true)
      setSegment((prevState) => ({
        ...prevState,
        isAirdropRecipient: true,
      }))
    } else if (option.value === 'no') {
      setIsAirdropRecipientExpanded(false)
      setSegment((prevState) => ({
        ...prevState,
        isAirdropRecipient: false,
      }))
    } else {
      setIsAirdropRecipientExpanded(false)
      setSegment((prevState) => {
        const { isAirdropRecipient, ...newState } = prevState
        return newState
      })
    }
  }

  const handleDapp = () => {
    // TO DO
  }

  const handleBlockchain = () => {
    // TO DO
  }

  const handleOtherDapps = () => {
    console.log('do handleOtherDapps')
  }

  const handleInteractionsRange = (value: Value) => {
    if (!value) {
      setSegment((prevState) => ({
        ...prevState,
        firstInteractionMin: null,
        firstInteractionMax: null,
      }))
    } else {
      setSegment((prevState) => ({
        ...prevState,
        firstInteractionMin: value[0],
        firstInteractionMax: value[1],
      }))
    }
  }

  const handleObservationsRange = (value: Value) => {
    if (!value) {
      setSegment((prevState) => ({
        ...prevState,
        lastObservationMin: null,
        lastObservationMax: null,
      }))
    } else {
      setSegment((prevState) => ({
        ...prevState,
        lastObservationMin: value[0],
        lastObservationMax: value[1],
      }))
    }
  }

  const handleFirstAirdropRange = (value: Value) => {
    if (!value) {
      setSegment((prevState) => ({
        ...prevState,
        firstAirdropTimestampMin: null,
        firstAirdropTimestampMax: null,
      }))
    } else {
      setSegment((prevState) => ({
        ...prevState,
        firstAirdropTimestampMin: value[0],
        firstAirdropTimestampMax: value[1],
      }))
    }
  }

  const handleMinMaxAirdrop = (
    e: React.ChangeEvent<HTMLInputElement>,
    propertyName: keyof TSegmentToAdd
  ) => {
    const newValue = e.target.value

    setSegment((prevState) => ({
      ...prevState,
      [propertyName]: newValue,
    }))
  }

  const handleUsedFunctions = () => {
    console.log('do handleUsedFunctions')
  }

  const handleSegmentTitle = (value: string) => {
    setSegment((prevState) => ({
      ...prevState,
      name: value,
    }))
  }

  return (
    <ModalNew
      title={segment.name}
      hasCloseButton={false}
      isOpen={setIsDisplayingAddSegmentModal}
    >
      <div className={Style['segment-container']}>
        <div className={Style['segment-top-bar']}>
          <SegmentTitle name={segment.name} setName={handleSegmentTitle} />
          <div className={Style['segment-buttons-container']}>
            <ButtonNew
              color="green500"
              variant="outline"
              onClick={() => setIsDisplayingAddSegmentModal(false)}
            >
              Cancel
            </ButtonNew>
            <ButtonNew color="green500" onClick={saveSegment}>
              Save Segment
            </ButtonNew>
          </div>
        </div>
        <Typography
          text="The segment will be saved with Today’s date, you can update it later through segment management"
          tag="p"
          color="gray500"
          size="m"
          weight="regular"
        />
        <Line />
        {otherDappsUsedOptions ? (
          <>
            <div className={Style['segment-selects-grid']}>
              {blockchainsOptions?.length > 0 && dappsOptions?.length > 0 ? (
                <>
                  <CustomSelectNew
                    isSearchable={false}
                    withArrow
                    name="blockchain"
                    label="Blockchain"
                    disabled={dappId ? true : false}
                    defaultValue={
                      selectedBlockchainOption
                        ? selectedBlockchainOption
                        : {
                            value: blockchainsOptions[0].value,
                            label: blockchainsOptions[0].label,
                            icon: blockchainsOptions[0].icon,
                          }
                    }
                    options={blockchainsOptions}
                    change={handleBlockchain}
                    size="medium"
                  />
                  <CustomSelectNew
                    isSearchable={false}
                    withArrow
                    label="dApp"
                    name="dapp"
                    disabled={dappId ? true : false}
                    defaultValue={dappId ? selectedDappOption : dappsOptions[0]}
                    options={dappsOptions}
                    change={handleDapp}
                    size="medium"
                  />
                </>
              ) : null}
            </div>
            <CustomSelectNew
              isSearchable={false}
              withArrow
              label="Airdrop Recipient?"
              name="airdrop recipient"
              defaultValue={{
                value: true,
                label: `Doesn't matter`,
              }}
              placeholder="Airdrop token"
              options={[
                { value: 'no matter', label: `Doesn't matter` },
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ]}
              change={handleAirdropRecipient}
              size="medium"
            />
            {isAirdropRecipientExpanded && (
              <div className={Style['airdrop-container']}>
                <div className={Style['airdrop-value-container']}>
                  <NumberInput
                    name="min airdrop"
                    label="Min Airdrop Value"
                    change={(e) => handleMinMaxAirdrop(e, 'airdropValueMin')}
                    placeholder="Enter Your Value"
                    value={segment?.airdropValueMin}
                  />
                  <NumberInput
                    name="max airdrop"
                    label="Max Airdrop Value"
                    change={(e) => handleMinMaxAirdrop(e, 'airdropValueMax')}
                    placeholder="Enter Your Value"
                    value={segment?.airdropValueMax}
                  />
                </div>
                <DateRangeInput
                  label="First Airdrop Range"
                  onChange={handleFirstAirdropRange}
                  value={[
                    segment?.firstAirdropTimestampMin,
                    segment?.firstAirdropTimestampMax,
                  ]}
                  format="yyyy-MM-dd"
                  clearIcon={<Icon name="exitThin" width="16" height="16" />}
                />
              </div>
            )}
            <div className={Style['segment-selects-container']}>
              <CustomSelect
                isSearchable={false}
                isMulti
                name="Other dApps"
                label="Other dApps"
                classNamePrefix="react-select-with-icon"
                placeholder="Select Other dApps"
                options={otherDappsUsedOptions}
                change={handleOtherDapps}
                size="medium"
                withArrow
              />
              <DateRangeInput
                label="dApp First Interaction"
                onChange={handleInteractionsRange}
                value={[
                  segment?.firstInteractionMin,
                  segment?.firstInteractionMax,
                ]}
                format="yyyy-MM-dd"
                clearIcon={<Icon name="exitThin" width="16" height="16" />}
              />
              <DateRangeInput
                label="Last Observation"
                onChange={handleObservationsRange}
                value={[
                  segment?.lastObservationMin,
                  segment?.lastObservationMax,
                ]}
                format="yyyy-MM-dd"
                clearIcon={<Icon name="exitThin" width="16" height="16" />}
              />
              <CustomSelect
                isSearchable={false}
                isMulti
                name="used functions"
                label="Used Functions"
                classNamePrefix="react-select-with-icon"
                withArrow
                placeholder="Select used functions"
                options={[]}
                change={handleUsedFunctions}
                size="medium"
              />
            </div>
          </>
        ) : (
          <div className={Style['loader-container']}>
            <Loader />
          </div>
        )}
      </div>
    </ModalNew>
  )
}
