import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'

import {
  CustomSelectNew,
  CustomSelect,
  ButtonNew,
  Icon,
  Typography,
} from 'components/lib'
import { objectToQueryString } from 'utils/helpers'
import { paths } from 'utils/paths'

import { useGiBlockchainsData } from 'features/gi/ecosystem/shared/hooks/useGiBlockchainsData'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import Style from './filtersSection.module.scss'

export const FiltersSection = () => {
  const navigate = useNavigate()
  const [_searchParams, setSearchParams] = useSearchParams()
  const {
    selectedBlockchain,
    selectedBlockchainsToCompare,
    verifiedBlockchainSegment,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
  } = useVerifiedFiltersParams()
  const { giBlockchainsData } = useGiBlockchainsData()
  const [isFiltersReadyToDisplay, setIsFiltersReadyToDisplay] = useState(false)
  const location = useLocation()

  const handleBlockchainFilter = (option) => {
    const queryParams = {
      period: verifiedDateRangeParam,
    }
    if (verifiedCompareWithParam) {
      const blockchainToCompareArr = verifiedCompareWithParam.split(',')
      blockchainToCompareArr.filter((item) => item === option.value)
      let testStr = verifiedCompareWithParam
      if (blockchainToCompareArr.includes(option.value)) {
        const test = blockchainToCompareArr.filter(
          (item) => item !== option.value
        )
        testStr = test.join(',')
      }
      queryParams['compare with'] = testStr
    }
    const queryString = objectToQueryString(queryParams)

    if (location.pathname.includes('charts')) {
      navigate(`/crypto/${option.value}/charts?${queryString}`)
    } else {
      navigate(`/crypto/${option.value}?${queryString}`)
    }
  }

  const handleCompareToFilter = (option) => {
    const queryParams = {
      period: verifiedDateRangeParam,
    }

    if (option.length > 0) {
      queryParams['compare with'] = option.map((obj) => obj.value).join(',')
    }

    const queryString = objectToQueryString(queryParams)
    setSearchParams(queryString)
  }

  const handleDateFilter = (option) => {
    const queryParams = {
      period: option.value,
    }
    if (verifiedCompareWithParam) {
      queryParams['compare with'] = verifiedCompareWithParam
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  const blockchainsOptions = giBlockchainsData
    .filter(
      (item) => item.blockchain.name !== selectedBlockchain?.blockchain?.name
    )
    .map((item) => {
      return {
        label: item.blockchain.name,
        value: item.blockchain.slug,
        icon: item.blockchain.logo,
        network: item.blockchain?.network || 'Mainnet',
        name: item.blockchain.name,
        id: item.blockchain.id,
      }
    })

  const dateOptions = [
    { label: 'Last Month', value: 'last month' },
    { label: 'Last 3 Months', value: '3 months' },
    { label: 'Last 6 Months', value: '6 months' },
    { label: 'Last Year', value: 'last year' },
    { label: 'All Time', value: 'all time' },
  ]

  useEffect(() => {
    if (verifiedBlockchainSegment) {
      setIsFiltersReadyToDisplay(true)
    }
  }, [
    verifiedBlockchainSegment,
    verifiedDateRangeParam,
    verifiedCompareWithParam,
    selectedBlockchainsToCompare,
  ])

  return (
    <>
      {isFiltersReadyToDisplay && (
        <section className={Style.section}>
          <div className={Style.filters}>
            <CustomSelectNew
              id="blockchain"
              isSearchable
              prefix="Blockchain:"
              name="blockchain"
              color="green500"
              defaultValue={{
                value: verifiedBlockchainSegment,
                label: selectedBlockchain.blockchain.name,
                icon: selectedBlockchain.blockchain.logo,
              }}
              options={blockchainsOptions}
              change={handleBlockchainFilter}
              size="medium"
            />
            <CustomSelect
              isSearchable
              isMulti
              name="compare"
              prefix="Compare With"
              classNamePrefix="react-select-multi-with-icon"
              defaultValue={
                selectedBlockchainsToCompare
                  ? selectedBlockchainsToCompare.map((item) => ({
                      label: item.blockchain.name,
                      value: item.blockchain.slug,
                      icon: item.blockchain.logo,
                    }))
                  : null
              }
              options={blockchainsOptions}
              change={handleCompareToFilter}
              placeholder="Add Comparison"
              size="medium"
            />
            <CustomSelectNew
              id="date-range"
              isSearchable={false}
              prefixIcon={
                <Icon height="16" width="16" name="calendar" color="gray700" />
              }
              color="green500"
              prefix="Date Range:"
              name="date-range"
              defaultValue={{
                value: verifiedDateRangeParam ?? '',
                label: dateOptions.filter(
                  (option) => option.value === verifiedDateRangeParam
                )[0].label,
              }}
              options={dateOptions}
              change={handleDateFilter}
              size="medium"
            />
          </div>
          <ButtonNew
            color="white"
            moveTo={`${paths.giDappOverview}?blockchain=${selectedBlockchain.blockchain.slug}`}
          >
            <Typography
              tag="p"
              text="View dApps on"
              size="s"
              color="gray900"
              weight="medium"
            />
            <img
              src={selectedBlockchain.blockchain.logo}
              alt=""
              height="16"
              width="16"
            />
            <Typography
              tag="span"
              text={selectedBlockchain.blockchain.name}
              size="s"
              color="gray900"
              weight="medium"
            />
            <Icon name="diagonalArrow" height={12} width={12} color="gray700" />
          </ButtonNew>
        </section>
      )}
    </>
  )
}
