import React from 'react'
import { NavLink } from 'react-router-dom'

import { Card, Icon, DropdownNew, Image, Typography } from 'components/lib'
import { TOptionOfDropdown } from 'types/optionOfDropdown'
import imagePreview from 'assets/images/default-dashboard-image.png'

import Style from './Slide.module.css'

interface SlideProps {
  id: string
  title: string
  options?: Array<TOptionOfDropdown>
  dappLogo?: string
  dappName?: string
}

export const Slide: React.FC<SlideProps> = ({
  id,
  title,
  options,
  dappLogo,
  dappName,
}) => (
  <section className={Style['slide']}>
    <Card key={id}>
      <NavLink to={`/dashboard/${id}`}>
        <img
          className={Style['image']}
          src={imagePreview}
          alt="image preview"
        />
      </NavLink>
      <div className={Style['info-container']}>
        <NavLink to={`/dashboard/${id}`}>
          <p className={Style['name']}>{title}</p>
        </NavLink>
        {options && options.length > 0 ? (
          <DropdownNew options={options} id={id} position="bottom-right">
            <Icon name="more" width={16} height={16} />
          </DropdownNew>
        ) : null}
      </div>
      <NavLink to={`/dashboard/${id}`}>
        <div className={Style['dapp-info-container']}>
          <Image source={dappLogo} alt={`${dappName} logo`} />
          <Typography
            size="xs"
            weight="regular"
            text={dappName}
            tag="p"
            color="gray600"
          />
        </div>
      </NavLink>
    </Card>
  </section>
)
