import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  useNavigate,
  CustomSelect,
  CustomSelectNew,
  CustomToggle,
  Typography,
  Icon,
} from 'components/lib'
import { objectToQueryString } from 'utils/helpers'

import { useBasicDappsData } from 'features/gi/shared/hooks/useBasicDappsData'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import Style from './DappBreakdownFilters.module.scss'

export const DappBreakdownFilters = ({
  selectedDapp,
  selectedDappsToCompare,
  verifiedDappSegment,
  verifiedCompareWithParam,
  verifiedDateRangeParam,
  verifiedBlockchainBreakdownParam,
  verifiedBlockchainsParam,
  selectedBlockchains,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { basicDappsData } = useBasicDappsData()
  const [isFiltersReadyToDisplay, setIsFiltersReadyToDisplay] = useState(false)
  const [blockchainsOptionsOfDapp, setBlockchainsOptionsOfDapp] = useState()
  const navigate = useNavigate()

  const handleDappFilter = (option) => {
    const queryParams = {
      period: verifiedDateRangeParam,
      'blockchain breakdown': verifiedBlockchainBreakdownParam,
    }

    if (verifiedBlockchainsParam) {
      queryParams.blockchains = verifiedBlockchainsParam
    }

    if (verifiedCompareWithParam) {
      const dappToCompareArr = verifiedCompareWithParam.split(',')
      dappToCompareArr.filter((item) => item === option.value)
      let testStr = verifiedCompareWithParam
      if (dappToCompareArr.includes(option.value)) {
        const test = dappToCompareArr.filter((item) => item !== option.value)
        testStr = test.join(',')
      }
      queryParams['compare with'] = testStr
    }
    const queryString = objectToQueryString(queryParams)

    navigate(`/app/${option.value}/charts?${queryString}`)
  }

  const handleBlockchainFilter = (option) => {
    const optionsValuesStr = option.map((option) => option.value).join(',')
    searchParams.delete('blockchains')
    if (optionsValuesStr) {
      searchParams.append('blockchains', optionsValuesStr)
    }
    setSearchParams(searchParams)
  }

  const handleCompareToFilter = (option) => {
    const queryParams = {
      period: verifiedDateRangeParam,
      'blockchain breakdown': verifiedBlockchainBreakdownParam,
    }

    if (verifiedBlockchainsParam) {
      queryParams.blockchains = verifiedBlockchainsParam
    }

    if (option.length > 0) {
      queryParams['compare with'] = option.map((obj) => obj.value).join(',')
    }

    const queryString = objectToQueryString(queryParams)
    setSearchParams(queryString)
  }

  const handleBlockchainBreakdownToggle = (value) => {
    const queryParams = {
      period: verifiedDateRangeParam,
      'blockchain breakdown': value,
    }

    if (verifiedBlockchainsParam) {
      queryParams.blockchains = verifiedBlockchainsParam
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  const handleDateFilter = (option) => {
    const queryParams = {
      period: option.value,
      'blockchain breakdown': verifiedBlockchainBreakdownParam,
    }

    if (verifiedBlockchainsParam) {
      queryParams.blockchains = verifiedBlockchainsParam
    }

    if (verifiedCompareWithParam) {
      queryParams['compare with'] = verifiedCompareWithParam
    }
    const queryString = objectToQueryString(queryParams)

    setSearchParams(queryString)
  }

  const dappsOptions = basicDappsData
    .filter((item) => item.name !== selectedDapp?.name)
    .map((item) => {
      return {
        label: item.name,
        value: item.slug,
        icon: item.icon,
        network: item?.network || 'Mainnet',
        name: item.name,
        id: item.id,
      }
    })

  const dateOptions = [
    { label: 'Last Month', value: 'last month' },
    { label: 'Last 3 Months', value: '3 months' },
    { label: 'Last 6 Months', value: '6 months' },
    { label: 'Last Year', value: 'last year' },
    { label: 'All Time', value: 'all time' },
  ]

  useEffect(() => {
    if (verifiedDappSegment) {
      setIsFiltersReadyToDisplay(true)
    }

    if (selectedDapp) {
      const onlyMainnetBlockchains = selectedDapp.blockchains.filter(
        (item) => item.network === 'Mainnet'
      )
      const modifiedBlockchains = onlyMainnetBlockchains.map((item) => {
        return {
          label: item.name,
          value: item.slug,
          icon: item.logo,
          name: item.name,
          slug: item.slug,
        }
      })
      setBlockchainsOptionsOfDapp(modifiedBlockchains)
    }
  }, [
    verifiedDappSegment,
    verifiedDateRangeParam,
    verifiedCompareWithParam,
    selectedDappsToCompare,
    selectedDapp,
  ])

  return (
    <>
      {isFiltersReadyToDisplay && (
        <section className={Style.section}>
          <CustomSelectNew
            id="Dapp"
            isSearchable
            isClearable
            prefix="dApp:"
            name="dapp"
            color="green500"
            defaultValue={{
              value: verifiedDappSegment,
              label: selectedDapp.name,
              icon: selectedDapp.icon,
            }}
            options={dappsOptions}
            change={handleDappFilter}
            size="medium"
          />
          <div
            className={
              verifiedBlockchainBreakdownParam
                ? `${Style['toggle-frame-active']} ${Style['toggle-frame']}`
                : `${Style['toggle-frame']}`
            }
          >
            <CustomToggle
              defaultChecked={verifiedBlockchainBreakdownParam}
              onChange={() =>
                handleBlockchainBreakdownToggle(
                  !verifiedBlockchainBreakdownParam
                )
              }
              icons={false}
              color="primary"
            />
            <Typography
              size="s"
              text="Blockchain Breakdown"
              weight="medium"
              tag="p"
              color="gray900"
              className="nowrap"
            />
          </div>
          {blockchainsOptionsOfDapp && blockchainsOptionsOfDapp.length > 1 ? (
            <CustomSelect
              isSearchable={false}
              isMulti
              name="Blockchain"
              prefix="Blockchain:"
              classNamePrefix="react-select-multi-with-icon"
              value={
                selectedBlockchains
                  ? selectedBlockchains.map((item) => ({
                      label: item.name,
                      value: item.slug,
                      icon: item.logo,
                    }))
                  : null
              }
              // placeholderIcon={<Icon height="16" width="16" name="dapp" color="gray900" />}
              placeholder="Blockchain"
              options={blockchainsOptionsOfDapp}
              change={handleBlockchainFilter}
              size="medium"
            />
          ) : null}
          {blockchainsOptionsOfDapp && blockchainsOptionsOfDapp.length === 1 ? (
            <CustomSelectNew
              id="blockchain"
              isSearchable
              isClearable
              prefix="Blockchain:"
              name="blockchain"
              value={{
                value: blockchainsOptionsOfDapp[0].value,
                label: blockchainsOptionsOfDapp[0].label,
                icon: blockchainsOptionsOfDapp[0].icon,
              }}
              options={blockchainsOptionsOfDapp}
              change={handleBlockchainFilter}
              disabled
              size="medium"
            />
          ) : null}
          <CustomSelect
            isSearchable={false}
            isMulti
            name="Compare With"
            prefix="Compare With:"
            classNamePrefix="react-select-multi-with-icon"
            disabled={verifiedBlockchainBreakdownParam}
            value={
              selectedDappsToCompare && !verifiedBlockchainBreakdownParam
                ? selectedDappsToCompare.map((item) => ({
                    label: item.name,
                    value: item.slug,
                    icon: item?.logo || item?.icon,
                  }))
                : null
            }
            placeholder="Add Comparison"
            options={dappsOptions}
            change={handleCompareToFilter}
            size="medium"
          />
          <CustomSelectNew
            prefix="Date Range:"
            prefixIcon={
              <Icon height="16" width="16" name="calendar" color="primary500" />
            }
            isSearchable={false}
            classNamePrefix="react-select-with-icon"
            name="date"
            color="green500"
            defaultValue={{
              value: verifiedDateRangeParam ?? '',
              label: dateOptions.filter(
                (option) => option.value === verifiedDateRangeParam
              )[0].label,
            }}
            options={dateOptions}
            change={handleDateFilter}
            size="medium"
            id="date"
            placeholder="Select Date Range"
          />
        </section>
      )}
    </>
  )
}
