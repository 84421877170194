import React from 'react'
import { Link } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'

import {
  Icon,
  CustomSimpleAreaChart,
  useMobile,
  Typography,
  CustomTooltip,
} from 'components/lib'
import { handleGrowthIndexLevel, capitalizeWords } from 'utils/helpers'
import { INDICATOR_NAME } from 'utils/constans'
import informations from 'utils/informations.json'

import { GiTooltipInformation } from 'features/gi/shared/components/giTooltipInformation/giTooltipInformation'
import { determineTrend } from 'features/gi/shared/utils/helpers'
import Style from './blockchainTable.module.scss'

export const BlockchainTable = ({ data }) => {
  const isMobile = useMobile()

  const determineRankChange = (change) => {
    if (change === 0) {
      return ''
    } else if (change > 0) {
      return (
        <div className={Style.changeRankContainer}>
          <Icon height="10" width="7" name="trendUp2" />
          <p className={Style.progressRank}>{change}</p>
        </div>
      )
    } else {
      return (
        <div className={Style.changeRankContainer}>
          <Icon height="10" width="7" name="trendDown2" />
          <p className={Style.regressRank}>{Math.abs(change)}</p>
        </div>
      )
    }
  }

  return (
    <div className={Style.tableWrapper}>
      <table className={Style.blockchainTable}>
        <thead>
          <tr className={Style.head}>
            <th>No.</th>
            <th>Blockchain</th>
            <th>
              <div className={Style.growthIndexColumnContainer}>
                {!isMobile && (
                  <Icon
                    name="growthIndex"
                    color="primary300"
                    width="16"
                    height="16"
                  />
                )}
                {INDICATOR_NAME}
                <div
                  className="relative"
                  data-tooltip-id="blockchain-info"
                  data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                    <GiTooltipInformation
                      title={`Ecosystem ${INDICATOR_NAME}`}
                      information={informations.ecosystem.gi}
                      metrics={informations.ecosystem.giListOfMetrics}
                    />
                  )}
                  data-tooltip-place="bottom-start"
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-offset={8}
                >
                  <Icon name="information" width="16" height="16" />
                  <CustomTooltip id="blockchain-info" light />
                </div>
              </div>
            </th>
            <th>
              <div
                className={`${Style.growthIndexColumnContainer} ${Style.growthIndexTimelineColumnContainer}`}
              >
                {!isMobile && (
                  <Icon
                    name="growthIndex"
                    color="primary300"
                    width="16"
                    height="16"
                  />
                )}
                {INDICATOR_NAME} Timeline (90d)
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr className={Style.row} key={row.blockchain.id}>
              <BlockchainColumn blockchainSlug={row.blockchain.slug}>
                <div className={Style.tdContainer}>
                  <Typography
                    text={index + 1}
                    size="m"
                    weight="regular"
                    tag="span"
                    color="primary500"
                  />
                  {determineRankChange(row.rankChange)}
                </div>
              </BlockchainColumn>
              <BlockchainColumn blockchainSlug={row.blockchain.slug}>
                <div className={Style.tdContainer}>
                  <img
                    className={Style.logo}
                    src={row.blockchain.logo}
                    alt={`${row.blockchain.name} logo`}
                  />
                  {row.blockchain.name}
                </div>
              </BlockchainColumn>
              <BlockchainColumn blockchainSlug={row.blockchain.slug}>
                <div className={Style.growthIndexColumnContainer}>
                  <Icon
                    name={`growthIndex${capitalizeWords(
                      handleGrowthIndexLevel(row.growthIndex)
                    )}`}
                    width="16"
                    height="16"
                  />
                  {row.growthIndex?.toFixed(0) || 0}
                </div>
              </BlockchainColumn>
              <BlockchainColumn blockchainSlug={row.blockchain.slug}>
                <CustomSimpleAreaChart
                  data={row.data || []}
                  style={{
                    height: '7.2rem',
                    width: !isMobile ? '100%' : '15rem',
                    margin: '0.4rem 0 0.2rem 0',
                  }}
                  trend={determineTrend(row.data || [])}
                />
              </BlockchainColumn>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const BlockchainColumn = ({ children, blockchainSlug }) => (
  <td>
    <Link to={`/crypto/${blockchainSlug}`}>
      <div className={Style['blockchain-column-container']}>{children}</div>
    </Link>
  </td>
)
