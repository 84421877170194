/***
 * HOOK of creator tools nav items
 *
 **********/

export function useNavAboutUsItems() {
  const navAboutUsItems = [
    {
      label: 'About Us',
      link: '/about-us',
      related: [],
      disabled: false,
      isTargetBlank: true,
    },
    {
      label: 'Blog',
      link: '/blog',
      related: [],
      disabled: false,
      isTargetBlank: true,
    },
  ]

  return navAboutUsItems
}
