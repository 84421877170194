import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

import {
  Title,
  Icon,
  useOutsideClick,
  ViewContext,
  Loader,
  useMobile,
  useElementDimensions,
  CustomTooltip,
  Typography,
} from 'components/lib'
import { logger } from 'utils/logger'
import { palette } from 'utils/constans'
import {
  convertFormatDate,
  formatToMoney,
  shortenAddress,
  getValidationErrorMessage,
} from 'utils/helpers'
import informations from 'utils/informations.json'

import {
  groupDataByDate,
  filterRecordsLast30Days,
} from 'features/gi/crm/shared/utils/helpers'
import { GiTooltipInformation } from 'features/gi/shared/components/giTooltipInformation/giTooltipInformation'
import { fetchGiDappUserInsightTimeline } from 'features/gi/crm/shared/utils/fetches'
import placeholder from 'features/gi/shared/assets/icons/placeholder.svg'
import { DappAddressInteractionsSchema } from 'features/gi/crm/shared/types/dappAddressInteractionsSchema'
import Style from './dappUserProfile.module.scss'

export const DappUserProfile = ({
  verifiedAddress,
  userData,
  dappSlug,
  blockchainSlug,
}) => {
  const [interactions, setInteractions] = useState()
  const [last30daysDapps, setLast30daysDapps] = useState()
  const [isLoadingInteractions, setIsLoadingInteractions] = useState(false)
  const [searchParams, _setSearchParams] = useSearchParams()
  const viewContext = useContext(ViewContext)
  const isMobile = useMobile()
  const navigate = useNavigate()
  const componentRef = useRef()
  const { width } = useElementDimensions(componentRef)
  const handleExitWidget = () => {
    if (dappSlug) {
      navigate(`/app/${dappSlug}/web3-crm?${searchParams}`)
    } else {
      navigate(`/crypto/${blockchainSlug}/web3-crm?${searchParams}`)
    }
  }

  const ref = useOutsideClick(() => {
    handleExitWidget()
  })

  useEffect(() => {
    if (verifiedAddress && userData?.length > 0) {
      const fetchData = async () => {
        try {
          setIsLoadingInteractions(true)
          const chains = [userData[0].chain?.slug]
          const response = await fetchGiDappUserInsightTimeline(
            verifiedAddress,
            chains,
            dappSlug
          )
          const validatedDappAddressInteractions =
            DappAddressInteractionsSchema.safeParse(response.data)
          if (!validatedDappAddressInteractions.success) {
            logger.error(validatedDappAddressInteractions.error)
            throw Error(getValidationErrorMessage('Interactions'))
          }
          setLast30daysDapps(
            filterRecordsLast30Days(validatedDappAddressInteractions.data)
          )

          setInteractions(
            groupDataByDate(validatedDappAddressInteractions.data)
          )
          setIsLoadingInteractions(false)
        } catch (err) {
          setIsLoadingInteractions(false)
          logger.error(err)
          viewContext.handleError(getValidationErrorMessage('Interactions'))
        }
      }

      fetchData()
    }
  }, [verifiedAddress, userData])

  return (
    <section className={Style.widgetContainer} ref={ref}>
      <div className={`${Style.titleContainer} mb16`}>
        <Title small>User Profile</Title>
        <button onClick={() => handleExitWidget(verifiedAddress)}>
          <Icon width="16" height="16" name="exit" color="gray600" />
        </button>
      </div>
      <span className={Style['small-title-container']} ref={componentRef}>
        <Icon width="12" height="12" name="wallet" color="gray800" />
        {width < 480 ? shortenAddress(verifiedAddress) : verifiedAddress}
      </span>
      {userData && userData.length === 1 ? (
        <GridOfBasicMetrics data={userData[0]} />
      ) : null}
      <div className={`${Style['small-title-container']} mt16`}>
        <Typography
          text="dApps used in the last 30 days"
          tag="p"
          weight="medium"
          size="s"
          color="primary500"
        />
        <div
          className="relative"
          data-tooltip-id="dapps-used"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <Typography
              text={informations['dApps used in the last 30 days']}
              tag="p"
              weight="regular"
              size="s"
              color="gray700"
            />
          )}
          data-tooltip-place="bottom-start"
          data-tooltip-position-strategy="fixed"
          data-tooltip-offset={8}
        >
          <Icon name="information" width="16" height="16" />
          <CustomTooltip id="dapps-used" light />
        </div>
      </div>
      {last30daysDapps?.length > 0 ? (
        <div className={Style.gridOfDapps}>
          {last30daysDapps.map((item) => {
            if (item?.dapp?.dapp_growth_index) {
              return (
                <Link
                  to={`/app/${item?.dapp?.slug}/analytics`}
                  key={item?.dapp_name}
                  target="_blank"
                >
                  <span className={Style.dappPosition}>
                    <img
                      src={item?.dapp?.icon || placeholder}
                      alt={`${item?.dapp_name} logo`}
                      className={Style.dappImage}
                    />
                    {item?.dapp_name}
                  </span>
                </Link>
              )
            } else {
              return (
                <span key={item?.dapp_name} className={Style.dappPosition}>
                  <img
                    src={item?.dapp?.icon || placeholder}
                    alt={`${item?.dapp_name} logo`}
                    className={Style.dappImage}
                  />
                  {item?.dapp_name}
                </span>
              )
            }
          })}
        </div>
      ) : (
        <div className={Style.gridOfDapps}>
          <span>-</span>
        </div>
      )}
      <div className={`${Style['small-title-container']} mt16`}>
        <Typography
          text="Interactions"
          tag="p"
          weight="medium"
          size="s"
          color="primary500"
        />
        <div
          className="relative"
          data-tooltip-id="interactions"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <Typography
              text={informations['Interactions']}
              tag="p"
              weight="regular"
              size="s"
              color="gray700"
            />
          )}
          data-tooltip-place="bottom-start"
          data-tooltip-position-strategy="fixed"
          data-tooltip-offset={8}
        >
          <Icon name="information" width="16" height="16" />
          <CustomTooltip id="interactions" light />
        </div>
      </div>
      {interactions && interactions?.length > 0 && !isLoadingInteractions ? (
        <div className={Style.timelineFrame}>
          <VerticalTimeline lineColor={palette.green500}>
            {interactions.map((item, index) => {
              return (
                <VerticalTimelineElement
                  key={`${item.week_of_record}${index}`}
                  className={Style.interaction}
                  contentStyle={{
                    border: `0.1rem solid ${palette.gray75}`,
                    borderRadius: '0.6rem',
                    boxShadow: 'none',
                    padding: '0.5rem 1rem',
                  }}
                  contentArrowStyle={{
                    display: 'none',
                  }}
                  date={convertFormatDate(item.week_of_record)}
                  dateClassName={Style.interactionDate}
                  iconStyle={{
                    background: palette.green500,
                    border: '0',
                    width: '1.2rem',
                    height: '1.2rem',
                    boxShadow: 'none',
                    marginTop: '1.4rem',
                    marginLeft: isMobile ? '1.4rem' : '-0.6rem',
                  }}
                >
                  {item.data.map((el) => {
                    if (el?.dapp?.dapp_growth_index) {
                      return (
                        <Link
                          to={`/app/${el?.dapp?.slug}/analytics`}
                          key={el?.dapp_name}
                          target="_blank"
                        >
                          <span className={Style.dappPosition} key={el?.caller}>
                            <img
                              className={Style.dappImage}
                              src={el?.dapp?.icon || placeholder}
                              alt={`${el?.dapp?.icon || 'Unknown'} logo`}
                            />
                            {el?.dapp_name || 'Unknown'}
                          </span>
                        </Link>
                      )
                    } else {
                      return (
                        <span className={Style.dappPosition} key={el?.caller}>
                          <img
                            className={Style.dappImage}
                            src={el?.dapp?.icon || placeholder}
                            alt={`${el?.dapp?.icon || 'Unknown'} logo`}
                          />
                          {el?.dapp_name || 'Unknown'}
                        </span>
                      )
                    }
                  })}
                </VerticalTimelineElement>
              )
            })}
          </VerticalTimeline>
        </div>
      ) : null}
      {isLoadingInteractions && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
      {interactions && interactions?.length === 0 && !isLoadingInteractions && (
        <p>No data</p>
      )}
    </section>
  )
}

export const GridOfBasicMetrics = ({ data }) => {
  return (
    <div className={Style.gridOfBasicMetrics}>
      <div className={Style.metricContainer}>
        <p className={Style.metricName}>Deposits</p>
        <p className={Style.metricValue}>{formatToMoney(data.deposits)}</p>
      </div>
      <div className={Style.metricContainer}>
        <p className={Style.metricName}>Date joined</p>
        <p className={Style.metricValue}>{data.date_joined}</p>
      </div>
      <div className={Style.metricContainer}>
        <p className={Style.metricName}>Last Interaction</p>
        <p className={Style.metricValue}>{data.last_interaction_date}</p>
      </div>
      <div className={Style.metricContainer}>
        <p className={Style.metricName}>Interactions</p>
        <p className={Style.metricValue}>{data.interactions}</p>
      </div>
    </div>
  )
}
