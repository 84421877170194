import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { BarChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

import zoom from 'assets/icons/zoom.svg'
import reset from 'assets/icons/reset.svg'
import { palette } from 'utils/constans'
import { determineChartDataFormat } from 'utils/helpers'
import { TTheme } from 'types/dashboard/theme'
import { useContainerDimensions } from 'hooks/useContainerDimensions'

import { Watermark } from '../watermark'
import tokenguard from '../tokenguard'
import {
  generateLegendsData,
  calcWidthOfLegend,
  getTopSeriesData,
  getTopNamesSelected,
} from '../helpers'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  ToolboxComponent,
  CanvasRenderer,
  BarChart,
  DataZoomSliderComponent,
  LegendComponent,
  DataZoomInsideComponent,
])

type TCustomBarChart = {
  data: Array<any>
  height?: string
  locked?: boolean
  theme: TTheme
}

export const CustomBarChart = ({
  data,
  theme,
  locked,
  height,
}: TCustomBarChart) => {
  const [legendWidth, setLegendWidth] = useState<string>()
  const componentRef = useRef()
  const { width } = useContainerDimensions(componentRef)
  let topSeriesData
  const preparedData = determineChartDataFormat(data)
  const legendsData = generateLegendsData(preparedData)
  const labelsData = preparedData.map((point) => point?.dimension)

  useEffect(() => {
    if (legendsData.length > 10) {
      setLegendWidth(calcWidthOfLegend(width, 3))
    } else {
      setLegendWidth(calcWidthOfLegend(width, 2))
    }
  }, [width])

  // legend
  let selectorLabelColor = palette.gray700
  let itemLegendTextColor = palette.gray700

  // datazoom variables
  let dataZoomBorderColor = palette.gray200
  let dataZoomBgColor = '#f6f6f6'
  let dataZoomFillerColor = '#093cc80a'
  let dataZoomSelectedLineColor = '#0a425e'
  let dataZoomSelectedAreaColor = '#dbe7ed'

  // xAxis variables
  let xAxisLabelColor = palette.gray700
  let xAxisLineColor = palette.gray100
  let xAxisSplitLineColor = palette.gray100
  let xAxisLabelFont = 'sans-serif'

  // yAxis variables
  let yAxisLabelColor = palette.gray700
  let yAxisLineColor = palette.gray100
  let yAxisSplitLineColor = palette.gray100
  let yAxisLabelFont = 'sans-serif'

  // toolbox
  let toolboxZoomIcon = zoom
  let toolboxResetIcon = reset
  let toolboxTextFillColor = '#072f43'

  // tooltip
  let tooltipCrossColor = palette.gray700
  let tooltipLineColor = palette.gray700

  const generatedSeries = legendsData.map((legendItem: string) => {
    let result = []
    preparedData.forEach((row) => {
      result.push(row[legendItem])
    })

    return {
      data: result,
      type: 'bar',
      smooth: true,
      clip: true,
      name: legendItem,
      symbolSize: 6,
      symbol: 'circle',
      emphasis: {
        focus: 'series',
      },
    }
  })

  const seriesData = generatedSeries

  let legendSelector = [
    {
      type: 'all',
      title: 'All',
    },
    {
      type: 'inverse',
      title: 'Inv',
    },
  ]
  let legendObj: any = {
    data: legendsData,
    top: 0,
    width: `${legendWidth}%`,
    left: '2%',
    type: 'scroll',
    orient: 'horizontal',
    icon: 'circle',
    pageIconColor: '#062434',
    textStyle: {
      overflow: 'breakAll',
      color: itemLegendTextColor,
    },
    itemGap: 16,
    itemWidth: 10,
    itemHeight: 10,
    selectorItemGap: 4,
    selectorLabel: {
      color: selectorLabelColor,
      width: 38,
      height: 23,
      padding: 0,
      fontSize: 12,
      verticalAlign: 'middle',
      align: 'center',
      backgroundColor: '#F4F4F4',
      borderColor: '#CBCBCB',
      borderWidth: 0.6,
      borderRadius: 4,
    },
    emphasis: {
      selectorLabel: {
        color: '#fff',
        backgroundColor: '#062434',
        borderColor: '#062434',
      },
    },
  }
  let areaStyleObj = {
    opacity: 0.6,
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 1,
        color: '#FFFFFF',
      },
      {
        offset: 0,
        color: palette.green500,
      },
    ]),
  }
  let toolboxObj = {
    show: true,
    top: 0,
    right: '4%',
    itemSize: 24,
    itemGap: 4,
    feature: {
      dataZoom: {
        show: true,
        icon: {
          zoom: `image://${zoom}`,
          back: `image://${reset}`,
        },
      },
    },
    emphasis: {
      iconStyle: {
        textFill: '#072F43',
      },
    },
  }

  if (legendsData.length > 10) {
    topSeriesData = getTopSeriesData(labelsData.length, seriesData)
    legendObj.selected = getTopNamesSelected(topSeriesData)
    legendObj.selector = legendSelector
    legendObj.right = '4%'
  } else if (((10 > legendsData.length) as any) > 5) {
    legendObj.selected = legendsData
    legendObj.selector = legendSelector
    legendObj.right = '4%'
  } else {
    legendObj.selected = legendsData
    legendObj.selector = []
    legendObj.left = '2%'
  }

  let dataZoomObj = [
    {
      type: 'slider',
      xAxisIndex: 0,
      filterMode: 'none',
      showDetail: false,
      borderColor: dataZoomBorderColor,
      backgroundColor: dataZoomBgColor,
      fillerColor: dataZoomFillerColor,
      borderRadius: 5,
      dataBackground: {
        lineStyle: {
          opacity: 0,
        },
        areaStyle: {
          opacity: 0,
        },
      },
      selectedDataBackground: {
        lineStyle: {
          color: dataZoomSelectedLineColor,
          width: 1,
          opacity: 0.6,
        },
        areaStyle: {
          color: dataZoomSelectedAreaColor,
          opacity: 1,
        },
      },
      moveHandleSize: 2,
      moveHandleStyle: {
        borderColor: '#CBCBCB',
        color: '#CBCBCB',
      },
      handleStyle: {
        borderColor: '#CBCBCB',
        color: '#CBCBCB',
        borderWidth: 2,
      },
      emphasis: {
        moveHandleStyle: {
          borderColor: '#8E8E8E',
          color: '#8E8E8E',
        },
        handleStyle: {
          borderColor: '#8E8E8E',
          color: '#8E8E8E',
          borderWidth: 2,
        },
      },
    },
  ]

  if (theme) {
    toolboxTextFillColor = theme.font
    yAxisLabelColor = theme.textColor
    xAxisLabelColor = theme.textColor
    yAxisLabelFont = theme.font
    xAxisLabelFont = theme.font
    areaStyleObj.color = (
      theme.chartGradient
        ? new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: '#FFFFFF',
            },
            {
              offset: 0,
              color: theme.primaryColor,
            },
          ])
        : theme.primaryColor
    ) as any
    dataZoomObj = theme.bottomTimeline ? dataZoomObj : []
    legendObj.textStyle.color = theme.textColor
  }

  const style: CSSProperties = {
    height: height ? height : '300px',
    margin: 'auto',
    zIndex: 1,
  }

  if (locked) {
    style.pointerEvents = 'none'
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: '#656565',
          width: 0.6,
          type: [10, 10],
        },
      },
    },
    legend: legendObj,
    toolbox: toolboxObj,
    grid: {
      top: 50,
      left: '2%',
      right: '4%',
      width: '94%',
      bottom: 70,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: labelsData,
        axisLabel: {
          color: xAxisLabelColor,
          fontSize: 12,
          fontFamily: xAxisLabelFont,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          onZero: true,
          lineStyle: {
            color: '#DCDCDC',
            width: 0.5,
          },
        },
        splitLine: {
          color: '#DCDCDC',
          width: 0.5,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          color: '#656565',
          fontSize: 12,
        },
        axisLine: {
          onZero: true,
          show: true,
          lineStyle: {
            color: '#DCDCDC',
            width: 0.5,
          },
        },
      },
      {
        axisLine: {
          onZero: true,
          show: true,
          lineStyle: {
            color: '#DCDCDC',
            width: 0.5,
          },
        },
      },
    ],
    dataZoom: dataZoomObj,
    series: seriesData,
  }

  return (
    <div
      ref={componentRef}
      style={{
        width: '100%',
        margin: 'auto',
        position: 'relative',
      }}
    >
      <ReactEChartsCore
        echarts={echarts}
        option={option}
        notMerge={true}
        lazyUpdate={true}
        theme={tokenguard}
        style={style}
      />
      <Watermark />
    </div>
  )
}
