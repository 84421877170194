/***
 *
 *   useGiBasicDappsData hook
 *   for getting growth index dapps data and set in to context
 *
 **********/

import { useState, useEffect, useContext } from 'react'

import { ViewContext, GiDappsDataContext } from 'components/lib'
import { fetchDappsBasicInfo } from 'utils/fetches/dapp'

export const useBasicDappsData = () => {
  const [basicDappsData, setBasicDappsData] = useState([])
  const [isBasicDappsDataLoading, setIsBasicDappsDataLoading] = useState(false)
  const viewContext = useContext(ViewContext)
  const { giDappsDataContext, setGiDappsDataContext } =
    useContext(GiDappsDataContext)
  useEffect(() => {
    if (giDappsDataContext.length > 0) {
      setBasicDappsData(giDappsDataContext)
    } else {
      const getGiDappsBasicData = async () => {
        try {
          setIsBasicDappsDataLoading(true)
          const param = `active=true&dapp_growth_index=true`
          const fetchedDappsBasicInfo = await fetchDappsBasicInfo(param)
          setBasicDappsData(fetchedDappsBasicInfo.data)
          setGiDappsDataContext(fetchedDappsBasicInfo.data)
          setIsBasicDappsDataLoading(false)
        } catch (err) {
          setIsBasicDappsDataLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
      getGiDappsBasicData()
    }
  }, [])

  return { basicDappsData, isBasicDappsDataLoading }
}
