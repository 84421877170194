/***
 *
 *   BLOCK FLOW CONTEXT
 *
 **********/

import React, { useState, createContext, ReactNode } from 'react'

export interface BlockFlowContextProps {
  blockFlowId: string | undefined | null
  setBlockFlowId: React.Dispatch<React.SetStateAction<undefined>>
}

export const BlockFlowContext = createContext<
  BlockFlowContextProps | undefined | null
>(undefined)

interface BlockFlowProviderProps {
  children: ReactNode
}

export function BlockFlowProvider({ children }: BlockFlowProviderProps) {
  const [blockFlowId, setBlockFlowId] = useState()

  return (
    <BlockFlowContext.Provider value={{ blockFlowId, setBlockFlowId }}>
      {children}
    </BlockFlowContext.Provider>
  )
}
