import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'

import {
  ButtonNew,
  Icon,
  ViewContext,
  Loader,
  useNavigate,
  useLocation,
  Typography,
  Image,
  CustomTooltip,
} from 'components/lib'
import informations from 'utils/informations.json'
import { logger } from 'utils/logger'
import { fetchGiDappUserInsights } from 'utils/fetches/gi'
import {
  convertFormatDate,
  formatToMoney,
  shortenAddress,
  getValidationErrorMessage,
} from 'utils/helpers'
import user from 'assets/icons/user-green.svg'

import { DappUsersResponseSchema } from 'features/gi/shared/types/dappUsersResponseSchema'
import { OverflowElements } from 'features/gi/shared/components/overflowElements/OverflowElements'
import Style from './topUsers.module.scss'

export function TopUsers({ dappSlug, dappName, blockchains }) {
  const [users, setUsers] = useState([])
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const viewContext = useContext(ViewContext)
  const location = useLocation()
  const [, , secondSegment] = location.pathname.split('/')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingUsers(true)
        let bodyRequest = {
          orderType: 'DESC',
          orderColumn: 'transferred_tokens',
          otherDappsUsed: [' '],
          caller: null,
          dappSlug: dappSlug,
          page: 1,
          pageSize: 5,
        }
        if (blockchains.length > 0) {
          const blockchainsToParam = blockchains.map(
            (blockchain) => blockchain.slug
          )
          bodyRequest.chains = blockchainsToParam
        }

        const response = await fetchGiDappUserInsights(bodyRequest)
        const validatedDappUsers = DappUsersResponseSchema.safeParse(response)
        if (!validatedDappUsers.success) {
          logger.error(validatedDappUsers.error)
          throw Error(getValidationErrorMessage('Top Users'))
        }
        setUsers(validatedDappUsers.data.data)
        setIsLoadingUsers(false)
      } catch (err) {
        setUsers()
        setIsLoadingUsers(false)
        viewContext.handleError(err)
      }
    }

    if (dappSlug) {
      fetchData()
    }
  }, [dappSlug, blockchains])

  return (
    <>
      {isLoadingUsers && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
      {!isLoadingUsers && users?.length > 0 && (
        <div className={Style.topUsers}>
          <div className={Style.barContainer}>
            <div className={Style.titleContainer}>
              <Image source={user} />
              <Typography
                tag="h2"
                color="gray900"
                size="m"
                weight="semi-bold"
                text="Top 5 Users"
                className="nowrap"
              />
              <div
                className="relative"
                data-tooltip-id="top-5-users"
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <Typography
                    text={informations['Top 5 Users']}
                    tag="p"
                    weight="regular"
                    size="s"
                    color="gray700"
                  />
                )}
                data-tooltip-place="bottom-start"
                data-tooltip-position-strategy="fixed"
                data-tooltip-offset={8}
              >
                <Icon name="information2" width="16" height="16" />
                <CustomTooltip id="top-5-users" light />
              </div>
            </div>
            <ButtonNew
              size="medium"
              color="green500"
              moveTo={`/app/${secondSegment}/web3-crm`}
              endIcon={
                <Icon
                  name="rightChevron"
                  color="white"
                  width="12"
                  height="16"
                />
              }
            >
              Discover {dappName} Users
            </ButtonNew>
          </div>
          <Link to={`/app/${secondSegment}/web3-crm`}>
            <div className={Style['table-container']}>
              <table className={Style.usersTable}>
                <thead>
                  <tr className={Style.head}>
                    <th>Address</th>
                    <th>Blockchain</th>
                    <th>Deposits</th>
                    <th>Date Joined</th>
                    <th>Last Interaction</th>
                    <th>Interactions</th>
                    <th>Other dApps used</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr
                      className={Style.row}
                      key={`${user.name}${index}`}
                      moveTo={`/app/${secondSegment}/web3-crm`}
                    >
                      <td>
                        {user.user && (
                          <p className={Style.nameLabel}>{user.user}</p>
                        )}
                        {user.caller && Array.isArray(user.caller) ? (
                          <ul className={Style.listOfAdresses}>
                            {user.caller.slice(0, 3).map((item) => {
                              return (
                                <li key={item}>
                                  <span className={Style.addressText}>
                                    <Icon
                                      width="12"
                                      height="12"
                                      name="wallet"
                                      color="gray800"
                                    />
                                    {shortenAddress(item)}
                                  </span>
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          <>
                            <span className={Style.addressText}>
                              <Icon
                                width="12"
                                height="12"
                                name="wallet"
                                color="gray800"
                              />
                              {user.caller}
                            </span>
                          </>
                        )}
                      </td>
                      <td>
                        <OverflowElements
                          data={users}
                          elements={[user.chain]}
                          type="blockchain"
                          size="m"
                          withFrame
                          id={user.name}
                        />
                      </td>
                      <td>{formatToMoney(user.deposits)}</td>
                      <td>{convertFormatDate(user.date_joined)}</td>
                      <td>{user.last_interaction_date}</td>
                      <td>{user.interactions}</td>
                      <td>
                        <OverflowElements
                          data={users}
                          elements={user.other_dapps_used}
                          type="dapp"
                          size="xl"
                          id={`${user.name}'`}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Link>
          {users?.length === 0 ? (
            <div className="relative min-height">
              <p className="align-xy">There is currently no data available.</p>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}
