/***
 *
 *   DAPP USERS
 *
 **********/

import React, { useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Loader,
  Paginate,
  ViewContext,
  Container,
  Breadcrumbs,
  useLocation,
  Typography,
} from 'components/lib'
import { useSeoPage } from 'hooks/useSeoPage'
import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'
import { breadcrumbs } from 'utils/constans'
import { paths } from 'utils/paths'

import { fetchGiDappUserInsightsByChain } from 'utils/fetches/gi'

import { DappUsersResponseSchema } from 'features/gi/shared/types/dappUsersResponseSchema'
import { EcosystemUsersFilters } from 'features/gi/crm/ecosystemUsers/components/EcosystemUsersFilters'
import { useEcosystemUserInsightsFilters } from 'features/gi/crm/ecosystemUsers/hooks/useEcosystemUserInsightsFilters'
import { UsersBasicValues } from 'features/gi/crm/shared/components/UsersBasicValues'
import { ListOfUsers } from 'features/gi/crm/shared/components/listOfUsers'
import { DappUserProfile } from 'features/gi/crm/shared/components/dappUserProfile'
import placeholder from 'features/gi/shared/assets/icons/placeholder.svg'
import { useBlockchain } from 'features/gi/shared/hooks/useBlockchain'

export function EcosystemUsers() {
  const breadcrumbsItems = [
    breadcrumbs.home,
    {
      key: 'web3 crm',
      url: paths.crm,
      name: 'Web3 CRM',
    },
  ]
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isBlockchainPerspective, setIsBlockchainPerspective] = useState(true)
  const [users, setUsers] = useState()
  const [metrics, setMetrics] = useState()
  const [totalRecords, setTotalRecords] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [, , secondSegment] = location.pathname.split('/')
  const { selectedBlockchain } = useBlockchain(secondSegment)
  const {
    verifiedBlockchainSegment,
    sortByParam,
    sortTypeParam,
    verifiedAddressSegment,
    verifiedPage,
    verifiedPageSize,
    searchAddressParam,
    usedDappsOptions,
    selectedOtherDappsUsed,
    verifiedOtherDappsUsedParam,
    minDepositParam,
    maxDepositParam,
    minInteractionsParam,
    maxInteractionsParam,
    joinedStartDate,
    joinedEndDate,
    lastInteractionStartDateParam,
    lastInteractionEndDateParam,
    blockchainsOptions,
  } = useEcosystemUserInsightsFilters(selectedBlockchain)
  const viewContext = useContext(ViewContext)
  // useSeoPage({
  //   title: selectedDapp?.name ? `${selectedDapp?.name} CRM | Patterns` : 'dApp CRM | Patterns',
  //   description: `Discover ${selectedDapp?.name} users, their conversions and other crypto apps used. Use our web3 CRM to boost ${selectedDapp?.name} revenue and minimize churn`,
  //   keywords: [`${selectedDapp?.name} CRM`, `${selectedDapp?.name} users`],
  //   ogTitle: selectedDapp?.name ? `${selectedDapp?.name} CRM | Patterns` : 'dApp CRM | Patterns',
  //   ogDescription: `Discover ${selectedDapp?.name} users, their conversions and other crypto apps used. Use our web3 CRM to boost ${selectedDapp?.name} revenue and minimize churn`,
  //   ogImage: selectedDapp?.icon,
  //   // ogUrl: to set,
  // })

  const handleCurrentPage = (currentPage) => {
    searchParams.delete('page')
    searchParams.append('page', currentPage)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (selectedBlockchain) {
      const fetchData = async () => {
        try {
          setIsLoadingData(true)
          const bodyRequest = {
            chains: [selectedBlockchain.slug],
            orderType: sortTypeParam?.toUpperCase() || 'DESC',
            orderColumn: sortByParam || 'deposits',
            page: verifiedPage,
            pageSize: verifiedPageSize,
            filters: {
              minDeposit: minDepositParam,
              maxDeposit: maxDepositParam,
              joinedStartDate: joinedStartDate,
              joinedEndDate: joinedEndDate,
              lastInteractionStartDate: lastInteractionStartDateParam,
              lastInteractionEndDate: lastInteractionEndDateParam,
              minInteractions: minInteractionsParam,
              maxInteractions: maxInteractionsParam,
            },
          }

          if (searchAddressParam) {
            bodyRequest.caller = searchAddressParam
          }

          if (verifiedOtherDappsUsedParam?.length > 0) {
            bodyRequest.otherDappsUsed = verifiedOtherDappsUsedParam.split(',')
          }

          const response = await fetchGiDappUserInsightsByChain(bodyRequest)
          setUsers(response.data)
          setTotalRecords(response.totalRecords)
          setMetrics(response.metrics)
          setIsLoadingData(false)
        } catch (err) {
          setUsers(undefined)
          setIsLoadingData(false)
          viewContext.handleError(err)
        }
      }

      fetchData()
    }
  }, [
    verifiedBlockchainSegment,
    verifiedPageSize,
    sortByParam,
    verifiedPage,
    sortTypeParam,
    searchAddressParam,
    verifiedOtherDappsUsedParam,
    maxDepositParam,
    minDepositParam,
    minInteractionsParam,
    maxInteractionsParam,
    joinedStartDate,
    joinedEndDate,
    lastInteractionStartDateParam,
    lastInteractionEndDateParam,
  ])

  const prepareOptions = (arr) => {
    return arr?.map((item) => {
      return {
        label: item.name,
        value: item.slug,
        icon:
          item.logo && item.logo !== 'not available' ? item.logo : placeholder,
      }
    })
  }

  return (
    <Container noPadding className="mb20">
      <Breadcrumbs items={breadcrumbsItems} />
      {verifiedBlockchainSegment ? (
        <>
          <Typography
            tag="h1"
            text={`${selectedBlockchain.name} - Web3 CRM`}
            className="pt20"
            color="primary500"
            size="m"
            weight="semi-bold"
          />
          {selectedOtherDappsUsed.length > 0 ||
          selectedOtherDappsUsed.length === 0 ? (
            <EcosystemUsersFilters
              pageSize={verifiedPageSize}
              selectedBlockchain={selectedBlockchain}
              blockchainsOptions={blockchainsOptions}
              verifiedBlockchainSegment={verifiedBlockchainSegment}
              usedDappsOptions={usedDappsOptions}
              selectedUsedDapps={prepareOptions(selectedOtherDappsUsed)}
              searchAddress={verifiedAddressSegment}
            />
          ) : null}
          <UsersBasicValues metrics={metrics} isLoadingData={isLoadingData} />
          <ListOfUsers
            verifiedAddressSegment={verifiedAddressSegment}
            isLoadingData={isLoadingData}
            users={users}
            sortByParam={sortByParam}
            sortTypeParam={sortTypeParam}
            minInteractionsParam={minInteractionsParam}
            maxInteractionsParam={maxInteractionsParam}
            maxDepositParam={maxDepositParam}
            minDepositParam={minDepositParam}
            joinedStartDate={joinedStartDate}
            joinedEndDate={joinedEndDate}
            lastInteractionStartDateParam={lastInteractionStartDateParam}
            lastInteractionEndDateParam={lastInteractionEndDateParam}
            blockchainSlug={selectedBlockchain.slug}
          />
          {!isLoadingData && totalRecords ? (
            <div className="align-center mt24 mb24">
              <Paginate
                totalRecords={totalRecords}
                limit={verifiedPageSize}
                currentPage={verifiedPage}
                onChange={(currentPage) => handleCurrentPage(currentPage)}
              />
            </div>
          ) : null}
          {verifiedAddressSegment && (
            <DappUserProfile
              verifiedAddress={verifiedAddressSegment}
              userData={users?.filter(
                (item) => item.caller === verifiedAddressSegment
              )}
              blockchainSlug={selectedBlockchain.slug}
            />
          )}
        </>
      ) : (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </Container>
  )
}
