import React, { useState } from 'react'

import { IconButton, Icon } from 'components/lib'

import { AddNewElement } from '../addNewElement/AddNewElement'
import { Customize } from '../customize/Customize'
import Style from './Toolbar.module.css'

export const Toolbar = () => {
  const [isShowingCustomizeOptions, setIsShowingCustomizeOptions] =
    useState<boolean>(false)
  const [isShowingAddElementOptions, setIsShowingAddElementOptions] =
    useState<boolean>(false)

  return (
    <>
      <div className={Style['toolbar']}>
        <IconButton
          icon={
            isShowingAddElementOptions ? (
              <Icon name="plusElementNegative" width="48" height="48" />
            ) : (
              <Icon name="plusElement" width="48" height="48" />
            )
          }
          onClick={() =>
            setIsShowingAddElementOptions(!isShowingAddElementOptions)
          }
        />
        <IconButton
          icon={
            isShowingCustomizeOptions ? (
              <Icon name="customizeNegative" width="48" height="48" />
            ) : (
              <Icon name="customize" width="48" height="48" />
            )
          }
          onClick={() =>
            setIsShowingCustomizeOptions(!isShowingCustomizeOptions)
          }
        />
      </div>
      {isShowingCustomizeOptions && <Customize />}
      {isShowingAddElementOptions && (
        <div className={Style['add-element-window']}>
          <AddNewElement closeToolbar={setIsShowingAddElementOptions} />
        </div>
      )}
    </>
  )
}
