/***
 *
 *   ADD SEGMENT MODAL CONTEXT
 *
 **********/

import React, { useState, createContext, ReactNode } from 'react'

export interface IAddSegmentModalContextProps {
  isDisplayingAddSegmentModal: boolean
  setIsDisplayingAddSegmentModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface IAddSegmentModalProviderProps {
  children: ReactNode
}

export const AddSegmentModalContext = createContext<
  IAddSegmentModalContextProps | undefined
>(undefined)

export function AddSegmentModalProvider({
  children,
}: IAddSegmentModalProviderProps) {
  const [isDisplayingAddSegmentModal, setIsDisplayingAddSegmentModal] =
    useState(false)

  return (
    <AddSegmentModalContext.Provider
      value={{ isDisplayingAddSegmentModal, setIsDisplayingAddSegmentModal }}
    >
      {children}
    </AddSegmentModalContext.Provider>
  )
}
