/***
 *
 *   Add Dapp Page
 *
 **********/

import React from 'react'

import { useSeoPage } from 'hooks/useSeoPage'

import { AddDapp } from 'features/dapps/addDapp/AddDapp'
// import { AddSegment } from 'features/addSegment/AddSegment'
// import { TestSankey } from 'features/addSegment/TestSankey'

export const AddDappPage = ({ showModal }) => {
  useSeoPage({
    // title: to set,
    // description: to set,
    // keywords: to set,
    // ogTitle: to set,
    // ogDescription: to set,
    // ogImage: to set,
    // ogUrl: to set
  })

  return <AddDapp showModal={showModal} />
  // return <AddSegment />
  // return <TestSankey />
}
