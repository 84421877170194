import React from 'react'

import { Typography, Icon } from 'components/lib'

import Style from './EmptyMetricCard.module.css'

type TEmptyMetricCardProps = {
  metricType: string
  metricIcon: string
}

export const EmptyMetricCard = ({
  metricType,
  metricIcon,
}: TEmptyMetricCardProps) => {
  return (
    <div className={`${Style['empty-metric-card']}`}>
      <Icon name={metricIcon} width="16" height="16" />
      <Typography
        size="s"
        text={`Select ${metricType}`}
        weight="semi-bold"
        tag="p"
        color="gray900"
      />
    </div>
  )
}
