import React from 'react'

import informations from 'utils/informations.json'

import { InsightSingleValue } from 'features/gi/crm/shared/components/insightSingleValue'
import Style from './UsersBasicValues.module.css'

type TBasicMetric = {
  metric_name: string
  metric_value: number | string
}

export const UsersBasicValues = ({ metrics }) => {
  const setElementColor = (number: number) => {
    if (number < 2) {
      return 'green50'
    } else {
      return 'yellow50'
    }
  }

  return (
    <section className={Style.container}>
      {metrics?.length > 0
        ? metrics.map((item: TBasicMetric, index: number) => {
            return (
              <InsightSingleValue
                color={setElementColor(index)}
                key={item.metric_name}
                title={item.metric_name}
                value={item.metric_value}
                prefix={item.metric_name === 'Deposits' && '$'}
                tooltipInfo={informations.users[item.metric_name]}
              />
            )
          })
        : null}
    </section>
  )
}
