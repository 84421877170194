/***
 *
 *   GI DAPP BASIC DASHBOARD PAGE
 *
 **********/

import React from 'react'

import { MainContentContainer } from 'components/lib'

import { GiDappBasicDashboard } from 'features/gi/dapp/giDappBasicDashboard/giDappBasicDashboard'

export function GiDappBasicPage() {
  return (
    <MainContentContainer>
      <GiDappBasicDashboard />
    </MainContentContainer>
  )
}
