import axios from 'axios'

import { endpoint } from 'utils/paths'
import settings from 'settings.json'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchGiDappsOverview = async (
  pageSize: string,
  currentPage: string,
  orderType = 'asc',
  blockchain = 'all'
) => {
  const response = await axios.get(
    `${serverURL}/${endpoint.apiGiDappsList}?limit=${pageSize}&offset=${currentPage}&orderType=${orderType}&blockchain=${blockchain}`
  )

  return response.data
}
