import React, { useState, useEffect, useRef } from 'react'

import {
  Label,
  ButtonNew,
  Typography,
  TextInputNew,
  CustomSelectNew,
  Icon,
} from 'components/lib'
import { TBlockchainOption, TSingleBlockchain } from 'types/blockchain'
import { TSingleDappDetails } from 'types/dapp'
import { transformObjectBySlug } from 'utils/helpers'

import previewLogoImage from 'features/dapps/addDapp/assets/images/preview-logo-image.svg'
import Style from './GeneralSection.module.css'

type TGeneralSectionProps = {
  dappToAdd: TSingleDappDetails
  setDappToAdd: (dapp: TSingleDappDetails) => void
  blockchains: Array<TSingleBlockchain>
  previewMode: boolean
}

export const GeneralSection = ({
  dappToAdd,
  setDappToAdd,
  blockchains,
  previewMode,
}: TGeneralSectionProps) => {
  const [file, setFile] = useState()
  const [blockchainsOptions, setBlockchainsOptions] = useState<
    Array<TBlockchainOption> | undefined
  >()
  const hiddenFileInput = useRef(null)

  useEffect(() => {
    if (blockchains.length > 0) {
      const onlyMainnetBlockchains = blockchains.filter(
        (item) => item.network === 'Mainnet'
      )
      const modifiedBlockchains = onlyMainnetBlockchains.map(
        (item: TSingleBlockchain) => {
          return {
            label: item.name,
            value: item.slug,
            icon: item.logo,
            name: item.name,
            slug: item.slug,
          }
        }
      )
      setBlockchainsOptions([...modifiedBlockchains])
    }
  }, [blockchains])

  const handleBlockchain = (option: TBlockchainOption) => {
    setDappToAdd((prevState) => ({
      ...prevState,
      blockchain: option.value,
    }))
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleDappName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDappToAdd((prevState) => ({
      ...prevState,
      name: e.target.value,
    }))
  }

  const handleWebsite = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDappToAdd((prevState) => ({
      ...prevState,
      website: e.target.value,
    }))
  }

  const handleAirdropDistributionAddress = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDappToAdd((prevState) => ({
      ...prevState,
      airdropContract: e.target.value,
    }))
  }

  const handleAirdropTokenAddress = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDappToAdd((prevState) => ({
      ...prevState,
      airdropCurrencyContract: e.target.value,
    }))
  }

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      const base64data = reader.result
      setFile(base64data)

      setDappToAdd((prevState) => ({
        ...prevState,
        logo: base64data,
      }))
    }

    reader.readAsDataURL(file)
  }

  return (
    <div>
      <Typography
        text="General"
        tag="h2"
        size="m"
        weight="medium"
        color="gray900"
      />
      <div className={Style['general-container']}>
        <div className={Style['logo-outer-container']}>
          <div className={Style['logo-image-container']}>
            {previewMode ? (
              <img src={dappToAdd.logo} alt="" />
            ) : (
              <img
                src={file ? file : previewLogoImage}
                alt=""
                className={Style['logo-image']}
              />
            )}
          </div>
          <div className={Style['logo-settings-container']}>
            <Label text="Logo*" />
            <ButtonNew
              size="large"
              color="green500"
              variant="outline"
              onClick={handleClick}
              startIcon={
                <Icon name="upload" color="green500" width="16" height="16" />
              }
              disabled={previewMode}
            >
              Upload
            </ButtonNew>
            <input
              type="file"
              onChange={handleUploadImage}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <TextInputNew
          label="dApp Name*"
          name="dappName"
          change={(e) => handleDappName(e)}
          size="large"
          placeholder="Enter your dApp name"
          value={dappToAdd?.name}
          disabled={previewMode}
        />
        {blockchainsOptions && blockchainsOptions?.length > 0 ? (
          <CustomSelectNew
            label="Blockchain*"
            options={blockchainsOptions}
            change={handleBlockchain}
            withArrow
            id="Blockchain"
            name="Blockchain"
            placeholder="Select blockchain"
            size="large"
            disabled={previewMode}
            isFocusable={false}
            value={
              previewMode
                ? {
                    value: transformObjectBySlug(
                      dappToAdd?.blockchain?.slug,
                      blockchainsOptions
                    ).slug,
                    label: transformObjectBySlug(
                      dappToAdd?.blockchain?.slug,
                      blockchainsOptions
                    ).label,
                    icon: transformObjectBySlug(
                      dappToAdd?.blockchain?.slug,
                      blockchainsOptions
                    ).icon,
                  }
                : {
                    value: dappToAdd
                      ? transformObjectBySlug(
                          dappToAdd?.blockchain,
                          blockchainsOptions
                        ).slug
                      : blockchainsOptions[0].value,
                    label: dappToAdd
                      ? transformObjectBySlug(
                          dappToAdd?.blockchain,
                          blockchainsOptions
                        ).label
                      : blockchainsOptions[0].label,
                    icon: dappToAdd
                      ? transformObjectBySlug(
                          dappToAdd?.blockchain,
                          blockchainsOptions
                        ).icon
                      : blockchainsOptions[0].icon,
                  }
            }
          />
        ) : null}
        <TextInputNew
          label="Website"
          name="website"
          change={handleWebsite}
          size="large"
          placeholder="Enter your website"
          value={dappToAdd?.website}
          disabled={previewMode}
        />
      </div>
      <div className={Style['address-fields-container']}>
        <TextInputNew
          label="Airdrop Distribution Address"
          name="distribution address"
          change={(e) => handleAirdropDistributionAddress(e)}
          size="large"
          placeholder="Enter address"
          value={dappToAdd?.airdropContract}
          disabled={previewMode}
        />
        <TextInputNew
          label="Airdrop Currency Token Address"
          name="airdrop token address"
          change={(e) => handleAirdropTokenAddress(e)}
          size="large"
          placeholder="Enter address"
          value={dappToAdd?.airdropCurrencyContract}
          disabled={previewMode}
        />
      </div>
    </div>
  )
}
