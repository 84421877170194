/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React from 'react'
import { Link } from 'react-router-dom'

import { Modal, Disclaimer } from 'components/lib'
import { BRAND_NAME } from 'utils/constans'

import { SignInContainer } from 'features/auth/signIn'
import { ContentAuthGrid } from 'features/auth/components/contentAuthGrid'
import { InformationAuthColumn } from 'features/auth/components/informationAuthColumn'

export const Signin = () => (
  <Modal title={`Log in to ${BRAND_NAME}`} redirect>
    <ContentAuthGrid>
      <div>
        <SignInContainer />
        <Disclaimer>
          New to {BRAND_NAME}?{' '}
          <Link to="/signup" text="Sign Up">
            Sign Up
          </Link>
        </Disclaimer>
      </div>
      <InformationAuthColumn
        headline="Log in to access amazing features"
        text={`Log in to access amazing features ${BRAND_NAME} account and access
          features like creating custom dashboards and advanced filters`}
      />
    </ContentAuthGrid>
  </Modal>
)
