/***
 *
 *   useAnalyticsDapps hook
 *   fetch, format and return dapps
 *
 **********/

import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { DappsSchema, TDapps } from 'types/dapp'
import { fetchAnalyticsDapps } from 'utils/fetches/dapp'
import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'

export const useAnalyticsDapps = () => {
  const [dapps, setDapps] = useState<TDapps | undefined>()
  const [isLoadingDapps, setIsLoadingDapps] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingDapps(true)
        const validatedDapps = await fetchAnalyticsDapps()
        // const fetchedDappsData = await fetchAnalyticsDapps()
        // const validatedDapps = DappsSchema.safeParse(fetchedDappsData)
        // if (!validatedDapps.success) {
        //   logger.error(validatedDapps.error)
        //   throw Error(getValidationErrorMessage('Dapps'))
        // }
        const convertedToDappsSchema = validatedDapps.map((item) => {
          // const convertedToDappsSchema = validatedDapps.data.map(item => {
          return {
            ...item,
            fromBlock: item.from_block,
            addedBy: item.added_by,
            createdAt: item.created_at,
            updatedAt: item.updated_at,
          }
        })
        setDapps(convertedToDappsSchema)
        setIsLoadingDapps(false)
      } catch (err) {
        setIsLoadingDapps(false)
        logger.error(err)
        toast.error(getValidationErrorMessage('created dApps'))
      }
    }

    fetchData()
  }, [])

  return {
    dapps,
    isLoadingDapps,
  }
}
