import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import {
  CustomSelect,
  SearchInput,
  IconLib,
  Icon,
  CustomSelectNew,
  ButtonNew,
} from 'components/lib'

import Style from './dappUsersFilters.module.scss'

export const DappUsersFilters = ({
  pageSize,
  searchAddress,
  usedDappsOptions,
  selectedUsedDapps,
  selectedDapp,
  selectedBlockchains,
  verifiedDappSegment,
  dappsOptions,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [blockchainsOptionsOfDapp, setBlockchainsOptionsOfDapp] = useState()
  const [isBlockchainPerspective, setIsBlockchainPerspective] = useState(false)
  const [isFilterReady, setIsFilterReady] = useState(false)
  const navigate = useNavigate()

  const handleDappFilter = (option) => {
    navigate(`/app/${option.value}/web3-crm`)
  }

  const handleOtherDappsUsedFilter = (option) => {
    const optionsValuesStr = option.map((option) => option.value).join(',')
    searchParams.delete('other-dapps-used')
    if (optionsValuesStr) {
      searchParams.append('other-dapps-used', optionsValuesStr)
    }
    setSearchParams(searchParams)
  }

  const handleBlockchainFilter = (option) => {
    const optionsValuesStr = option.map((option) => option.value).join(',')
    searchParams.delete('blockchains')
    if (optionsValuesStr) {
      searchParams.append('blockchains', optionsValuesStr)
    }
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (selectedUsedDapps?.length === 0 || selectedUsedDapps?.length > 0) {
      setIsFilterReady(true)
    }

    if (selectedDapp) {
      const onlyMainnetBlockchains = selectedDapp.blockchains.filter(
        (item) => item.network === 'Mainnet'
      )
      const modifiedBlockchains = onlyMainnetBlockchains.map((item) => {
        return {
          label: item.name,
          value: item.slug,
          icon: item.logo,
          name: item.name,
          slug: item.slug,
        }
      })
      setBlockchainsOptionsOfDapp(modifiedBlockchains)
    }
  }, [
    JSON.stringify(selectedUsedDapps),
    JSON.stringify(usedDappsOptions),
    selectedDapp,
  ])

  const positionQuantityOptions = [
    {
      label: '10',
      value: 10,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    },
  ]

  const handlePositionQuantity = (option) => {
    searchParams.delete('page')
    searchParams.append('page', 1)
    searchParams.delete('page-size')
    searchParams.append('page-size', option.value)
    setSearchParams(searchParams)
  }

  const handleSearchScAddress = (e) => {
    if (e) {
      searchParams.delete('search-address')
      searchParams.delete('page')
      searchParams.append('search-address', e)
      setSearchParams(searchParams)
    } else {
      searchParams.delete('search-address')
      searchParams.delete('page')
      setSearchParams(searchParams)
    }
  }

  return (
    <section className={Style.filtersSection}>
      {isFilterReady && (
        <>
          <div className={Style['left-column']}>
            <CustomSelectNew
              id="Dapp"
              isSearchable
              isClearable
              prefix="dApp:"
              name="dapp"
              color="green500"
              value={{
                value: verifiedDappSegment,
                label: selectedDapp?.name,
                icon: selectedDapp?.icon,
              }}
              options={dappsOptions}
              change={handleDappFilter}
              size="medium"
            />
            {blockchainsOptionsOfDapp && blockchainsOptionsOfDapp.length > 1 ? (
              <CustomSelect
                isSearchable={false}
                isMulti
                name="Blockchain"
                prefix="Blockchain:"
                classNamePrefix="react-select-multi-with-icon"
                defaultValue={
                  selectedBlockchains
                    ? selectedBlockchains.map((item) => ({
                        label: item.name,
                        value: item.slug,
                        icon: item?.icon || item?.logo,
                      }))
                    : null
                }
                placeholder="Blockchain"
                options={blockchainsOptionsOfDapp}
                change={handleBlockchainFilter}
                size="medium"
              />
            ) : null}
            {blockchainsOptionsOfDapp &&
            blockchainsOptionsOfDapp.length === 1 ? (
              <CustomSelectNew
                id="blockchain"
                isSearchable
                isClearable
                prefix="Blockchain:"
                name="blockchain"
                value={{
                  value: blockchainsOptionsOfDapp[0].value,
                  label: blockchainsOptionsOfDapp[0].label,
                  icon: blockchainsOptionsOfDapp[0].icon,
                }}
                options={blockchainsOptionsOfDapp}
                change={handleBlockchainFilter}
                disabled
                size="medium"
              />
            ) : null}
            <CustomSelect
              isSearchable={false}
              name="Events / Calls"
              disabled
              placeholderIcon={
                <IconLib size="16" image="circle" color="gray900" />
              }
              placeholder="Events / Calls"
              size="medium"
            />
            <CustomSelect
              isSearchable={false}
              isMulti
              name="Other dApps Used"
              prefix="Other dApps Used:"
              classNamePrefix="react-select-with-icon"
              defaultValue={
                selectedUsedDapps
                  ? selectedUsedDapps.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))
                  : null
              }
              placeholderIcon={
                <Icon height="16" width="16" name="dapp" color="gray900" />
              }
              placeholder="Other dApps Used"
              options={usedDappsOptions}
              change={handleOtherDappsUsedFilter}
              size="medium"
            />
            <SearchInput
              throttle={1000}
              action={(e) => handleSearchScAddress(e)}
              size="small"
              placeholder="Wallet Address"
              value={searchAddress}
              icon={<Icon width="16" height="16" name="magnifier" />}
            />
          </div>
          <div className={Style['right-column']}>
            <ButtonNew
              size="medium"
              color="white"
              moveTo={
                isBlockchainPerspective ? `/web3-crm` : `/crypto/bsc/web3-crm`
              }
              endIcon={
                <Icon
                  name="rightChevron"
                  color="gray900"
                  width="12"
                  height="16"
                />
              }
            >
              {isBlockchainPerspective
                ? `Explore CRM for dApps`
                : `Explore CRM for blockchains`}
            </ButtonNew>
            <CustomSelectNew
              isSearchable={false}
              withArrow
              prefix="Show on page:"
              defaultValue={{
                value: pageSize,
                label: pageSize.toString(),
              }}
              options={positionQuantityOptions}
              change={handlePositionQuantity}
              size="medium"
            />
          </div>
        </>
      )}
    </section>
  )
}
