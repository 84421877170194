import { z } from 'zod'

export const SingleDappAddressInteractionsSchema = z.object({
  caller: z.string(),
  dapp_name: z.union([z.string(), z.null()]),
  week_of_record: z.string(),
  dapp: z.unknown(),
})

export const DappAddressInteractionsSchema = z.array(
  SingleDappAddressInteractionsSchema
)
