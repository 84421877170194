import { DashboardBuilderPage } from 'views/builder/DashboardBuilderPage'
import { AddDappPage } from 'views/builder/AddDappPage'
import { paths } from 'utils/paths'

const Routes = [
  {
    path: paths.dashboardBuilder,
    view: AddDappPage,
    layout: 'builder',
    permission: 'user',
    title: 'Dashboard Builder',
  },
  {
    path: `/:dappName${paths.dashboardBuilder}/edit/:id`,
    view: DashboardBuilderPage,
    layout: 'builder',
    permission: 'user',
    title: 'Dashboard Builder',
  },
  {
    path: `/:dappName${paths.dashboardBuilder}/:dappId`,
    view: DashboardBuilderPage,
    layout: 'builder',
    permission: 'user',
    title: 'Dashboard Builder',
  },
]

export default Routes
