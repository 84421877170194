/***
 *
 *   useBasicValuesData hook
 *   for getting single values of basic components growth index data
 *
 **********/

import { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'

import { ViewContext } from 'components/lib'
import { logger } from 'utils/logger'
import { endpoint } from 'utils/paths'
import { getValidationErrorMessage } from 'utils/helpers'

import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import { BasicValuesSchema } from 'features/gi/ecosystem/giEcosystemBasicDashboard/types/BasicValuesSchema'

export const useBasicValuesData = () => {
  const {
    verifiedBlockchainSegment,
    verifiedParamsStr,
    verifiedCompareWithParam,
  } = useVerifiedFiltersParams()
  const [basicValuesData, setBasicValuesData] = useState()
  const [isBasicValuesDataLoading, setIsBasicValuesDataLoading] = useState(true)
  const viewContext = useContext(ViewContext)
  const mainDataRequestController = useRef()

  useEffect(() => {
    if (verifiedBlockchainSegment && verifiedParamsStr) {
      const fetchBasicValuesData = async () => {
        try {
          if (mainDataRequestController.current) {
            mainDataRequestController.current.abort()
          }

          mainDataRequestController.current = new AbortController()
          const signal = mainDataRequestController.current.signal
          setIsBasicValuesDataLoading(true)
          let url = `${endpoint.apiGiBasicData}`

          const postData = {
            chainName: verifiedBlockchainSegment,
            compareWith: verifiedCompareWithParam?.split(','),
          }

          const response = await axios.post(url, postData, {
            signal: signal,
          })
          const validatedBasicValues = BasicValuesSchema.safeParse(
            response?.data
          )
          if (!validatedBasicValues.success) {
            logger.error(validatedBasicValues.error)
            throw Error(getValidationErrorMessage('metrics'))
          }
          setBasicValuesData(validatedBasicValues.data)
          setIsBasicValuesDataLoading(false)
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setIsBasicValuesDataLoading(false)
            viewContext.handleError(err)
          }
        }
      }

      fetchBasicValuesData()
    }
  }, [verifiedBlockchainSegment, verifiedParamsStr])

  return { basicValuesData, isBasicValuesDataLoading }
}
